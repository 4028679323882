import React, { useState, useCallback } from 'react';
import { Page, Tabs } from '@shopify/polaris';
import { Box } from '@primer/components';
import ActiveMeeting from '../TeleHealth/ActiveMeeting';
import InactiveMeeting from '../TeleHealth/InactiveMeeting';
import AdminLoader from '../AdminLoader';

export default function TeleHealth() {
    const loadingMarkup = <AdminLoader />;
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);
    const [activemeetingLoader, setActivemeetingLoader] = useState(false);
    const [inactivemeetingLoader, setInactivemeetingLoader] = useState(false);
    const tabs = [
        {
            id: 'active-list',
            content: 'Active Meeting',
            body: (
                <ActiveMeeting activemeetingLoader={setActivemeetingLoader} />
            ),
        },
        {
            id: 'inactive-list',
            content: 'Inactive Meeting',
            body: (
                <InactiveMeeting inactivemeetingLoader={setInactivemeetingLoader} />
            ),
        },
    ];

    return (
        <div>
            {(activemeetingLoader === true || inactivemeetingLoader === true) ? loadingMarkup : ""}
            <Page fullWidth title="Telehealth Details">
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    )

}