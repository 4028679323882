import React, { useState } from 'react';
import {
    Page,
    Card,
    Loading,
    TextContainer,
    Heading
} from '@shopify/polaris';
import { useParams } from 'react-router-dom';
export default function DoctorQueriesDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const doctorQuriesId = useParams();
    console.log("===Doctor Queries Id===");
    console.log(doctorQuriesId);
    const importdataDetails = (
        <div style={{padding: '10px'}}>
            <TextContainer spacing="tight">
                <Heading>No Data Available yet.</Heading>
            </TextContainer>
        </div>
    )
    const loadingMarkup = isLoading ? <Loading /> : null;

    return (
        <Page title="Doctor Queries Details" fullWidth>
            {loadingMarkup}
            <Card>
                {importdataDetails}
            </Card>
        </Page>
    )
}
