import React, { useEffect, useCallback, useState } from 'react';
import { Page, Loading, Card, ExceptionList, DataTable, Button, TextStyle, Banner, Modal, FormLayout, TextField, InlineError } from '@shopify/polaris';
import axios from 'axios';
import Url from '../../ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorOnboardingListValue } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { Redirect, useHistory } from 'react-router-dom';
import { MobileAcceptMajor, MobileCancelMajor, NoteMinor } from '@shopify/polaris-icons';
import baseUrl from '../../ApiConfig';

export default function DoctorsOnboardingRequest(props: any) {
    const { docOndoardingRequestLoading } = props;
    const history = useHistory();
    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    docOndoardingRequestLoading(isLoading);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const handleEmailChange = useCallback((newValue) => {
        setEmail(newValue);
    }, []);
    const [firstName, setFirstName] = useState('');
    const handleFirstNameChange = useCallback((newValue) => {
        setFirstName(newValue);
    }, []);
    const [lastName, setLastName] = useState('');
    const handleLastNameChange = useCallback((newValue) => {
        setLastName(newValue);
    }, []);

    const closeerror = () => {
        setAddUserError('')
    }
    const [emailError, setEmailError] = useState('');
    const emailErrordiv = emailError
        ? <TextStyle variation="negative">{emailError}</TextStyle>
        : '';

    const [firstNameError, setFirstNameError] = useState('');
    const firstNameErrordiv = firstNameError
        ? <TextStyle variation="negative">{firstNameError}</TextStyle>
        : '';
    const [lastNameError, setLastNameError] = useState('');
    const lastNameErrordiv = lastNameError
        ? <TextStyle variation="negative">{lastNameError}</TextStyle>
        : '';
    const [addUserError, setAddUserError] = useState('');
    const addUserErrorDiv = addUserError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{addUserError}</p>
        </Banner>
        : '';


    const [addUserSuccess, setAddUserSuccess] = useState('');
    const closeSuccess = () => {
        setAddUserSuccess('');
    }

    const addUserSuccessDiv = addUserSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addUserSuccess}</p>
        </Banner>
        : '';

    const [uploadmodalActive, setuploadmodalActive] = useState(false);
    const uploadhandleToggle = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
        setEmail("");
        setEmailError("");
        setFirstName("");
        setFirstNameError("");
        setLastName("");
        setLastNameError("");
        setAddUserSuccess("");
        setAddUserError("");
    }

    // Get Doctor Onboarding Request List API
    const doctorOnBoardingApproveOrReject = (doctorId: any, approveOrreject: any) => {
        setIsLoading(true);
        console.log("Api BodyValue====", doctorId, approveOrreject);
        let token = localStorage.getItem("token");
        axios.post(Url.baseUrl + '/adminapp/api/onboardRequestApproval/', { "isApproved": approveOrreject, "onboardReqId": doctorId }, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `token ${token}`
            }

        })
            .then((res) => {
                setIsLoading(false);
                dispatch(getDoctorOnboardingListValue());
            })

            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    // Go to Doctor Details
    function gotoDetails(doctorId: number) {
        history.push(`/doctorrequestdetails/${doctorId}`);
    }

    // Reload Self Task List
    const reloadDoctorOnboardingList = () => {
        dispatch(getDoctorOnboardingListValue());
    }

    useEffect(() => {
        dispatch(getDoctorOnboardingListValue());
    }, [dispatch]);

    // call Store
    const AdminDoctorRequestList = useSelector((state: AppState) => state.doctorRequestValue);
    const allDoctorRequestValues = AdminDoctorRequestList.doctorRequestValue;

    let allCaseListConfig: any = [];
    for (var i = 0; i < allDoctorRequestValues.length; i++) {
        let doctorId: number = allDoctorRequestValues[i].id;
        let name = (allDoctorRequestValues[i].fullName === "") || (allDoctorRequestValues[i].fullName === null) ? ' ' : allDoctorRequestValues[i].fullName;
        let qualification = (allDoctorRequestValues[i].qualification === "") || (allDoctorRequestValues[i].qualification === null) ? ' ' : allDoctorRequestValues[i].qualification;
        let specialty = (allDoctorRequestValues[i].specialty === "") || (allDoctorRequestValues[i].specialty === null) ? ' ' : allDoctorRequestValues[i].specialty;
        let yearsOfExperience = (allDoctorRequestValues[i].yearsOfExperience === null) ? ' ' : allDoctorRequestValues[i].yearsOfExperience;
        allCaseListConfig.push([
            // <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(doctorId)}>View</Button>,
            name,
            qualification,
            specialty,
            yearsOfExperience,
            <Button icon={MobileAcceptMajor} size="slim" onClick={() => doctorOnBoardingApproveOrReject(doctorId, "True")}>Approve</Button>,
            <Button icon={MobileCancelMajor} destructive size="slim" onClick={() => doctorOnBoardingApproveOrReject(doctorId, "False")}>Reject</Button>,
        ]);
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Add Doctor Body
    const addDoctorBody = {
        "email": email.toLowerCase(),
        "first_name": firstName,
        "last_name": lastName
    };

    // Add Doctor API
    const addUser = () => {
        console.log("Add User Body===", addDoctorBody);
        if (email === '' && firstName === '' && lastName === '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("Please enter first name");
            setLastNameError("Please enter last name")
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase())) === false) {
            setEmailError('Please enter valid email id or phone number');
        }
        else if (email === '' && firstName === '' && lastName != '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("Please enter first name");
            setLastNameError("")
        }
        else if (email === '' && firstName != '' && lastName === '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("");
            setLastNameError("Please enter last name")
        }
        else if (email === '' && firstName != '' && lastName != '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("");
            setLastNameError("")
        }
        else if (email != '' && firstName === '' && lastName === '') {
            setEmailError("");
            setFirstNameError("Please enter first name");
            setLastNameError("Please enter last name")
        }
        else if (email != '' && firstName === '' && lastName != '') {
            setEmailError("");
            setFirstNameError("Please enter first name");
            setLastNameError("")
        }
        else if (email != '' && firstName != '' && lastName === '') {
            setEmailError("");
            setFirstNameError("");
            setLastNameError("Please enter last name")
        }
        else {
            axios.post(baseUrl.baseUrl + '/adminapp/api/adminActions/add_doctor_onboarding_request',
                addDoctorBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
                        setAddUserSuccess(result.data.message);
                        dispatch(getDoctorOnboardingListValue());
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setAddUserError(error.response.data.message);
                })
        }
    }
    const allCaseListValue: any[] = allCaseListConfig;

    // Doctor request Table
    const doctorOnBoardingList = (
        <Card>
            <Card.Header>
                <Button primary={false} onClick={reloadDoctorOnboardingList}>Reload</Button>
                <Button primary={true} onClick={uploadhandleToggle}>Add User</Button>
            </Card.Header>
            <Card.Section>
                {allCaseListValue && allCaseListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Name',
                            'Qualification',
                            'Speciality',
                            'Years of Experience',
                            "Approve",
                            "Reject"
                        ]}
                        rows={allCaseListValue}
                    />
                ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Onboarding Request Details Is Available!',
                                },
                            ]}
                        />)}
            </Card.Section>
        </Card>
    )

    // Add Doctor Markup
    const addUserMarkup = (
        <Modal
            open={uploadmodalActive}
            onClose={uploadhandleToggle}
            title={"Add User"}
            primaryAction={{
                content: "Submit",
                onAction: addUser,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout.Group condensed>
                        <div>
                            <TextField
                                label="Email / Phone Number"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Please email or phone number"
                                id="emailPhoneID"
                            />
                            <InlineError message={emailErrordiv} fieldID="emailPhoneID" />
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group condensed>
                        <div>
                            <TextField
                                label="FirstName"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                placeholder="Enter First Name"
                                id="firstNameIdD"
                            />
                            <InlineError message={firstNameErrordiv} fieldID="firstNameIdD" />
                        </div>
                        <div>
                            <TextField
                                label="Last Name"
                                value={lastName}
                                onChange={handleLastNameChange}
                                placeholder="Please Last Name"
                                id="lastNameId"
                            />
                            <InlineError message={lastNameErrordiv} fieldID="lastNameId" />
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group>{addUserSuccessDiv}</FormLayout.Group>
                    <FormLayout.Group>{addUserErrorDiv}</FormLayout.Group>
                </Page>
            </Modal.Section>
        </Modal>
    );

    return (
        <div>
            {(AdminDoctorRequestList.loading === true) ? docOndoardingRequestLoading(true) :
                <Page title="Doctor's On-Boarding Request List" fullWidth>
                    {toSignin ? <Redirect to="/signin" /> : null}
                    {doctorOnBoardingList}
                    {addUserMarkup}
                </Page>}
        </div>
    )

}