import * as React from 'react';
import {
    Page,
    Button,
    Card,
    ExceptionList,
    DataTable,
} from '@shopify/polaris';
import { NoteMinor, ImportMinor } from '@shopify/polaris-icons';

export default function ImportFromChat() {

    const importDataFromChat = () => {
    }

    const bodyMarkup = (
        <Card>
            <Card.Header>
                <Button icon={ImportMinor} primary={false} onClick={importDataFromChat}>Import Data</Button>
            </Card.Header>
            <Card.Section>
                {/* {selectedDoctorListValue && selectedDoctorListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Dr. Name',
                                'Qualification',
                                'Speciality',
                                'Appoinment Status',
                                'Delete',
                            ]}
                            rows={selectedDoctorListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Imported Data are available!',
                                },
                            ]}
                        />)} */}
            </Card.Section>
        </Card>
    );

    return (
        <Page fullWidth title="From Chat">
            {bodyMarkup}
        </Page>
    );
}

