import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Button,
    Page,
    DataTable,
    ExceptionList,
    Toast
} from '@shopify/polaris';
import { ExchangeMajor, NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInactiveCorporateList } from '../../../redux/effects/ApiCall';
import { AppState } from '../../../redux/store/Store';
import axios from 'axios';
import Url from '../../../ApiConfig';

export default function CorporatePackageActive(props: any) {
    const { corporateInActivePackageLoader } = props;

    const history = useHistory();
    const dispatch = useDispatch();
    const [noteStatusSuccess, setNoteStatusSuccess] = useState('');
    const [noteStatusSuccessActive, setNoteStatusSuccessActive] = useState(false);
    const togglenoteStatusSuccessActive = useCallback(() => setNoteStatusSuccessActive((noteStatusSuccessActive) => !noteStatusSuccessActive), []);
    const noteStatusSuccessMarkup = noteStatusSuccessActive ? (
        <Toast content={noteStatusSuccess} onDismiss={togglenoteStatusSuccessActive} />
    ) : null;

    const [noteStatusError, setNoteStatusError] = useState('');
    const [noteStatusErrorActive, setNoteStatusErrorActive] = useState(false);
    const togglenoteStatusErrorActive = useCallback(() => setNoteStatusErrorActive((noteStatusErrorActive) => !noteStatusErrorActive), []);
    const noteStatusErrorMarkup = noteStatusErrorActive ? (
        <Toast content={noteStatusError} onDismiss={togglenoteStatusErrorActive} />
    ) : null;

    const gotoDetails = (id: any) => {
        history.push(`/inactivecorporatepackagedetails/${id}`)
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    function changePackageStatus(packageId: any) {
        corporateInActivePackageLoader(true);
        axios.get(Url.baseUrl + `/paymentapp/api/corporatePackageForAdmin/${Number(packageId)}/toggle_active_corporatePackage`,
            { headers })
            .then((res) => {
                corporateInActivePackageLoader(false);
                setNoteStatusError("");
                setNoteStatusSuccess(res.data.message);
                dispatch(getInactiveCorporateList());
                togglenoteStatusSuccessActive();
            })
            .catch((error) => {
                corporateInActivePackageLoader(false);
                setNoteStatusSuccess("");
                setNoteStatusError(error.response.data.message);
                dispatch(getInactiveCorporateList());
                togglenoteStatusErrorActive();
            })
    }
    useEffect(() => {
        dispatch(getInactiveCorporateList());
    }, [dispatch]);

    // call Store
    const inActiveCorporatePackageList = useSelector((state: AppState) => state.inactiveCorporateListValue);
    const inActiveCorporateListValues = inActiveCorporatePackageList.inactiveCorporateListValue;

    let inActiveCorporateListConfig: any = [];
    for (var i = 0; i < inActiveCorporateListValues.length; i++) {
        let id = (inActiveCorporateListValues[i].id === null) ? "" : inActiveCorporateListValues[i].id;
        let name = (inActiveCorporateListValues[i].name === null || inActiveCorporateListValues[i].name === "") ? "" : inActiveCorporateListValues[i].name;
        let discountFactor = (inActiveCorporateListValues[i].discountFactor === null || inActiveCorporateListValues[i].discountFactor === "") ? "" : inActiveCorporateListValues[i].discountFactor;
        inActiveCorporateListConfig.push([
            <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(id)}></Button>,
            name,
            discountFactor,
            <Button icon={ExchangeMajor} size="slim"
                onClick={() => changePackageStatus(id)}
            ></Button>,
        ]);
    }
    const corporateActivePackageListValue: any[] = inActiveCorporateListConfig;

    const actualPageMarkup = (
        <Card>
            <Card.Header title="Inactive Corporate Packages Details">
            </Card.Header>
            <Card.Section>
                {corporateActivePackageListValue && corporateActivePackageListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View Details',
                            'Package Name',
                            'Discount Factor',
                            'Change Status',
                        ]}
                        rows={corporateActivePackageListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Corporate Packages Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    return (
        <div>
            {inActiveCorporatePackageList.loading === true ? corporateInActivePackageLoader(true) : corporateInActivePackageLoader(false)}
            <Page>
                {actualPageMarkup}
                {noteStatusSuccessMarkup}
                {noteStatusErrorMarkup}
            </Page>
        </div>
    );
}