import React, { useEffect, useState } from 'react';
import {
    Card,
    Page,
    ExceptionList,
    DataTable,
    Button,
} from '@shopify/polaris';

import { useHistory, useParams, Redirect } from 'react-router-dom';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import Url from '../../ApiConfig';
import AdminLoader from './AdminLoader';

export default function ExecutiveTaskDetails() {
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <AdminLoader /> : null;
    const { executiveId } = useParams() as {
        executiveId: string,
    };
    const [executiveTaskValues, setExecutiveTaskValues] = useState<any[]>([]);
    const [toSignin, setToSignin] = useState(false);
    const history = useHistory();
    function gotoDetails(executiveCaseId: Number, executivePatientId: Number) {
        history.push(`/filepreparation/${executivePatientId}/${executiveCaseId}`);
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };
    const executiveTaskListBody = {
        "executiveId": executiveId,

    }
    // Executive task list by id api 
    const executiveTaskList = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/adminapp/api/executives/executive_task_list_by_id',
            executiveTaskListBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.taskDataList;
                let ExecutiveTaskValue = [];
                for (var i = 0; i < response.length; i++) {
                    let executiveCaseId = response[i].caseId;
                    let executivePatientId = response[i].patientId;
                    let executiveTaskTitle = (response[i].title == null) ? '' : response[i].title;
                    let executiveAssignPatient = (response[i].patientName == null) ? '' : response[i].patientName;
                    let executiveSecondOpinionReasons = (response[i].secondOpinionReasons == null) ? '' : truncate(response[i].secondOpinionReasons);
                    let executiveConsultationType = (response[i].consultationType == null) ? '' : response[i].consultationType;
                    let executiveTaskCreateDate = (response[i].created_date == null) ? "" : (new Date(response[i].created_date)).toLocaleDateString('en-US', DATE_OPTIONS);
                    ExecutiveTaskValue.push([
                        <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(executiveCaseId, executivePatientId)}></Button>,
                        executiveTaskTitle,
                        executiveAssignPatient,
                        executiveSecondOpinionReasons,
                        executiveConsultationType,
                        executiveTaskCreateDate
                    ]);
                }
                setExecutiveTaskValues(ExecutiveTaskValue);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error.response.data.message)
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
            })

    }
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    useEffect(() => {
        executiveTaskList();
    }, []);


    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const ExecutiveTaskTable = (
        <Card>
            <Card.Section>
                {executiveTaskValues && executiveTaskValues.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'View',
                            'Title',
                            'Patient Name',
                            'Second Opinion Reasons',
                            'ConsultationType',
                            'Task Create Date'
                        ]}
                        rows={executiveTaskValues}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Executive task list is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    return (
        <div>
            {loadingMarkup}
            <Page fullWidth title=" Executive Task List" breadcrumbs={[{ content: 'Executivelist', url: '/executive' }]}>
                {toSignin ? <Redirect to="/signin" /> : null}
                {ExecutiveTaskTable}
            </Page>
        </div>
    );
}
