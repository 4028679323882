import {
  GET_LIST, GET_ONBOARDING_REQUEST_LIST,
  GET_ONBOARDING_REJECT_LIST,
  GET_ONBOARDING_INCOMPLETE_LIST, DoctorGetActionTypes,
  DoctorOnboardingRequestActionTypes,
  DoctorOnboardingRejectActionTypes,
  DoctorOnboardingIncompleteActionTypes,
  GET_DOCTOR_LIST, DoctorAdminGetActionTypes,
  GET_PATIENT_LIST, PatientGetActionTypes,
  GET_TASK_LIST, AdminGetTaskListActionTypes, AdminGetQuestionListActionTypes,
  GET_QUESTION_LIST, SET_VERIFY, SetVerifyActionTypes, CaseGetActionTypes, GET_CASE_LIST,
  QuestionGetActionTypes, ChiefComplaintGetActionTypes, GET_CC_LIST, AddChiefComplaintTypes,
  ADD_CC, DeleteDocumentTypes, DELETE_DOCUMENT, GetQuestionStateType, GetChiefComplaintStateType,
  SetAddChiefComplaintStateType, SetDeleteDocumentStateType, AdminGetAllTaskListActionTypes, GET_ALL_TASK_LIST, GET_ALL_CASE_LIST, AllCaseGetActionTypes, AdminAllAppointmentGetActionTypes, APPOINTMENT_PATIENT_LIST, ALL_PATIENT_TEAM_LIST, AdminGetPatientTeamListActionTypes, AdminGetAllPaymentListActionTypes, ALL_PAYMENT_LIST, PATIENT_QUERY_LIST, AdminPatientQueryActionTypes, AdminDoctorQueryActionTypes, DOCTOR_QUERY_LIST, AdminGetAllInactiveTaskListActionTypes, GET_ALL_INACTIVE_TASK_LIST, ActiveCorporatePackagesTypes, ACTIVE_CORPORATE_LIST, InactiveCorporatePackagesTypes, INACTIVE_CORPORATE_LIST, ActiveCorporatePatientTypes, ACTIVE_CORPORATE_PATIENT_LIST, INACTIVE_CORPORATE_PATIENT_LIST, InactiveCorporatePatientTypes
} from '../types/ActionTypes';
import {
  DoctorList, DoctorListForAdmin, PatientList, AdminTaskList, AdminQuestionList, CaseList,
  QuestionList, ChiefComplaintList, AdminAllTaskList, Count, PageCount, PatientPageCount,
  AllCaseList,
  AllAppointmentPageCount,
  AdminAllAppointmentList,
  CaseHistory,
  AdminPatientTeamList,
  PatientTeamListPageCount,
  AdminAllPaymentList,
  AdminPatientQueryList,
  AdminDoctorQueryList,
  PaymentPageCount,
  AdminAllInactiveTaskList,
  ActiveCorporateList,
  InactiveCorporateList,
  ActiveCorporatePatientList,
  InActiveCorporatePatientList
} from '../interfaces/Model';

export const getDoctorAction = (doctors: DoctorList[]): DoctorGetActionTypes => {
  return {
    type: GET_LIST,
    payload: doctors
  };
};

export const getOnboardingDoctorRequestAction = (doctors: DoctorList[]): DoctorOnboardingRequestActionTypes => {
  return {
    type: GET_ONBOARDING_REQUEST_LIST,
    payload: doctors
  };
};
export const getOnboardingDoctorRejecttAction = (doctors: DoctorList[]): DoctorOnboardingRejectActionTypes => {
  return {
    type: GET_ONBOARDING_REJECT_LIST,
    payload: doctors
  };
};
export const getOnboardingDoctorIncompleteAction = (doctors: DoctorList[]): DoctorOnboardingIncompleteActionTypes => {
  return {
    type: GET_ONBOARDING_INCOMPLETE_LIST,
    payload: doctors
  };
};

export const getDoctorListAction = (doctorsadmin: DoctorListForAdmin[]): DoctorAdminGetActionTypes => {
  return {
    type: GET_DOCTOR_LIST,
    payload: doctorsadmin
  };
};

export const getPatientAction = (patients: PatientList[], patientPageCount: PatientPageCount): PatientGetActionTypes => {
  return {
    type: GET_PATIENT_LIST,
    payload: patients,
    patientPageCount: patientPageCount
  };
};

export const getTaskListAction = (tasklist: AdminTaskList[]): AdminGetTaskListActionTypes => {
  return {
    type: GET_TASK_LIST,
    payload: tasklist
  };
};

export const getAllTaskListAction = (alltasklist: AdminAllTaskList[], count: Count, pageCount: PageCount): AdminGetAllTaskListActionTypes => {
  return {
    type: GET_ALL_TASK_LIST,
    payload: alltasklist,
    count: count,
    pageCount: pageCount
  };
};

export const getAllInactiveTaskListAction = (allinactivetasklist: AdminAllInactiveTaskList[], count: Count, pageCount: PageCount): AdminGetAllInactiveTaskListActionTypes => {
  return {
    type: GET_ALL_INACTIVE_TASK_LIST,
    payload: allinactivetasklist,
    count: count,
    pageCount: pageCount
  };
};

export const getQuestionListAction = (questionlist: AdminQuestionList[]): AdminGetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST,
    payload: questionlist
  };
};

export const setVerifyAction = (setverify: any): SetVerifyActionTypes => {
  return {
    type: SET_VERIFY,
    payload: setverify
  };
};

export const getCaseAction = (cases: CaseList[], patientName: CaseHistory): CaseGetActionTypes => {
  return {
    type: GET_CASE_LIST,
    payload: cases,
    patientname : patientName,
  };
};

export const getAllCaseAction = (allcases: AllCaseList[], pageCount: PageCount): AllCaseGetActionTypes => {
  return {
    type: GET_ALL_CASE_LIST,
    payload: allcases,
    pageCount: pageCount
  };
};

export const getQuestionAction = (question: QuestionList[]): QuestionGetActionTypes => {
  return {
    type: GET_QUESTION_LIST,
    payload: question
  };
};

export const getChiefComplaintAction = (cc: ChiefComplaintList[]): ChiefComplaintGetActionTypes => {
  return {
    type: GET_CC_LIST,
    payload: cc
  };
};

export const AddChiefComplaintAction = (addCC: any): AddChiefComplaintTypes => {
  return {
    type: ADD_CC,
    payload: addCC
  };
};

export const DeleteDocumentAction = (deletedocument: any): DeleteDocumentTypes => {
  return {
    type: DELETE_DOCUMENT,
    payload: deletedocument
  };
};

export const getAppointmentAction = (appointment: AdminAllAppointmentList[], appointmentPageCount: AllAppointmentPageCount): AdminAllAppointmentGetActionTypes => {
  return {
    type: APPOINTMENT_PATIENT_LIST,
    payload: appointment,
    appointmentPageCount: appointmentPageCount
  };
};

export const getPatientListAction = (patientTeamList: AdminPatientTeamList[], patientListPageCount: PatientTeamListPageCount): AdminGetPatientTeamListActionTypes => {
  return {
    type: ALL_PATIENT_TEAM_LIST,
    payload: patientTeamList,
    patientListPageCount: patientListPageCount
  };
};

export const getAllPaymentAction = (allPaymentList: AdminAllPaymentList[], PageCount: PaymentPageCount): AdminGetAllPaymentListActionTypes => {
  return {
    type: ALL_PAYMENT_LIST,
    payload: allPaymentList,
    PageCount: PageCount
  };
};

export const getPatientQueryAction = (patientQueryList: AdminPatientQueryList[]): AdminPatientQueryActionTypes => {
  return {
    type: PATIENT_QUERY_LIST,
    payload: patientQueryList,
  };
};

export const getDoctorQueryAction = (doctorQueryList: AdminDoctorQueryList[]): AdminDoctorQueryActionTypes => {
  return {
    type: DOCTOR_QUERY_LIST,
    payload: doctorQueryList,
  };
};

export const getActiveCorporateListActions = (corporatePackageList: ActiveCorporateList[]): ActiveCorporatePackagesTypes => {
  return {
    type: ACTIVE_CORPORATE_LIST,
    payload: corporatePackageList,
  };
};

export const getInactiveCorporateListActions = (corporatePackageList: InactiveCorporateList[]): InactiveCorporatePackagesTypes => {
  return {
    type: INACTIVE_CORPORATE_LIST,
    payload: corporatePackageList,
  };
};

export const getActiveCorporatepatientListActions = (activeCorporatePatientList: ActiveCorporatePatientList[], totalNoOfPatientTeam:any): ActiveCorporatePatientTypes => {
  return {
    type: ACTIVE_CORPORATE_PATIENT_LIST,
    payload: activeCorporatePatientList,
    totalNoOfPatientTeam:totalNoOfPatientTeam
  };
};
export const getInactiveCorporatepatientListActions = (inactiveCorporatePatientList: InActiveCorporatePatientList[], totalNoOfPatientTeam:any): InactiveCorporatePatientTypes => {
  return {
    type: INACTIVE_CORPORATE_PATIENT_LIST,
    payload: inactiveCorporatePatientList,
    totalNoOfPatientTeam:totalNoOfPatientTeam
  };
};