import React, { useState, useCallback, useEffect } from 'react';
import { Banner, Page, Card, Button, Toast, ExceptionList, DescriptionList, Modal, } from '@shopify/polaris';
import { Box } from '@primer/components';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { MobileAcceptMajor, MobileCancelMajor, NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import Url from '../../../ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTaskListValue } from '../../../redux/effects/ApiCall';
import { AppState } from '../../../redux/store/Store';
import { AdminAllTaskList } from '../../../redux/interfaces/Model';
import { SelectPicker } from 'rsuite';
import * as _ from 'lodash';
import AdminLoader from '../AdminLoader';

export default function TaskDetails() {
    const dispatch = useDispatch();

    const [toSignin] = useState(false)
    const { rowIndex } = useParams() as {
        rowIndex: string,

    };
    const { currentPage } = useParams() as {
        currentPage: string,

    };
    let token = localStorage.getItem("token");


    // Call Store
    const adminAllTaskList = useSelector((state: AppState) => state.alltaskListValue);
    const taskValues: AdminAllTaskList = _.filter(adminAllTaskList.alltaskListValue, ['id', Number(rowIndex)])[0];

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = <AdminLoader />;

    const [successToastMessage, setSuccessToastMessage] = useState("");
    const [successToastactive, setSuccessToastactive] = useState(false);
    const successToasttoggleActive = useCallback(() => setSuccessToastactive((successToastactive) => !successToastactive), []);
    const successToastMarkup = successToastactive ? (
        <Toast content={successToastMessage} onDismiss={successToasttoggleActive} />
    ) : null;

    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [errorToastactive, setErrorToastactive] = useState(false);
    const errorToasttoggleActive = useCallback(() => setErrorToastactive((errorToastactive) => !errorToastactive), []);
    const errorToastMarkup = errorToastactive ? (
        <Toast content={errorToastMessage} error onDismiss={errorToasttoggleActive} />
    ) : null;

    const [executiveValues, setExecutiveValues] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState(null);
    const handleSelectedExecutiveChange = useCallback((value) => setSelectedExecutive(value), []);

    const [executiveModalActive, setExecutiveModalActive] = useState(false);
    const handleExecutiveModalChange = () => {
        setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
        setSelectedExecutive(null);
        setSuccessAssignBannerTitle("");
        setErrorAssignBannerTitle("");
    }



    const [successAssignBannerTitle, setSuccessAssignBannerTitle] = useState("");
    const successAssignBannerDiv = successAssignBannerTitle
        ? <Banner
            status="success">
            <p>{successAssignBannerTitle}</p>
        </Banner>
        : '';
    const [errorAssignBannerTitle, setErrorAssignBannerTitle] = useState("");
    const errorAssignBannerDiv = errorAssignBannerTitle
        ? <Banner
            status="critical">
            <p>{errorAssignBannerTitle}</p>
        </Banner>
        : '';
    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [toastSuccessActive, setToastSuccessActive] = useState(false);
    const toggleToastSuccessActive = useCallback(() => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive), []);

    const toastExecutiveSuccessMarkup = toastSuccessActive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleToastSuccessActive} />
    ) : null;

    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [toastErrorActive, setToastErrorActive] = useState(false);
    const toggleToastErrorActive = useCallback(() => setToastErrorActive((toastErrorActive) => !toastErrorActive), []);

    const toastExecutiveErrorMarkup = toastErrorActive ? (
        <Toast content={toastErrorMessage} onDismiss={toggleToastErrorActive} />
    ) : null;
    function gotoCaseDetails() {
        history.push(`/filepreparation/${taskValues.patientId}/${taskValues.caseId}`);
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Accept Booking Appointment API
    const acceptBooking = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/appointmentapp/api/appointment/doctor_accept_appointment_by_admin',
            { "taskId": Number(taskValues.id), "requestStatus": Number(1) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }

    // Receject Appointment Appointment API
    const rejectBooking = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/appointmentapp/api/appointment/doctor_accept_appointment_by_admin',
            { "taskId": Number(taskValues.id), "requestStatus": Number(-1) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }

    // Get Executive List API
    const getExecutiveList = () => {
        setIsLoading(true);
        axios.get(Url.baseUrl + '/adminapp/api/executives/executive_list_dropdown', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.executiveList;
                setExecutiveValues(response);
            })
            .catch((error) => {
                setIsLoading(false);
                //setPatientrelatnlistError(error.response.data.message);
            })
    };

    // Assign Executive API
    const assignExecutive = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/adminapp/api/adminActions/assign_task_to_admin',
            { "taskId": Number(taskValues.id), "executiveId": Number(selectedExecutive) }, { headers })
            .then((res) => {
                setIsLoading(false);
                //setSuccessAssignBannerTitle(res.data.message);
                handleExecutiveModalChange();
                setToastSuccessMessage("Task assigned successfully");
                toggleToastSuccessActive();
            })
            .catch((error) => {
                setIsLoading(false);
                //setErrorAssignBannerTitle(error.response.data.message);
                handleExecutiveModalChange();
                setToastErrorMessage("Something went wrong");
                toggleToastErrorActive();
            })
    }
    const handleTaskComplete = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/adminapp/api/adminTasks/admin_task_close',
            { "taskId": String(taskValues.id), "taskCloseComment": "taskCloseByAdmin" }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getExecutiveList();
        if (taskValues === undefined) {
            dispatch(getAllTaskListValue(Number(currentPage)));
        }
    }, [dispatch, taskValues]);

    const assignExecutiveModalMarkup = (
        <div>
            <Modal
                large
                open={executiveModalActive}
                onClose={handleExecutiveModalChange}
                title="Select Executive"
                primaryAction={{
                    content: 'Assign',
                    onAction: assignExecutive,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleExecutiveModalChange,
                    },
                ]}
            >
                <Modal.Section>
                    <div>
                        <p style={{ marginBottom: "5px" }}>
                            Select executive for task: "{(taskValues === undefined) ? "" : taskValues.title}"
                        </p>
                        <SelectPicker
                            block
                            onChange={handleSelectedExecutiveChange}
                            data={executiveValues}
                            value={selectedExecutive}
                            menuStyle={{ zIndex: 200, color: "rgb(8, 78, 138) !important" }}
                        />
                    </div>
                    <Box p={1}>{successAssignBannerDiv}</Box>
                    <Box p={1}>{errorAssignBannerDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )

    const taskTable = (
        <Card
            secondaryFooterActions={[{ content: 'Mark as Complete', destructive: true, onAction: handleTaskComplete }]}

            primaryFooterAction={{ content: 'Assign to Executive', onAction: handleExecutiveModalChange }}>
            <Card.Header>
                <Button icon={MobileAcceptMajor} primary={false} onClick={acceptBooking}>Accept Appointment For Doctor</Button>
                <Button icon={MobileCancelMajor} primary={false} onClick={rejectBooking}>Reject Appointment For Doctor</Button>
                <Button icon={ViewMinor} primary={true} onClick={gotoCaseDetails}>View Case Details</Button>
            </Card.Header>
            <Card.Section>
                {taskValues ? (
                    <DescriptionList
                        items={[
                            {
                                term: 'Title',
                                description: `${(taskValues === undefined) ? "" : taskValues.title}`
                            },
                            {
                                term: 'Task For',
                                description: `${(taskValues === undefined) ? "" : taskValues.patientName}`
                            },
                            {
                                term: 'Why want 2nd opinion ?',
                                description: `${(taskValues === undefined) ? "" : taskValues.whyWant2ndOpinion}`
                            },
                            {
                                term: 'Reason for 2ndOpinion',
                                description: `${(taskValues === undefined) ? "" : taskValues.secondOpinionReasons}`
                            },
                            {
                                term: 'Consultation Type',
                                description: `${(taskValues === undefined) ? "" : taskValues.consultationType}`
                            },
                            {
                                term: 'Task Create Date',
                                description: `${(taskValues === undefined) ? "" : taskValues.created_date}`
                            },
                            {
                                term: 'Note',
                                description: `${(taskValues === undefined) ? "" : (taskValues.note === null) ? "" : taskValues.note}`
                            },
                            {
                                term: 'Completed',
                                description: `${(taskValues === undefined || taskValues === null) ? "" : (taskValues.completed === false) ? "No" : "yes"}`
                            },
                            {
                                term: 'Assigned To',
                                description: `${(taskValues === undefined || taskValues === null) ? "" : taskValues.assigned_to}`
                            },
                        ]}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Task Details is available!',
                            },
                        ]}
                    />)}


            </Card.Section>
        </Card>
    )

    return (
        <div>
            {taskValues === undefined || isLoading === true ? loadingMarkup : ""}
            {toSignin ? <Redirect to="/signin" /> : null}
            <Page title="Task List Details" fullWidth breadcrumbs={[{ content: 'Tasklist', url: '/task' }]}>
                {assignExecutiveModalMarkup}
                {taskTable}
                {successToastMarkup}
                {errorToastMarkup}
                {toastExecutiveSuccessMarkup}
                {toastExecutiveErrorMarkup}
            </Page>
        </div>
    );
}