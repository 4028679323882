import React, { useState, useCallback, KeyboardEvent } from 'react';
import { useHistory } from 'react-router-dom';
import AimsLogo from '../image/logo/aims2health-with-caption.png';
import FeatureImg from '../image/feature-image-admin.svg';
import { Box, Flex } from '@primer/components';
import {
  Button,
  FormLayout,
  Banner,
  DisplayText,
  TextStyle,
  Layout,
  Page,
  Caption,
  Link,
  Stack,
  Card,
  Form,
  InlineError
} from '@shopify/polaris';
import Url from '../../ApiConfig';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import AdminLoader from './AdminLoader';

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#006FBB",
      outlineWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderColor: "#006FBB",
      outlineWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderColor: "#006FBB",
      outlineWidth: 0,
    },
    height: "38px",
    background: "006FBB"
  },
  input: {
    "&::placeholder": {
      color: "#006FBB"
    },
    color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
  },
  focused: {},
  notchedOutline: {}
}));

export default function Login() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#006FBB",
      },
      secondary: {
        main: '#330101',
      },
      text: {
        primary: "#FFFFF",
        secondary: "#006FBB"
      }
    },
    typography: {
      fontSize: 22,
    },
    overrides: {
      MuiInputBase: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },

  });
  const outlinedInputClasses = useOutlinedInputStyles();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <AdminLoader /> : null;

  const history = useHistory();
  const [error, setError] = useState('');
  const errorErrorDiv = error
    ? <Banner title={error} status="critical" /> : '';

  const [buttonLoading, setButtonLoading] = useState(false);

  interface EmailState {
    email: string;
  }

  const [email, setEmail] = React.useState<EmailState>({
    email: '',
  });

  const handleEmailChange = (prop: keyof EmailState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ ...email, [prop]: event.target.value });
  };
  const [errorEmail, setErrorEmail] = useState('');
  const errorEmailDiv = errorEmail
    ? <TextStyle variation="negative">{errorEmail}</TextStyle>
    : '';

  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPassword] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [errorpassword, setErrorPassword] = useState('');
  const errorPassword = errorpassword
    ? <TextStyle variation="negative">{errorpassword}</TextStyle>
    : '';

  const loginBody = {
    "email_or_username": email.email.toLowerCase(),
    "password": password.password
  }
  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json'
  }
  //handle button click of login form
  const handleLogin = () => {
    if (email.email === "") {
      setErrorEmail("Email is empty");
      setErrorPassword("Password is empty");
    }
    else if (email.email === "" && password.password != "") {
      setErrorEmail("Email is empty");
      setErrorPassword("");
    }
    else if (email.email != "" && password.password === "") {
      setErrorEmail("");
      setErrorPassword("Password is empty");
    }
    else {

      setIsLoading(true);
      setButtonLoading(true);
      setError('');
      setErrorEmail("");
      setErrorPassword("");
      axios.post(Url.baseUrl + '/adminapp/api-auth-token',
        loginBody, { headers }
      )
        .then(result => {
          setIsLoading(false);
          setButtonLoading(false);
          if (result) {
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("email", result.data.user.email);
            localStorage.setItem("firstname", result.data.user.first_name);
            localStorage.setItem("lastname", result.data.user.last_name);
            localStorage.setItem("id", result.data.user.id);
            history.push("/dashboard");
            window.location.reload();
          }
          else {
            history.push("/signin");
          }
        })
        .catch((error) => {
          if (navigator.onLine === true) {
            setError(error.response.data.message || error.response.data.non_field_errors[0]);
            setIsLoading(false);
            setButtonLoading(false);
          }
          else {
            setError("You are offline, please check your internet");
            setIsLoading(false);
            setButtonLoading(false);
          }

        })
    }
  }

  const actualPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex style={{ height: "90vh" }} alignItems="center" justifyContent="center">
          <Card>
            <div style={{ padding: '10px' }}>
              <Link url="https://aims2health.com"><img src={AimsLogo} alt="Logo" height="64" /></Link>
              <Box my={3}>
                <DisplayText size="large">Log in to AIMS2Health</DisplayText>
                <Caption>Continue to AIMS2Health admin dashboard</Caption>
              </Box>
              <br />
              <Box my={3}>
                <Form onSubmit={handleLogin}>
                  <FormLayout>
                    <div>
                      <ThemeProvider theme={theme}>
                        <InputLabel htmlFor="outlined-adornment-email"></InputLabel>
                        <OutlinedInput
                          id="emailFieldID"
                          placeholder="Enter your email"
                          value={email.email}
                          onChange={handleEmailChange('email')}
                          fullWidth
                          classes={outlinedInputClasses}
                          labelWidth={0}
                        />
                      </ThemeProvider>
                      <InlineError message={errorEmailDiv} fieldID="emailFieldID" />
                    </div>
                    <div>
                      <ThemeProvider theme={theme}>
                        <InputLabel htmlFor="outlined-adornment-password"></InputLabel>
                        <OutlinedInput
                          id="passwordFieldID"
                          placeholder="Enter your password"
                          type={password.showPassword ? 'text' : 'password'}
                          value={password.password}
                          onChange={handleChange('password')}
                          fullWidth
                          classes={outlinedInputClasses}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {password.showPassword ? <Visibility style={{ fill: "white" }} /> : <VisibilityOff style={{ fill: "white" }} />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={0}
                        />
                      </ThemeProvider>
                      <InlineError message={errorPassword} fieldID="passwordFieldID" />
                    </div>
                    <div>
                      <Button fullWidth primary loading={buttonLoading} onClick={handleLogin}>Sign In</Button>
                    </div>
                    <div>{errorErrorDiv}</div>
                  </FormLayout>
                </Form>
              </Box>
            </div>
          </Card>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <img
            src={FeatureImg}
            alt=""
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </Box>
      </Layout.Section>
      <Layout.Section>
        <Stack>
          <Stack.Item fill>
            <Caption>© Copyright AIMS2Health 2021. All rights reserved.</Caption>
          </Stack.Item>
          <Stack.Item>
            <Caption>
              <Link url="https://aims2health.com/teamsofuse.html" external>Terms of Use</Link>&nbsp;&nbsp;
              <Link url="https://aims2health.com/privacystatement.html" external>Privacy & Cookies</Link>
            </Caption>
          </Stack.Item>
        </Stack>
      </Layout.Section>
    </Layout>
  );
  return (
    <div>
      {loadingMarkup}
      <Page fullWidth>
        {actualPageMarkup}
      </Page>
    </div>
  );

}