import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    DescriptionList,
    Button,
    Modal,
    TextField,
    Banner,
    TextStyle,
    InlineError,
    FormLayout,
    Stack
} from '@shopify/polaris';

import { Redirect, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Url from '../../ApiConfig';
import { Box } from '@primer/components';
import { SelectPicker } from 'rsuite';

export default function ContactDetails(props: any) {
    const { contactDetailsLoader } = props;

    const [toSignin, setToSignin] = useState(false);

    const { id } = useParams() as {
        id: string;
    };

    const [isLoading, setIsLoading] = useState(false);
    contactDetailsLoader(isLoading);

    const [contactperson, setContactPerson] = useState('');
    const [phonecontact, setPhoneContact] = useState('');
    const [emailcontact, setEmailContact] = useState('');
    const [prescriptionPhone, setPrescriptionPhone] = useState('');
    const [prescriptionAddress, setPrescriptionAddress] = useState('');

    const closeerror = () => {
        setUpdateSummaryError('')
    }
    const [updateSummaryError, setUpdateSummaryError] = useState('');
    const updateSummaryErrorDiv = updateSummaryError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{updateSummaryError}</p>
        </Banner>
        : '';

    const [updateDrPhone, setUpdateDrPhone] = useState('');
    const handleUpdateDrPhoneChange = useCallback((newValue) => {
        setUpdateDrPhone(newValue);
    }, []);

    const [updateDrPhoneError, setUpdateDrPhoneError] = useState('');
    const updateDrPhoneErrorDiv = updateDrPhoneError
        ? <TextStyle variation="negative">{updateDrPhoneError}</TextStyle>
        : '';

    const [updateDrEmail, setUpdateDrEmail] = useState('');
    const handleUpdateDrEmailChange = useCallback((newValue) => {
        setUpdateDrEmail(newValue);
    }, []);

    const [updateDrEmailError, setUpdateDrEmailError] = useState('');
    const updateDrEmailErrorDiv = updateDrEmailError
        ? <TextStyle variation="negative">{updateDrEmailError}</TextStyle>
        : '';

    const [updateAimsdirectorError, setUpdateAimsdirectorError] = useState("");
    const updateAimsDirectorErrorDiv = updateAimsdirectorError
        ? <TextStyle variation="negative">{updateAimsdirectorError}</TextStyle>
        : '';

    const [updateDrExecutive, setUpdateDrExecutive] = useState('');
    const handleUpdateDrExecutiveChange = useCallback((newValue) => {
        setUpdateDrExecutive(newValue);
    }, []);

    const [updateDrPresciptionPhone, setUpdateDrPresciptionPhone] = useState("");
    const handleUpdateDrPresciptionPhoneChange = useCallback((newValue) => {
        setUpdateDrPresciptionPhone(newValue);
    }, []);

    const [updateDrPresciptionPhoneError, setUpdateDrPresciptionPhoneError] = useState('');
    // const updateDrPresciptionPhoneErrorDiv = updateDrPresciptionPhoneError
    //     ? <TextStyle variation="negative">{updateDrPhoneError}</TextStyle>
    //     : '';

    const [updateDrPrescriptionAddress, setUpdateDrPrescriptionAddress] = useState('');
    const handleUpdateDrPrescriptionAddressChange = useCallback((newValue) => {
        setUpdateDrPrescriptionAddress(newValue);
    }, []);

    const [updateDrPrescriptionAddressError, setUpdateDrPrescriptionAddressError] = useState('');
    // const updateDrPrescriptionAddressErrorDiv = updateDrPrescriptionAddressError
    //     ? <TextStyle variation="negative">{updateDrPrescriptionAddressError}</TextStyle>
    //     : '';

    const [updateDrDetailsModalActive, setUpdateDrDetailsModalActive] = useState(false);
    const drContactDetailsModalToggle = () => {
        setUpdateDrDetailsModalActive((updateDrDetailsModalActive) => !updateDrDetailsModalActive);
        setUpdateDrPhone(phonecontact);
        setUpdateDrPhoneError("");
        setUpdateDrEmail(emailcontact);
        setUpdateDrEmailError("");
        setUpdateDrExecutive(contactperson);
        setUpdateDrPresciptionPhone(prescriptionPhone);
        setUpdateDrPrescriptionAddress(prescriptionAddress);
        setUpdateContactDetailsSuccess("");
        setUpdateContactDetailsError("");
    }
    const closeContactDetailsSuccess = () => {
        setUpdateContactDetailsSuccess('')
    }

    const [updateContactDetailsSuccess, setUpdateContactDetailsSuccess] = useState('');
    const updateContactDetailsDiv = updateContactDetailsSuccess
        ? <Banner
            onDismiss={closeContactDetailsSuccess}
            status="success">
            <p>{updateContactDetailsSuccess}</p>
        </Banner>
        : '';

    const closeContactDetailsError = () => {
        setUpdateSummaryError('')
    }

    const [updateContactDetailsError, setUpdateContactDetailsError] = useState('');
    const updateContactDetailsErrorDiv = updateContactDetailsError
        ? <Banner
            onDismiss={closeContactDetailsError}
            status="critical">
            <p>{updateContactDetailsError}</p>
        </Banner>
        : '';

    // Aims Directors
    const [selectedDocName, setSelectedDocName] = useState('');
    const handleDocNameChange = useCallback((value) => {
        setSelectedDocName(value);
        //appointmentDoctorPageLoader(value)
    }, []);

    const [doctorList, setDoctorList] = useState<any[]>([]);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Contact Details
    const getContactDetailsList = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + `/adminapp/api/doctorContact/contactDetailsOfDoctor`, { "doctorId": Number(id) }, { headers })
            .then((res) => {
                setIsLoading(false);
                let doctorData = res.data.doctorContactDetails;
                setContactPerson((doctorData.contactPersonFromAIMS2HealthDirector == "" || doctorData.contactPersonFromAIMS2HealthDirector == null) ? " " : doctorData.contactPersonFromAIMS2HealthDirector);
                setPhoneContact((doctorData.phoneContact == "" || doctorData.phoneContact == null) ? " " : doctorData.phoneContact);
                setEmailContact((doctorData.emailContact == "" || doctorData.emailContact == null) ? " " : doctorData.emailContact);
                setPrescriptionPhone((doctorData.hasOwnProperty('doctorPrescriptionPhoneNumber')) ? (doctorData.doctorPrescriptionPhoneNumber === null || doctorData.doctorPrescriptionPhoneNumber === "") ? "" : doctorData.doctorPrescriptionPhoneNumber : "");
                setPrescriptionAddress((doctorData.hasOwnProperty('doctorPrescriptionAddress')) ? (doctorData.doctorPrescriptionAddress === null || doctorData.doctorPrescriptionAddress === "") ? "" : doctorData.doctorPrescriptionAddress : "");
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    const drContactBody = {
        "doctorId": Number(id),
        "phoneContact": updateDrPhone,
        "emailContact": updateDrEmail,
        "contactPersonFromAIMS2HealthDirectorId": selectedDocName,
        "prescriptionPhone": updateDrPresciptionPhone,
        "prescriptionAdress": updateDrPrescriptionAddress
    }

    // update dr. sumaary details
    const updateDrContactDetails = () => {
        // if (updateDrPhone === "" && updateDrEmail === "" && (selectedDocName === "" || selectedDocName === null)) {
        //     setUpdateDrPhoneError("Please add phone number");
        //     setUpdateDrEmailError("Please add email");
        //     setUpdateAimsdirectorError("Please select a contact person");
        // }
        // else if (updateDrPhone === "" && updateDrEmail === "" && (selectedDocName != "" || selectedDocName != null)) {
        //     setUpdateDrPhoneError("Please add phone number");
        //     setUpdateDrEmailError("Please add email");
        //     setUpdateAimsdirectorError("");
        // }
        // else if (updateDrPhone === "" && updateDrEmail === "" && (selectedDocName === "" || selectedDocName === null)) {
        //     setUpdateDrPhoneError("Please add phone number");
        //     setUpdateDrEmailError("");
        //     setUpdateAimsdirectorError("Please select a contact person");
        // }
        // else if (updateDrPhone === "" && updateDrEmail != "" && (selectedDocName != "" || selectedDocName != null)) {
        //     setUpdateDrPhoneError("Please add phone number");
        //     setUpdateDrEmailError("");
        //     setUpdateAimsdirectorError("");
        // }
        // else if (updateDrPhone != "" && updateDrEmail === "" && (selectedDocName === "" || selectedDocName === null)) {
        //     setUpdateDrPhoneError("");
        //     setUpdateDrEmailError("Please add email");
        //     setUpdateAimsdirectorError("Please select a contact person");
        // }
        // else if (updateDrPhone != "" && updateDrEmail === "" && (selectedDocName != "" || selectedDocName != null)) {
        //     setUpdateDrPhoneError("");
        //     setUpdateDrEmailError("Please add email");
        //     setUpdateAimsdirectorError("");
        // }
        // else if (updateDrPhone != "" && updateDrEmail != "" && (selectedDocName === "" || selectedDocName === null)) {
        //     setUpdateDrPhoneError("");
        //     setUpdateDrEmailError("");
        //     setUpdateAimsdirectorError("Please select a contact person");
        // }
        // else {
            console.log("UpdateDrContactBody===", drContactBody);
            setIsLoading(true);
            axios.post(Url.baseUrl + '/adminapp/api/doctorContact/updateContactDetailsOfDoctor',
                drContactBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setUpdateContactDetailsSuccess(result.data.message);
                    setUpdateContactDetailsError("");
                    drContactDetailsModalToggle();
                    getContactDetailsList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setUpdateContactDetailsError(error.response.data.message);
                    setUpdateContactDetailsSuccess("");
                })
        // }
    }

    // get Doctor List
    const getDocList = () => {
        setIsLoading(true);
        axios.get(Url.baseUrl + '/adminapp/api/doctorContact/doctor_contact_person_from_aims2health_director_dropdown',
            { headers }).then((res) => {
                setIsLoading(false);
                let values = res.data.doctorsList;
                setDoctorList(values);
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
            })
    };

    useEffect(() => {
        // Do api call here....
        window.scrollTo(0, 0)
        getContactDetailsList()
    }, []);

    const doctorDetails = (
        <Card>
            <Card.Header >
                <Button primary={true} onClick={drContactDetailsModalToggle} >Update details</Button>
            </Card.Header>
            <Card.Section>
                <DescriptionList
                    items={[
                        {
                            term: 'Doctor Phone Contact',
                            description: phonecontact === "" ? "Details not available " : phonecontact
                        },
                        {
                            term: 'Doctor Email Contact',
                            description: emailcontact === "" ? "Details not available " : emailcontact
                        },
                        {
                            term: 'Doctor Contact Person From AIMS2Health Director',
                            description: contactperson === "" ? "Details not available " : contactperson
                        },
                        {
                            term: 'Doctor Phone Number For Prescription',
                            description: prescriptionPhone === "" ? "Details not available " : prescriptionPhone
                            
                        },
                        {
                            term: 'Doctor Address For Prescription',
                            description: prescriptionAddress === "" ? "Details not available " : prescriptionAddress                            
                        },
                    ]}
                />
            </Card.Section>
        </Card >
    )

    const drcontactDetailsModalMarkup = (
        <div>
            <Modal
                large
                open={updateDrDetailsModalActive}
                onClose={drContactDetailsModalToggle}
                title="Update Doctor Contact Details"
                primaryAction={{
                    content: 'Submit',
                    onAction: updateDrContactDetails,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: drContactDetailsModalToggle,
                    },
                ]}
            >
                <Modal.Section>
                    <FormLayout.Group>
                        <div>
                            <TextField
                                value={updateDrPhone}
                                label="Doctor Phone Contact"
                                placeholder="Enter contact number"
                                id="contactNumberID"
                                onChange={handleUpdateDrPhoneChange}
                            />
                            {/* <InlineError message={updateDrPhoneErrorDiv} fieldID="contactNumberID" /> */}
                        </div>
                        <div>
                            <TextField
                                value={updateDrEmail}
                                label="Doctor Email Contact"
                                placeholder="Enter Email Id"
                                id="doctorEmailID"
                                onChange={handleUpdateDrEmailChange}
                            />
                            {/* <InlineError message={updateDrEmailErrorDiv} fieldID="doctorEmailID" /> */}
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group>
                        <Stack>
                            <Stack.Item fill>
                                <div style={{ marginBottom: "5px" }}>Doctor Contact Person From AIMS2Health Director</div>
                                <SelectPicker
                                    block
                                    onChange={handleDocNameChange}
                                    data={doctorList}
                                    value={selectedDocName}
                                />
                                {/* <InlineError message={updateAimsDirectorErrorDiv} fieldID="directorID" /> */}
                            </Stack.Item>
                        </Stack>
                    </FormLayout.Group>
                    <FormLayout.Group>
                        <div>
                            <TextField
                                value={updateDrPresciptionPhone}
                                label="Doctor Phone Number For Prescription"
                                placeholder="Enter dr. phone number for prescription"
                                id="contactNumberID"
                                onChange={handleUpdateDrPresciptionPhoneChange}
                            />
                            {/* <InlineError message={updateDrPhoneErrorDiv} fieldID="contactNumberID" /> */}
                        </div>
                        <div>
                            <TextField
                                value={updateDrPrescriptionAddress}
                                label="Doctor Address For Prescription"
                                placeholder="Enter dr. address for prescription"
                                id="doctorEmailID"
                                multiline={1}
                                onChange={handleUpdateDrPrescriptionAddressChange}
                            />
                            {/* <InlineError message={updateDrEmailErrorDiv} fieldID="doctorEmailID" /> */}
                        </div>
                    </FormLayout.Group>
                    <Box p={1}>{updateContactDetailsDiv}</Box>
                    <Box p={1}>{updateContactDetailsErrorDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )
    return (
        <div>
            {toSignin ? <Redirect to="/signin" /> : null}
            {doctorDetails}
            {drcontactDetailsModalMarkup}
            {updateSummaryErrorDiv}
        </div>
    );
}