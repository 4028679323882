import React, { useState, useCallback } from 'react';
import { Page, Tabs } from '@shopify/polaris';
import { Box } from '@primer/components';
import CorporatePackageActive from './CorporatePackageActive';
import CorporatePackageInactive from './CorporatePackageInactive';
import AdminLoader from '../AdminLoader';


export default function CorporatePackage() {
    const loadingMarkup = <AdminLoader />;
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedCorporatetab", String(selectedTabIndex));
    }, []);

    const [corporateActivePackageLoader, setCorporateActivePackageLoader] = useState(false);
    const [corporateInActivePackageLoader, setCorporateInActivePackageLoader] = useState(false);

    const tabs = [
        {
            id: 'active-packages',
            content: 'Active Packages',
            body: (
                <CorporatePackageActive corporateActivePackageLoader={setCorporateActivePackageLoader} />
            ),
        },
        {
            id: 'inactive-packages',
            content: 'Inactive Packages',
            body: (
                <CorporatePackageInactive corporateInActivePackageLoader={setCorporateInActivePackageLoader} />
            ),
        },
    ];

    return (
        <div>
            {corporateActivePackageLoader === true || corporateInActivePackageLoader === true ? loadingMarkup : "" }
            <Page fullWidth title="Corporate Packages">
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    )
}