import React, { useState, useCallback } from 'react';
import { Box } from '@primer/components';
import {
    Tabs,
    Page,
} from '@shopify/polaris';
import ActiveNotes from './ActiveNotes';
import InactiveNotes from './InActiveNotes';
import AdminLoader from '../AdminLoader';

export default function Notes(props:any) {
    const {notesLoader} =props;

    const [activeTaskLoader, setActiveTaskLoader] = useState(false);
    const [inactiveTaskLoader, setInactiveTaskLoader] = useState(false);
    
    let isLoading = (activeTaskLoader === true ||  inactiveTaskLoader === true) ? true : false;
    notesLoader(isLoading);

    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);


    const tabs = [
        {
            id: 'active-notes',
            content: 'Active Notes',
            body: (
                <ActiveNotes activeTaskLoader={setActiveTaskLoader} />
            ),
        },
        {
            id: 'inactive-notes',
            content: 'Inactive Notes',
            body: (
                <InactiveNotes inactiveTaskLoader={setInactiveTaskLoader} />
            ),
        },
    ];

    return (
        <div>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </div>

    )

}