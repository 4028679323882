import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Page,
    Loading,
    DataTable,
    Button,
    ExceptionList,
    Modal,
    FormLayout,
    TextField,
    Toast,
    InlineError,
    TextContainer,
    Subheading,
    Banner,
    SkeletonPage,
    SkeletonDisplayText,
    SkeletonBodyText,
    Stack,
} from '@shopify/polaris';
import { NoteMinor, ViewMinor, EditMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import Url from '../../ApiConfig';
import { useHistory, Redirect } from 'react-router-dom';
import { Box } from '@primer/components';
import AdminLoader from './AdminLoader';
export default function ExecutiveList() {
    const [executiveValues, setExecutiveValues] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = <AdminLoader />;
    const [toSignin, setToSignin] = useState(false);
    const history = useHistory();

    let token = localStorage.getItem("token");

    const [executiveFirstName, setExecutiveFirstName] = useState('');
    const handleExecutiveFirstNameChange = useCallback((value) => setExecutiveFirstName(value), []);
    const [errorExecutiveFirstName, setErrorExecutiveFirstName] = useState('');

    const [executiveLastName, setExecutiveLastName] = useState('');
    const handleExecutiveLastNameChange = useCallback((value) => setExecutiveLastName(value), []);
    const [errorExecutiveLastName, setErrorExecutivelastName] = useState('');

    const [executiveEmail, setExecutiveEmail] = useState('');
    const handleExecutiveEmailChange = useCallback((value) => setExecutiveEmail(value), []);
    const [errorExecutiveEmail, setErrorExecutiveEmail] = useState('');

    const [executivePhoneNo, setExecutivePhoneNo] = useState('');
    const handleExecutivePhoneNoChange = useCallback((value) => setExecutivePhoneNo(value), []);
    const [errorExecutivePhoneNo, setErrorExecutivePhoneNo] = useState('');

    const [editExecutiveId, setEditExecutiveId] = useState('');
    const [userSelectedId, setUserSelectedId] = useState('');

    // Add Executive Modal
    const [executiveModalActive, setExecutiveModalActive] = useState(false);
    const handleExecutiveModalToggle = () => {
        setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
        setExecutiveFirstName('');
        setExecutiveLastName('');
        setExecutiveEmail('');
        setExecutivePhoneNo('');
        setEditExecutiveId('');
        setErrorExecutiveFirstName("");
        setErrorExecutivelastName("");
        setErrorExecutiveEmail("");
        setErrorExecutivePhoneNo("")
    }

    // Edit Executive Modal
    const [editExecutiveModalActive, setEditExecutiveModalActive] = useState(false);
    const handleEditExecutiveModalToggle = () => {
        setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
        setExecutiveFirstName('');
        setExecutiveLastName('');
        setExecutiveEmail('');
        setExecutivePhoneNo('');
        setEditExecutiveId('');
        setErrorExecutiveFirstName("");
        setErrorExecutivelastName("");
        setErrorExecutiveEmail("");
        setErrorExecutivePhoneNo("")
    }

    // Delete Executive Modal
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteExecutiveModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    const handleCancelExecutiveModalToggle = () => {
        setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
        setDeleteModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
        setDeleteExecutiveModalSuccess("");
        setDeleteExecutiveModalError("");
    }

    // Add / update Executive Success Modal
    const [successAddUpdateExecutiveToastMessage, setSuccessAddUpdateExecutiveToastMessage] = useState("");
    const [successAddUpdateExecutiveToastactive, setSuccessAddUpdateExecutiveToastactive] = useState(false);
    const successAddUpdateExecutiveToastToggleActive = useCallback(() => setSuccessAddUpdateExecutiveToastactive((successAddUpdateExecutiveToastactive) => !successAddUpdateExecutiveToastactive), []);
    const successAddUpdateExecutiveToastMarkup = successAddUpdateExecutiveToastactive ? (
        <Toast content={successAddUpdateExecutiveToastMessage} onDismiss={successAddUpdateExecutiveToastToggleActive} />
    ) : null;

    // Add / Update Executive Error Modal
    const [errorAddUpdateExecutiveToastMessage, setErrorAddUpdateExecutiveToastMessage] = useState("");
    const [errorAddUpdateExecutiveToastActive, setErrorAddUpdateExecutiveToastActive] = useState(false);
    const errorAddUpdateExecutiveToastToggleActive = useCallback(() => setErrorAddUpdateExecutiveToastActive((errorAddUpdateExecutiveToastActive) => !errorAddUpdateExecutiveToastActive), []);
    const errorAddUpdateExecutiveToastMarkup = errorAddUpdateExecutiveToastActive ? (
        <Toast content={errorAddUpdateExecutiveToastMessage} error onDismiss={errorAddUpdateExecutiveToastToggleActive} />
    ) : null;

    // Go to Executive Details Page
    const ViewExecutiveDetails = (executiveId: any) => {
        history.push(`/executivetaskdetails/${executiveId}`);
    }

    //For delete popup..
    const closeSuccess = () => {
        setDeleteExecutiveModalError("");
    }

    const [deleteExecutiveModalSuccess, setDeleteExecutiveModalSuccess] = useState('');
    const successExecutiveModalDiv = deleteExecutiveModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deleteExecutiveModalSuccess}</p>
        </Banner>
        : '';
    const [deleteExecutiveModalError, setDeleteExecutiveModalError] = useState('');
    const errorDeleteExecutiveModalDiv = deleteExecutiveModalError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{deleteExecutiveModalError}</p>
        </Banner>
        : '';

    //api call section ...        
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Edit Executive Modal Open Function
    const editExecutive = (response: any, userid: any) => {
        setEditExecutiveModalActive((editExecutiveModalActive) => !editExecutiveModalActive);
        setEditExecutiveId(response.id)
        setUserSelectedId(userid);
        setExecutiveFirstName(response.first_name);
        setExecutiveLastName(response.last_name);
        setExecutiveEmail(response.email);
        setExecutivePhoneNo(response.phoneNumber);
    }
    // Get Executive List API
    const getExecutiveList = () => {
        setIsLoading(true);
        axios.get(Url.baseUrl + '/adminapp/api/executives/executive_list_all', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.executiveList;
                let ExecutiveListValue = [];
                for (var i = 0; i < response.length; i++) {
                    let selectedResponse = response[i];
                    let executiveId = response[i].id;
                    let userid = response[i].userId;
                    let executiveEmail = (response[i].email == null) ? '' : response[i].email;
                    let executiveFirstName = (response[i].first_name == null) ? '' : response[i].first_name;
                    let executiveLastName = (response[i].last_name == null) ? '' : response[i].last_name;
                    let executivePhoneNumber = (response[i].phoneNumber == null) ? '' : response[i].phoneNumber;
                    ExecutiveListValue.push([
                        <Button icon={ViewMinor} onClick={() => {
                            ViewExecutiveDetails(executiveId)
                        }}> Task</Button>,
                        executiveFirstName,
                        executiveLastName,
                        executiveEmail,
                        executivePhoneNumber,
                        <Button icon={EditMinor} primary onClick={() => editExecutive(selectedResponse, userid)}>Edit</Button>,
                    ]);
                }
                setExecutiveValues(ExecutiveListValue);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error.response.data.message)
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    };

    // Executive Add Body
    const executiveAddBody = {
        "first_name": executiveFirstName,
        "last_name": executiveLastName,
        "email": executiveEmail,
        "phoneNumber": executivePhoneNo
    }
    // Add / Edit Executive API
    const addUpdateExecutive = () => {
        if (executiveFirstName === "" && executiveLastName === "" && executiveEmail === "" && executivePhoneNo === "") {
            setErrorExecutiveFirstName("First name is required");
            setErrorExecutivelastName("Last name is required");
            setErrorExecutiveEmail("Email is required");
            setErrorExecutivePhoneNo("Phone Number is required")
        }
        else if (executiveFirstName != "" && executiveLastName === "" && executiveEmail != "") {
            setErrorExecutiveFirstName("First name is required");
            setErrorExecutivelastName("Last name is required");
            setErrorExecutiveEmail("Email is required");
        }
        else if (executiveFirstName === "" && executiveLastName != "" && executiveEmail === "") {
            setErrorExecutiveFirstName("First name is required");
            setErrorExecutivelastName("");
            setErrorExecutiveEmail("Email is required");
        }
        else if (executiveFirstName === "" && executiveLastName != "" && executiveEmail != "") {
            setErrorExecutiveFirstName("First name is required");
            setErrorExecutivelastName("");
            setErrorExecutiveEmail("");
        }
        else if (executiveFirstName != "" && executiveLastName === "" && executiveEmail === "") {
            setErrorExecutiveFirstName("");
            setErrorExecutivelastName("Last name is required");
            setErrorExecutiveEmail("Email is required");
        }
        else if (executiveFirstName != "" && executiveLastName === "" && executiveEmail != "") {
            setErrorExecutiveFirstName("");
            setErrorExecutivelastName("Last name is required");
            setErrorExecutiveEmail("");
        }
        else if (executiveFirstName != "" && executiveLastName != "" && executiveEmail === "") {
            setErrorExecutiveFirstName("");
            setErrorExecutivelastName("");
            setErrorExecutiveEmail("Email is required");
        }
        else {
            setIsLoading(true);
            let url, executiveEditSubmitBody;
            if (editExecutiveId) {
                url = Url.baseUrl + '/adminapp/api/executives/edit_excutive';
                executiveEditSubmitBody = Object.assign(executiveAddBody, { executiveId: String(editExecutiveId), userId: userSelectedId })
            }
            else {
                url = Url.baseUrl + '/adminapp/api/executives/add_excutive';
            }

            axios.post(url, JSON.stringify(executiveAddBody),
                { headers })
                .then((res) => {
                    setIsLoading(false);
                    setSuccessAddUpdateExecutiveToastMessage(res.data.message);
                    handleExecutiveModalToggle();
                    getExecutiveList();
                    successAddUpdateExecutiveToastToggleActive();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setErrorAddUpdateExecutiveToastMessage(error.response.data.message);
                    errorAddUpdateExecutiveToastToggleActive();
                })
        }
    }

    // Delete Executive Body
    const deleteExecutiveBody =
    {
        "executiveId": String(editExecutiveId),
        "isActive": false
    }

    // Delete Executive API .. 
    const DeleteExecutiveSubmit = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/adminapp/api/executives/delete_excutive',
            deleteExecutiveBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteExecutiveModalError("");
                setDeleteExecutiveModalSuccess(res.data.message);
                getExecutiveList();
                setDeleteModalActive((deleteModalActive) => !deleteModalActive);
            })

            .catch((error) => {
                setIsLoading(false);
                setDeleteExecutiveModalSuccess("");
                setDeleteExecutiveModalError(error.response.data.message);
            })
    }

    useEffect(() => {
        getExecutiveList();
    }, []);

    // Add Executive Modal
    const addExecutiveModalMarkup = (
        <Modal
            open={executiveModalActive}
            onClose={handleExecutiveModalToggle}
            title={editExecutiveId ? "Edit AIMS2Health Executive" : "Add AIMS2Health Executive"}
            primaryAction={{
                content: 'Submit',
                onAction: addUpdateExecutive,
            }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: handleExecutiveModalToggle,
                },
            ]}
        >
            <Modal.Section>
                <FormLayout>
                    <FormLayout.Group>
                        <div>
                            <TextField
                                label="First Name"
                                placeholder="Enter first name"
                                value={executiveFirstName}
                                onChange={handleExecutiveFirstNameChange}
                                id="executiveFName"
                            />
                            <InlineError message={errorExecutiveFirstName} fieldID="executiveFName" />
                        </div>
                        <div>
                            <TextField
                                label="Last Name"
                                placeholder="Enter last name"
                                value={executiveLastName}
                                onChange={handleExecutiveLastNameChange}
                                id="executiveLName"
                            />
                            <InlineError message={errorExecutiveLastName} fieldID="executiveLName" />
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group>
                        <div>
                            <TextField
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                value={executivePhoneNo}
                                onChange={handleExecutivePhoneNoChange}
                                id="executivePhoneNumber"
                            />
                            <InlineError message={errorExecutivePhoneNo} fieldID="executivePhoneNumber" />
                        </div>
                        <div>
                            <TextField
                                label="Email"
                                placeholder="Enter email"
                                value={executiveEmail}
                                onChange={handleExecutiveEmailChange}
                                id="executiveEmail"
                            />
                            <InlineError message={errorExecutiveEmail} fieldID="executiveEmail" />
                        </div>
                    </FormLayout.Group>
                </FormLayout>
            </Modal.Section>
        </Modal>
    );

    // Edit Executive Modal
    const editExecutiveModalMarkup = (
        <Modal
            open={editExecutiveModalActive}
            onClose={handleEditExecutiveModalToggle}
            title={editExecutiveId ? "Edit AIMS2Health Executive" : "Add AIMS2Health Executive"}
            primaryAction={{
                content: 'Update',
                onAction: addUpdateExecutive,
            }}
            secondaryActions={[
                {
                    content: 'Delete',
                    onAction: handleCancelExecutiveModalToggle,
                },
                {
                    content: 'Cancel',
                    onAction: handleEditExecutiveModalToggle,
                },
            ]}
        >
            <Modal.Section>
                <FormLayout>
                    <FormLayout.Group>
                        <div>
                            <TextField
                                label="First Name"
                                placeholder="Enter first name"
                                value={executiveFirstName}
                                onChange={handleExecutiveFirstNameChange}
                                id="executiveFName"
                            />
                            <InlineError message={errorExecutiveFirstName} fieldID="executiveFName" />
                        </div>
                        <div>
                            <TextField
                                label="Last Name"
                                placeholder="Enter last name"
                                value={executiveLastName}
                                onChange={handleExecutiveLastNameChange}
                                id="executiveLName"
                            />
                            <InlineError message={errorExecutiveLastName} fieldID="executiveLName" />
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group>
                        <div>
                            <TextField
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                value={executivePhoneNo}
                                onChange={handleExecutivePhoneNoChange}
                                id="executivePhoneNumber"
                            />
                            <InlineError message={errorExecutivePhoneNo} fieldID="executivePhoneNumber" />
                        </div>
                        <div>
                            <TextField
                                label="Email"
                                placeholder="Enter email"
                                value={executiveEmail}
                                onChange={handleExecutiveEmailChange}
                                id="executiveEmail"
                            />
                            <InlineError message={errorExecutiveEmail} fieldID="executiveEmail" />
                        </div>
                    </FormLayout.Group>
                </FormLayout>
            </Modal.Section>
        </Modal>
    );

    // Executive List Table
    const ExecutiveTable = (
        <Card>
            <Card.Header>
                <Button primary={true} onClick={handleExecutiveModalToggle}>Add Executive</Button>
            </Card.Header>
            <Card.Section>
                {executiveValues && executiveValues.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View',
                            'First Name',
                            'Last Name',
                            'Executive Email',
                            'Phone Number',
                            'Edit',
                        ]}
                        rows={executiveValues}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Executive list is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    // Delete Executive Modal
    const deleteExecutiveComponent = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteExecutiveModalChange}
            title="Delete Executive"
            primaryAction={{
                content: "Yes",
                onAction: DeleteExecutiveSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteExecutiveModalChange,
                },
            ]}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Executive: {executiveFirstName} {executiveLastName} ?
                    </Subheading>
                    <div>{successExecutiveModalDiv}</div>
                    <div>{errorDeleteExecutiveModalDiv}</div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const loaderskeleton = (
        <Page fullWidth title="Executive List">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (
        <div>
            {isLoading == true ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> :
                <Page fullWidth title="Executive List">
                    {toSignin ? <Redirect to="/signin" /> : null}
                    {/* {loadingMarkup} */}
                    {ExecutiveTable}
                    {addExecutiveModalMarkup}
                    {editExecutiveModalMarkup}
                    {deleteExecutiveComponent}
                    {successAddUpdateExecutiveToastMarkup}
                    {errorAddUpdateExecutiveToastMarkup}
                </Page>
            }
        </div>

        // <div>
        //     {AllCaseListValue.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth title="Executive List">
        //         {
        //             AllCaseListValue.loading ? "" :
        //                 <ThemeProvider theme={theme}>
        //                     {taskTable}
        //                 </ThemeProvider>
        //         }
        //     </Page>}
        // </div>
    );
}
