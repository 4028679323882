import React, { useState, useEffect } from 'react';
import {
    Loading,
    Button,
    Page,
    Card,
    DataTable,
    ExceptionList,
    Stack,
    Toast,
    SkeletonPage,
    TextContainer,
    FormLayout,
    SkeletonDisplayText,
    SkeletonBodyText,
} from '@shopify/polaris';
import { Redirect, useParams } from 'react-router-dom';
import { NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import { getAdminAllAppointmentList } from '../../redux/effects/ApiCall';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppState } from '../../redux/store/Store';
import axios from 'axios';
import baseUrl from '../../ApiConfig';
import { useCallback } from 'react';
import { Box } from '@primer/components';
import AdminLoader from './AdminLoader';

export default function AppointmentList() {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 30) + "..." : str;
    }

    const dispatch = useDispatch();
    const [toSignin] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkuphori = isLoading ? <AdminLoader /> : null;

    const loadingMarkup = <AdminLoader />;

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const [page, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log("PAGECOUNT==>", value);
        setPage(value);
        dispatch(getAdminAllAppointmentList(appointmentBody, value));
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const toastMarkup = active ? (
        <Toast content={successMessage} onDismiss={toggleActive} />
    ) : null;

    const appointmentBody = {
        "appointmentStatus": ""
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    function deactivate(appointmentId: any) {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointmentList/deactivate_appointment_for_admin', { "appointmentId": String(appointmentId) }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessMessage(res.data.message);
                toggleActive();
                dispatch(getAdminAllAppointmentList(appointmentBody, page));
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    // call Store
    const AdminAppointmentList = useSelector((state: AppState) => state.allAppointmentListValue);
    const pageCount = useSelector((state: AppState) => state.allAppointmentListValue.appointmentPageCount);
    let allAppointmentValues = AdminAppointmentList.allAppointmentListValue;

    // Selection Type GET API
    let appoinmentValueList: any = [];
    for (var i = 0; i < allAppointmentValues.length; i++) {
        let appointmentId = (allAppointmentValues[i].hasOwnProperty('id')) ? (String(allAppointmentValues[i].id) == "" || String(allAppointmentValues[i].id) == null) ? "" : allAppointmentValues[i].id : "";
        let patientName = (allAppointmentValues[i].hasOwnProperty('patientName')) ? (allAppointmentValues[i].patientName == "" || allAppointmentValues[i].patientName == null) ? "" : allAppointmentValues[i].patientName : "";
        let description = (allAppointmentValues[i].hasOwnProperty('name')) ? (allAppointmentValues[i].name == "" || allAppointmentValues[i].name == null) ? "No Details Available" : truncate(allAppointmentValues[i].name) : "";
        let createdDate = (allAppointmentValues[i].hasOwnProperty('createDate')) ? (allAppointmentValues[i].createDate == null) ? "" : (new Date(allAppointmentValues[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS) : null;
        let endDate = (allAppointmentValues[i].hasOwnProperty('end')) ? (allAppointmentValues[i].end == null) ? "" : (new Date(allAppointmentValues[i].end)).toLocaleDateString('en-US', DATE_OPTIONS) : null;
        let modifiedDate = (allAppointmentValues[i].hasOwnProperty('modifiedDate')) ? (allAppointmentValues[i].modifiedDate == null) ? "" : (new Date(allAppointmentValues[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS) : null;
        let appoinmentStatus = (allAppointmentValues[i].hasOwnProperty('status')) ? (allAppointmentValues[i].status === null || allAppointmentValues[i].status === "") ? "" : allAppointmentValues[i].status : "";
        appoinmentValueList.push([
            patientName,
            description,
            createdDate,
            endDate,
            modifiedDate,
            appoinmentStatus,
            <Button icon={DeleteMinor} size="slim" onClick={() => deactivate(appointmentId)}>Deactive</Button>
        ]);
    }
    const appoinmentRequestListValue: any[] = appoinmentValueList;

    // Reload Appointment API
    const reloadAppointmentList = () => {
        dispatch(getAdminAllAppointmentList(appointmentBody, page));
    };
    useEffect(() => {
        // Do api call here.... 
        dispatch(getAdminAllAppointmentList(appointmentBody, page));
    }, []);

    const tableMarkup = (
        <Card >
            <Card.Section>
                <Card.Header>
                    <ThemeProvider theme={theme}>
                        <Stack>
                            <Typography>Page: {page}</Typography>
                            <Pagination color="primary" count={Number(pageCount)} page={page} onChange={handleChange} />
                        </Stack>
                    </ThemeProvider>
                    <Button primary={false} onClick={reloadAppointmentList}>Reload Appointment List</Button>
                </Card.Header>

                {appoinmentRequestListValue && appoinmentRequestListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Patient Name',
                            'Description',
                            'Create Date',
                            'End Date',
                            'Modified Date',
                            'Status',
                            'Deactive',
                        ]}
                        rows={appoinmentRequestListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Appointment Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    const loaderskeleton = (
        <Page fullWidth title="Appointment List">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (
        // <Page title="Appointment List" fullWidth>
        //     {toSignin ? <Redirect to="/signin" /> : null}
        //     {AdminAppointmentList.loading ? loadingMarkup : ''}
        //     {loadingMarkuphori}
        //     {tableMarkup}
        //     {toastMarkup}
        // </Page>

        <div>
            {toSignin ? <Redirect to="/signin" /> : null}
            {AdminAppointmentList.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> :
                <div>
                    {loadingMarkuphori}
                    <Page fullWidth title="Appointment List">
                        {
                            AdminAppointmentList.loading ? "" :
                                <ThemeProvider theme={theme}>
                                    {tableMarkup}
                                </ThemeProvider>
                        }
                    </Page>
                </div>
            }
            {toastMarkup}
        </div>
    );
}