import React, { useState, useCallback, useEffect } from 'react';
import {
    Page,
    Tabs
} from '@shopify/polaris';
import { Box } from '@primer/components';
import ImgUpload from './ImgUpload';
import ImportFromPhone from '../pages/ImportFromPhone';
import ImportFromEmail from '../pages/ImportFromEmail';
import ImportFromWhatsApp from '../pages/ImportFromWhatsApp';
import ImportFromChat from '../pages/ImportFromChat';
import AdminLoader from './AdminLoader';

export default function ImportData() {

    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedOnboardingtab", String(selectedTabIndex));
    }, []);
    const loadingMarkup = <AdminLoader />;
    const [importFromPhoneLoader, setImportFromPhoneLoader] = useState(false);
    // const [importFromEmailLoader, setImportFromEmailLoader] = useState(false);
    // const [importFromWhatsAppLoader, setImportFromWhatsAppLoader] = useState(false);
    // const [importFromChatLoader, setImportFromChatLoader] = useState(false);


    const tabs = [
        {
            id: 'Phone',
            content: 'Import From Phone',
            body: (
                <ImportFromPhone importFromPhoneLoader={setImportFromPhoneLoader}/>
            ),
        },
        {
            id: 'Email',
            content: 'Import From Email',
            body: (
                <ImportFromEmail />
            ),
        },
        {
            id: 'WhatsApp',
            content: 'Import From WhatsApp',
            body: (
                <ImportFromWhatsApp />
            ),
        },
        {
            id: 'Chat',
            content: 'Import From Chat',
            body: (
                <ImportFromChat/>
            ),
        },

    ];
    useEffect(() => {
        window.scrollTo(0, 0);
        // get call here.... 
    }, []);
    
    return (
        <div>
        {(importFromPhoneLoader === true  ) ? loadingMarkup : ""}
        <Page title="Imported Data" fullWidth>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </Page>
        </div>
    );
}