import React, { useState, useEffect, useCallback } from 'react';
import {
    Loading,
    Button,
    Page,
    Card,
    DataTable,
    TextStyle,
    ExceptionList,
    Toast,
    Stack,
    SkeletonPage,
    TextContainer,
    FormLayout,
    SkeletonBodyText,
    SkeletonDisplayText,
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../ApiConfig';
import { Redirect } from 'react-router-dom';
import { PaymentsMajor, ViewMinor, NoteMinor, RefreshMinor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@primer/components';
import AdminLoader from './AdminLoader';

export default function Payment(props: any) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    const [toSignin, setToSignin] = useState(false);

    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = <AdminLoader />;

    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [toastSuccessActive, setToastSuccessActive] = useState(false);
    const toggleToastSuccessActive = useCallback(() => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive), []);

    const toastSuccessMarkup = toastSuccessActive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleToastSuccessActive} />
    ) : null;

    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [toastErrorActive, setToastErrorActive] = useState(false);
    const toggleToastErrorActive = useCallback(() => setToastErrorActive((toastErrorActive) => !toastErrorActive), []);

    const toastErrorMarkup = toastErrorActive ? (
        <Toast content={toastErrorMessage} onDismiss={toggleToastErrorActive} />
    ) : null;

    const [paymentDetailsId, setPaymentDetailsId] = useState('');
    function checkPaymentStatusDetails(id: any) {
        setPaymentDetailsId(id);
        checkPaymentStatusDetailsApicall(id);
    }
    // Check Payment Status API
    function checkPaymentStatusDetailsApicall(id: any) {
        setIsLoading(true);
        console.log("PAYMENTID==>", id);
        axios.post(baseUrl.baseUrl + '/paymentapp/api/payment/get_payment_status',
            { "paymentId": id }, { headers })
            .then(result => {
                setIsLoading(false);            
                setToastSuccessMessage(result.data.message);
                toggleToastSuccessActive();
                dispatch(getPaymentList(page, body));
                
            })
            .catch((error) => {
                setToastErrorMessage(error.response.data.message);
                toggleToastErrorActive();
                setIsLoading(false);
                dispatch(getPaymentList(page, body));
            })
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const body = {
        "paymentStatus": ""
    };

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const timeConvert = (time: string) => {
        let hours: any = ((Number(time.split(":")[0]) + 11) % 12 + 1);
        let suffix = Number(time.split(":")[0]) >= 12 ? "PM" : "AM";
        hours = ((hours + 11) % 12 + 1);
        hours = hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        hours = hours + ":" + time.split(":")[1] + " " + suffix;
        return hours;
    }

    const AdminAllPaymentList = useSelector((state: AppState) => state.allPaymentListValue);
    const allPaymentValues = AdminAllPaymentList.allPaymentListValue;

    const pageCount = useSelector((state: AppState) => state.allPaymentListValue.PageCount);
    console.log("PAGECOUNT==>", pageCount);

    let paymentsValueList = [];
    for (var i = 0; i < allPaymentValues.length; i++) {
        let paymentid = allPaymentValues[i].id;
        let selectedResponse = allPaymentValues[i].paymentLink;
        let description = (allPaymentValues[i].note == "") ? "No Details Available" : allPaymentValues[i].note;
        let amount = (allPaymentValues[i].amount == "") ? "Amount Not Available" : allPaymentValues[i].amount;
        let round_amount = Number(amount) / 100;
        let createDate = (allPaymentValues[i].create_date == null) ? "" : (new Date(allPaymentValues[i].create_date)).toLocaleDateString('en-US', DATE_OPTIONS);
        let appoinmentPaidTime = (allPaymentValues[i].hasOwnProperty('paidTime')) ? (allPaymentValues[i].paidTime === null) ? "" : (new Date(allPaymentValues[i].paidTime)).toLocaleDateString('en-US', DATE_OPTIONS) + " at " + (new Date(allPaymentValues[i].paidTime)).getHours() + ":" + (new Date(allPaymentValues[i].paidTime)).getMinutes() : ""; //timeConvert(allPaymentValues[i].paidTime)
        let paymentstatus = (allPaymentValues[i].status === null) ? "" : allPaymentValues[i].status;
        paymentsValueList.push([
            description,
            createDate,
            "Rs. " + round_amount,
            <Button icon={PaymentsMajor} size="slim" disabled={(paymentstatus === "paid" || paymentstatus === "free") ? true : false} onClick={() => paymentLink(selectedResponse)}>
                Pay Now
            </Button>,
            appoinmentPaidTime,
            <TextStyle>{paymentstatus}</TextStyle>,
            <Button icon={ViewMinor} size="slim" disabled={(amount === "0.00") ? true : false} onClick={() => checkPaymentStatusDetails(paymentid)}></Button>,
        ]);
    }
    const paymentListValue: any[] = paymentsValueList;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log("PAGECOUNT==>", value);
        setPage(value);
        dispatch(getPaymentList(value, body));
    };

    function paymentLink(response: any) {
        window.open(response);
    }

    function reloadPaymentList() {
        dispatch(getPaymentList(page, body));
    }

    useEffect(() => {
        // Do api call here.... 
        window.scrollTo(0,0);
        dispatch(getPaymentList(page, body));
    }, [dispatch]);


    const tableMarkup = (
        <Card >
            <Card.Section>
                <Card.Header>
                    <ThemeProvider theme={theme}>
                        <Stack>
                            <Box pt={1}><Typography>Page: {page}</Typography></Box>                            
                            <Pagination color="primary" count={Number(pageCount)} page={page} onChange={handleChange} />
                            <Button icon={RefreshMinor} primary={false} onClick={reloadPaymentList}>Reload Payment List</Button>
                        </Stack>
                    </ThemeProvider>
                </Card.Header>
                <br />
                {paymentListValue && paymentListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Description',
                            'Create Date',
                            'Amount',
                            'Pay',
                            'Paid On',
                            'Payment Status',
                            'Check Status'
                        ]}
                        rows={paymentListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Payment Details is available!',
                            },
                        ]}
                    />
                )}
            </Card.Section>
        </Card>
    );

    const loaderskeleton = (
        <Page fullWidth title="Payment List For Admin">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (
        // <Page fullWidth title="Payment List For Admin">
        //     {toSignin ? <Redirect to="/signin" /> : null}
        //     {AdminAllPaymentList.loading ? paymentloadingMarkup : ''}
        //     {loadingMarkup}
        //     {tableMarkup}
        //     {toastSuccessMarkup}
        //     {toastErrorMarkup}
        // </Page>

        <div>
            {(AdminAllPaymentList.loading === true || isLoading === true) ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> :
                <Page fullWidth title="Payment List For Admin">
                    {
                        AdminAllPaymentList.loading ? "" :
                            <ThemeProvider theme={theme}>
                                {tableMarkup}
                            </ThemeProvider>
                    }
                </Page>}
            {toastSuccessMarkup}
            {toastErrorMarkup}
        </div>
    );
}