import React, { useState, useCallback, useEffect } from 'react';
import { Page, Tabs } from '@shopify/polaris';
import { Box } from '@primer/components';
import ActivePatientList from './CorporatePatientList/ActivePatientList';
import ActiveAppointmentList from './CorporateAppointmentList/ActivePaymentList';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { ActiveCorporateList } from '../../../redux/interfaces/Model';
import { getActiveCorporateList } from '../../../redux/effects/ApiCall';
import * as _ from 'lodash';

export default function ActiveCorporatePackageDetails() {
    const dispatch = useDispatch();

    const { id } = useParams() as {
        id: string;
    };
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedActivePatient-PaymentTab", String(selectedTabIndex));
    }, []);

    // call Store
    const activeCorporatePackageList = useSelector((state: AppState) => state.activeCorporateListValue);
    const activeCorporateListDetails: ActiveCorporateList = _.filter(activeCorporatePackageList.activeCorporateListValue, ['id', Number(id)])[0];

    useEffect(() => {
        window.scrollTo(0, 0);
        if (activeCorporateListDetails === undefined) {
            dispatch(getActiveCorporateList());
        }
    }, [dispatch, activeCorporateListDetails]);

    const tabs = [
        {
            id: 'active-patient-list',
            content: 'Patient Team List in Corporate Package',
            body: (
                <ActivePatientList packageId={id} />
            ),
        },
        {
            id: 'active-payment-list',
            content: 'Payment List in Corporate Package',
            body: (
                <ActiveAppointmentList packageId={id} />
            ),
        },
    ];

    return (
        <Page fullWidth
            breadcrumbs={[{ content: 'Corporate Packages', url: '/corporatepackages' }]}
            title={`${(activeCorporateListDetails === undefined) ? "" : activeCorporateListDetails.name + "'s Details"}`}
        >
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </Page>
    )
}
