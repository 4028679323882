import React, { useState, useCallback } from 'react';
import {
    Page,
    Tabs
} from '@shopify/polaris';
import { Box } from '@primer/components';
import ImportedDataList from './ImportedDataList';
import ImportedConfigFile from './ImportedConfig';

export default function ImportedData() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

    const tabs = [
        {
            id: 'masterimporteddata',
            content: 'Imported Data',
            body: (
                <ImportedDataList />
            )
            ,
            panelID: 'master-importeddata-content',
        },
        {
            id: 'masterimportedconfig',
            content: 'Import Config',
            body: (
                <ImportedConfigFile />
            ),
            accessibilityLabel: 'All imports',
            panelID: 'master-importedconfig-content',
        },

    ];
    const symptomsAddInfo = (
        <div>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </div >
    )
    return (
        <Page title="Import Data" fullWidth>
            {symptomsAddInfo}
        </Page>
    );
}