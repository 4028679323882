import React, { useEffect, useState } from 'react';
import { Page, Card, DataTable, ExceptionList } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorOnboardingRejectList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { NoteMinor } from '@shopify/polaris-icons';

export default function DoctorsOnboardingRequest(props: any) {
    const dispatch = useDispatch();
    const { docOndoardingRejectLoading } = props;
    const [isLoading, setIsLoading] = useState(false);
    docOndoardingRejectLoading(isLoading);
    // api call from store....
    const docotorList = useSelector((state: AppState) => state.doctorRejectValue);
    let docList = docotorList.doctorRejectValue;
    let docListConfig: any = [];
    for (var i = 0; i < docList.length; i++) {
        let doctorId: number = docList[i].id;
        let doctorName = docList[i].fullName;
        let doctorQualification = (docList[i].qualification === null) ? '' : docList[i].qualification;
        let doctorSpecialty = (docList[i].specialty === null) ? '' : docList[i].specialty;
        let doctorExperience = (docList[i].yearsOfExperience === null) ? '' : docList[i].yearsOfExperience;
        docListConfig.push([
            doctorName,
            doctorQualification,
            doctorSpecialty,
            doctorExperience,
        ]);
    }
    const docListValue: any[] = docListConfig;

    useEffect(() => {
        dispatch(getDoctorOnboardingRejectList());
    }, [dispatch]);

    const DoctorListTable = (
        <Card>
            <Card.Section>
                {docListValue && docListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Doctor Name',
                            'Qualification',
                            'Speciality',
                            'Year of Experience',
                        ]}
                        rows={docListValue}
                    />
                ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Doctor list is available!',
                                },
                            ]}
                        />)}
            </Card.Section>
        </Card>
    );
    return (
        <div>
            {(docotorList.loading === true) ? docOndoardingRejectLoading(true) :
                <Page title="Doctor's On-Boarding Reject List" fullWidth>
                    {DoctorListTable}
                </Page>
            }
        </div>
    )

}