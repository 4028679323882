import React, { useState, useEffect } from 'react';
import {
    Loading, Card, Page, Stack, DataTable, ExceptionList, Layout, Button, SkeletonPage, TextContainer, FormLayout, SkeletonDisplayText, SkeletonBodyText
} from '@shopify/polaris';
import { makeStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Redirect, useHistory } from 'react-router-dom';
import { red } from '@material-ui/core/colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Pagination from '@material-ui/lab/Pagination';
import { getPatientListValue } from '../../redux/effects/ApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/store/Store';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import Typography from '@material-ui/core/Typography';
import { Box } from '@primer/components';
import AdminLoader from './AdminLoader';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#BBE5B3',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: "300px"
        },
        details: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            color: '#F5FAFA',
            backgroundColor: "#2f3032",
        },
        content: {
            flex: '1 0 auto',
            color: '#F5FAFA',
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        statLabel: {
            fontSize: 12,
            fontWeight: 500,
            margin: 0,
        },
        statValue: {
            fontSize: 20,
            fontWeight: 'bold',
            marginBottom: 4,
            letterSpacing: '1px',
        },
    })
);

export default function PatientListAdmin() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    /*  create a piece of state (toSignin)
    with a method to update that state (setToHome)
    and give it a default value of false */
    const [toSignin, setToSignin] = useState(false);
    const loadingMarkup = <AdminLoader />;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };

    const [page, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log("PAGECOUNT==>", value);
        setPage(value);
        dispatch(getPatientListValue(value));
    };

    function gotoDetails(patientId: number) {
        history.push(`/patientcaselist/${patientId}`);
    }

    useEffect(() => {
        dispatch(getPatientListValue(page));
    }, [dispatch]);

    const truncate = (str: string,) => {
        return str.length > 20 ? str.substring(0, 25) + "..." : str;
    }


    // call Store
    const AdminPatientList = useSelector((state: AppState) => state.patientValue);
    const pageCount = useSelector((state: AppState) => state.patientValue.patientPageCount);
    const allcaseValues = AdminPatientList.patientValue;
    console.log("PatientpageCount===", allcaseValues);
    console.log(AdminPatientList.loading);

    let allCaseListConfig: any = [];
    for (var i = 0; i < allcaseValues.length; i++) {
        let taskSeqNo: number = i;
        let patientId: number = allcaseValues[i].id;
        let firstname = (allcaseValues[i].firstname === "") || (allcaseValues[i].firstname === null) ? ' ' : allcaseValues[i].firstname;
        let lastname = (allcaseValues[i].lastname === "") || (allcaseValues[i].lastname === null) ? ' ' : allcaseValues[i].lastname;
        let dob = (allcaseValues[i].dob === "") || (allcaseValues[i].dob === null) ? ' ' : (new Date(allcaseValues[i].dob)).toLocaleDateString('en-US', DATE_OPTIONS);
        let patientRelationshipWithOwner = (allcaseValues[i].patientRelationshipWithOwner?.displayValue);
        let gender = (allcaseValues[i].gender?.label);
        let bloodGroup = (allcaseValues[i].bloodGroup?.label);
        let description = (allcaseValues[i].description === "") || (allcaseValues[i].description === null) ? ' ' : truncate(allcaseValues[i].description);

        allCaseListConfig.push([
            <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(patientId)}>View</Button>,
            patientId,
            firstname,
            lastname,
            dob,
            patientRelationshipWithOwner,
            gender,
            bloodGroup,
            description
        ]);
    }
    const allCaseListValue: any[] = allCaseListConfig;
    console.log("AllCaseListArray", allCaseListValue);

    const taskTable = allCaseListValue ? (
        <Card>
            <Card.Header >
                <ThemeProvider theme={theme}>
                    <Stack>
                        <Typography>Page: {page}</Typography>
                        <Pagination color="primary" count={Number(pageCount)} page={page} onChange={handleChange} />
                    </Stack>
                </ThemeProvider>
            </Card.Header>
            <Card.Section>
                {allCaseListValue && allCaseListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View',
                            'Patient Id',
                            'First Name',
                            'Last Name',
                            'DOB',
                            'Relationship',
                            'Gender',
                            'BloodGroup',
                            'Description'
                        ]}
                        rows={allCaseListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Patient Details Is Available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    ) : (
        <Layout.Section>
            {/* {emptyPageMarkup} */}
        </Layout.Section>
    );

    const loaderskeleton = (
        <Page fullWidth title="Patient List">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>
                        
                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (
        // <div>
        //     {toSignin ? <Redirect to="/signin" /> : null}
        //     <Page fullWidth title="Patient List">
        //         {AdminPatientList.loading ? loadingMarkup : ''}
        //         {taskTable}
        //     </Page>
        // </div>

        <div>
            {AdminPatientList.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth title="Patient List">
                {
                    AdminPatientList.loading ? "" :
                        <ThemeProvider theme={theme}>
                            {taskTable}
                        </ThemeProvider>
                }
            </Page>}
        </div>
    );
}

