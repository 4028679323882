import React, { useState, useEffect } from 'react';
import { Page, Loading, Card, DataTable, ExceptionList, Modal, Button, Heading, 
    SkeletonPage, TextContainer, FormLayout, SkeletonDisplayText, SkeletonBodyText, 
    Stack } from '@shopify/polaris';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientQueryList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { Box } from '@primer/components';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AdminLoader from './AdminLoader';
import { useMediaQuery } from '@material-ui/core';


export default function Patient() {
    const loadingMarkup = <AdminLoader />;
    const dispatch = useDispatch();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    const [descriptionTextShow, setDescriptionTextShow] = useState(false);
    const closeModal = () => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
    }

    const [descriptionModalActive, setDescriptionModalActive] = useState(false);
    const viewDescription = (selectedResponse: any, descriptionText: any) => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setDescriptionTextShow(descriptionText);
        console.log(selectedResponse);
    }

    const truncate = (str: string,) => {
        return str.length > 25 ? str.substring(0, 25) + "..." : str;
    }

    // call Store
    const AdminPatientQueryList = useSelector((state: AppState) => state.adminPatientQueryListValue);
    const adminPatientQueryValues = AdminPatientQueryList.adminPatientQueryListValue;

    // Get Patient Query List
    let adminPatientQueryConfig: any = [];
    for (var i = 0; i < adminPatientQueryValues.length; i++) {
        let selectedResponse = adminPatientQueryValues[i].id;
        let phoneNumber = (adminPatientQueryValues[i].callbackNumbers === null) ? "" : adminPatientQueryValues[i].callbackNumbers[0].number;
        let descriptionText = (adminPatientQueryValues[i].description == null) ? "" : adminPatientQueryValues[i].description;
        let tableDescriptionText = (adminPatientQueryValues[i].description == null) ? "" : truncate(adminPatientQueryValues[i].description);
        adminPatientQueryConfig.push([
            phoneNumber,
            tableDescriptionText,
            <Button icon={ViewMinor} primary={false} onClick={() => viewDescription(selectedResponse, descriptionText)}>View</Button>
        ]);
    }
    const patientQueries: any[] = adminPatientQueryConfig;

    const reloadPatientQueryList = () => {
        dispatch(getPatientQueryList());
    }

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(getPatientQueryList());
    }, []);



    // Query List table
    const actualPageMarkup = (
        <Card>
            <Card.Header>
                <Button primary={false} onClick={reloadPatientQueryList}>Reload</Button>
            </Card.Header>
            <Card.Section>
                {patientQueries && patientQueries.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Phone Number',
                            'Description',
                            'View'
                        ]}
                        rows={patientQueries}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Queries is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    const loaderskeleton = (
        <Page fullWidth title="Patient Queries List">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    // Query Description Modal
    const descriptionModalBody = (
        <Modal
            large
            open={descriptionModalActive}
            onClose={closeModal}
            title={"Description"}
        >
            <Modal.Section>
                <Heading>
                    {descriptionTextShow}
                </Heading>
            </Modal.Section>

        </Modal>
    );

    return (
        // <Page title="Patient Queries List" fullWidth>
        //     {AdminPatientQueryList.loading ? loadingMarkup : ""}
        //     {actualPageMarkup}
        //     {descriptionModalBody}
        // </Page>

        <div>
            {AdminPatientQueryList.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth title="Patient Queries List">
                {
                    AdminPatientQueryList.loading ? "" :
                        <ThemeProvider theme={theme}>
                            {actualPageMarkup}
                        </ThemeProvider>
                }
            </Page>}
            {descriptionModalBody}
        </div>
    )
}
