import * as React from 'react';
import {
    Page,
    Button,
    Card,
    ExceptionList,
    DataTable,
} from '@shopify/polaris';
import { NoteMinor, ImportMinor, RefreshMinor, SoundMajor, SearchMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Url from '../../ApiConfig';

export default function ImportFromPhone(props: any) {
    const { importFromPhoneLoader } = props;

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    let token = localStorage.getItem("token");

    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `token ${token}`
    }

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    const gotoRecordingUrl = (url: any) => {
        if (url === "" || url === null) {
            alert("No Url Found");
        }
        else {
            window.open(url);
        }
    }

    const findUser = (fromNumber: any) => {
        importFromPhoneLoader(true);
        axios.post(Url.baseUrl + '/ehrapp/api/incomeCall/find_user_by_phone_number', { 'phone': fromNumber }, { headers })
            .then((res) => {
                importFromPhoneLoader(false);
                let resData = res.data;
                console.log("FINDPH==>", resData);
                let ID = res.data.hasOwnProperty('owner') ? res.data.owner.id : "Id not found";
                alert("ID=" + ID + " " + "User Type==" + resData.userType);
            })
            .catch((error) => {
                importFromPhoneLoader(false);
            })
    }

    const [incomingCallListValue, setincomingListValue] = useState<any[]>([]);

    const importDataFromPhone = () => {
        getImportedList();
    }

    const importRecentDataFromPhone = () => {
        importFromPhoneLoader(true);
        axios.get(Url.baseUrl + '/ehrapp/api/incomeCall/import_recent_calls', { headers })
            .then((res) => {
                importFromPhoneLoader(false);
                console.log("RESPONSERECENT==>", res.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function getImportedList() {
        importFromPhoneLoader(true);
        axios.get(Url.baseUrl + '/ehrapp/api/incomeCall/get_all_incoming_calls', { headers })
            .then((res) => {
                importFromPhoneLoader(false);
                let callList = res.data.callList;
                let allincomingListConfig: any = [];
                for (var i = 0; i < callList.length; i++) {
                    let Id: number = callList[i].id;
                    let create_date = (callList[i].createDate === "") || (callList[i].createDate === null) ? ' ' : (new Date(callList[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let fromNumber = (callList[i].fromNumber === "") || (callList[i].fromNumber === null) ? ' ' : callList[i].fromNumber;
                    let toNumber = (callList[i].toNumber === "") || (callList[i].toNumber === null) ? ' ' : callList[i].toNumber;
                    let sidNumber = (callList[i].sidNumber === "") || (callList[i].sidNumber === null) ? ' ' : callList[i].sidNumber;
                    let phoneNumber = (callList[i].phoneNumber === "") || (callList[i].phoneNumber === null) ? ' ' : callList[i].phoneNumber;
                    let recordingUrl = (callList[i].recordingUrl === "") || (callList[i].recordingUrl === null) ? '' : callList[i].recordingUrl;
                    let exoTelCreateDate = (callList[i].exoTelCreateDate === "") || (callList[i].exoTelCreateDate === null) ? ' ' : (new Date(callList[i].exoTelCreateDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    allincomingListConfig.push([
                        Id,
                        create_date,
                        exoTelCreateDate,
                        phoneNumber,
                        fromNumber,
                        toNumber,
                        <Button icon={SoundMajor} primary={false} onClick={() => gotoRecordingUrl(recordingUrl)}>Listen</Button>,
                        <Button icon={SearchMinor} primary={false} onClick={() => findUser(fromNumber)}>Find</Button>,
                        sidNumber,
                    ]);
                }
                setincomingListValue(allincomingListConfig);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const bodyMarkup = (
        <Card>
            <Card.Header>
                <Button icon={ImportMinor} primary={false} onClick={importRecentDataFromPhone}>Import Recent</Button>
                <Button icon={RefreshMinor} primary={true} onClick={importDataFromPhone}>Reload Data</Button>
                {/* <Typography>Page: {page}</Typography>
                <Pagination color="primary" count={Number(pageCount)} page={page} onChange={handleChange} /> */}
            </Card.Header>
            <Card.Section>
                {incomingCallListValue && incomingCallListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'ID',
                            'Create Date',
                            'Exotel',
                            'Phone Number',
                            'From',
                            'To',
                            'Recording Url',
                            'Find User',
                            'Sid',
                        ]}
                        rows={incomingCallListValue}
                    />
                ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Imported Data are available!',
                                },
                            ]}
                        />)}
            </Card.Section>
        </Card>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        getImportedList();
    }, []);

    return (
        <Page fullWidth title="From Phone">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {bodyMarkup}
            </ThemeProvider>

        </Page>
    );
}

