import React, { useEffect } from 'react';
import {
    Page,
    Card,
    Button,
    DataTable,
    ExceptionList,
    SkeletonPage,
    TextContainer,
    FormLayout,
    SkeletonDisplayText,
    SkeletonBodyText,
    Stack,
} from '@shopify/polaris';
import { getDoctorListValueAdmin } from '../../redux/effects/ApiCall';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/store/Store';
import { useHistory } from 'react-router-dom';
import { ViewMinor, NoteMinor } from '@shopify/polaris-icons';
import AdminLoader from './AdminLoader';
import { Box } from '@primer/components';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function Doctor() {
    const dispatch = useDispatch();
    const history = useHistory();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    useEffect(() => {
        dispatch(getDoctorListValueAdmin());
    }, [dispatch]);

    const loadingMarkup = <AdminLoader />;


    const doctorDetails = (doctorId: any) => {
        history.push(`/doctordetails/${doctorId}`);
    };
    const viewDoctorAppointment = (doctorId: any) => {
        history.push(`/doctorapoointment/${doctorId}`)
    }
    // Api call from store.....
    const doctorList = useSelector((state: AppState) => state.doctorListValue);
    let docList = doctorList.doctorListValue;
    let docListConfig: any = [];
    for (var i = 0; i < docList.length; i++) {
        let doctorId: number = docList[i].id;
        let doctorName = (docList[i].fullName === "") || (docList[i].fullName === null) ? ' ' : docList[i].fullName;
        let doctorQualification = (docList[i].qualification === "") || (docList[i].qualification === null) ? ' ' : docList[i].qualification;
        let doctorExperience = (docList[i].specialtyWithYearsOfExperience === "") || (docList[i].specialtyWithYearsOfExperience === null) ? ' ' : docList[i].specialtyWithYearsOfExperience;

        docListConfig.push([
            <Button icon={ViewMinor} onClick={() => doctorDetails(doctorId)}>View</Button>,
            doctorName,
            <Button onClick={() => viewDoctorAppointment(doctorId)} >View Appointments</Button>,
            doctorQualification,
            doctorExperience,
        ]);
    }
    const docListValue: any[] = docListConfig;
    const DoctorListTable = (
        <Card>

            <Card.Section>
                {docListValue && docListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Doctor Details',
                            'Doctor Name',
                            'Appoinment Details',
                            'Qualification',
                            'Experience',
                        ]}
                        rows={docListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Doctor list is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    const loaderskeleton = (
        <Page fullWidth title="Doctor List">
            <Card >
                <SkeletonPage >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (
        // <div>
        //     {doctorList.loading ? loadingMarkup : ''}
        //     <Page title="Doctor List" fullWidth>
        //         {DoctorListTable}
        //     </Page>
        // </div>

        <div>
            {doctorList.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth title="Doctor List">
                {
                    doctorList.loading ? "" :
                        <ThemeProvider theme={theme}>
                            {DoctorListTable}
                        </ThemeProvider>
                }
            </Page>}
        </div>

    )
}
