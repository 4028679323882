import React, { useEffect, useState } from 'react';
import { Page, Card, DataTable, ExceptionList, Loading, Button } from '@shopify/polaris';
import { NoteMinor, PaymentsMajor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../../../ApiConfig';

export default function ActivePatientList(id: any) {
    let packageId = id.packageId;

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [corporateActivePackageListValue, setCorporateActivePackageListValue] = useState<any[]>([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    function paymentLink(paymentLink: any) {
        window.open(paymentLink);
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const getCorporateActivePackagePatient = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/paymentapp/api/corporatePackageForAdmin/corporatePackagePaymentList',
            { "packageId": Number(packageId) }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.paymentList;
                let corporateActivePackageConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let id = (resData[i].id === null) ? "" : resData[i].id;
                    let create_date = (resData[i].create_date === null) ? "" : (new Date(resData[i].create_date)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let amount = (resData[i].amount === null) ? "" : resData[i].amount;
                    let note = (resData[i].note === null) ? "" : resData[i].note;
                    let paymentLink = (resData[i].paymentLink === null) ? "" : resData[i].paymentLink;
                    let status = (resData[i].status === null) ? "" : resData[i].status;
                    corporateActivePackageConfig.push([
                        <Button icon={PaymentsMajor} size="slim" disabled={(status === "paid" || status === "free") ? true : false} onClick={() => paymentLink(paymentLink)}>
                            Pay Now
                        </Button>,
                        amount,
                        note,
                        status,
                        create_date,
                    ]);
                }
                setCorporateActivePackageListValue(corporateActivePackageConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    useEffect(() => {
        getCorporateActivePackagePatient();
    }, []);
    const actualPageMarkup = (
        <Card>
            <Card.Header title="Patient Payment Details">
            </Card.Header>
            <Card.Section>
                {corporateActivePackageListValue && corporateActivePackageListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            "Payment Link",
                            "Amount",
                            "Note",
                            "Status",
                            "Create Date",
                        ]}
                        rows={corporateActivePackageListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Patient Payment Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )
    return (
        <Page title="" fullWidth>
            {loadingMarkup}
            {actualPageMarkup}
        </Page>
    )

}