import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Thumbnail,
    Loading,
    Caption,
    Page,
    DataTable,
    Modal,
    Button,
    TextContainer,
    Subheading,
    ExceptionList
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom';
import baseUrl from '../../../../ApiConfig';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#006FBB',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        text: {
            primary: "#FFFFF",
            secondary: "#006FBB"
        }
    },
    typography: {
        fontSize: 18,
    }
});


export default function Diagnosis(props: any) {
    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const [toSignin, setToSignin] = useState(false);

    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [patientDetailsId, setPatientDetailsId] = useState('');


    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [diagnosisDescription, setDiagnosisDescription] = useState('');
    const handleDiagnosisDescriptionChange = useCallback((newValue) => {
        setDiagnosisDescription(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };

    const closeDiagnosisError = () => {
        setDiagnosisError('')
    }
    const [diagnosisError, setDiagnosisError] = useState('');
    const errorDiv = diagnosisError
        ? <Banner
            onDismiss={closeDiagnosisError}
            status="critical">
            <p>{diagnosisError}</p>
        </Banner>
        : '';

    const closeDiagnosisSuccess = () => {
        setDiagnosisSuccess('');
    }
    const [diagnosisSuccess, setDiagnosisSuccess] = useState('');
    const successDiv = diagnosisSuccess
        ? <Banner
            onDismiss={closeDiagnosisSuccess}
            status="success">
            <p>{diagnosisSuccess}</p>
        </Banner>
        : '';

    const [diagnosisDescriptionError, setDiagnosisDescriptionError] = useState('');
    const diagnosisDescriptionErrordiv = diagnosisDescriptionError
        ? <TextStyle variation="negative">{diagnosisDescriptionError}</TextStyle>
        : '';

    const uploadbody = {
        'typeName': 'patient-image'
    };

    const [modalActive, setModalActive] = useState(false);

    const [uploadmodalActive, setuploadmodalActive] = useState(false);
    const uploadhandleToggle = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setImageInfo('');
        setDiagnosisDescriptionError('');
        setDiagnosisDescription('');
        setDiagnosisSuccess('');
        setDiagnosisError('');
    }

    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteDiagnosisModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    // Delete popup open ........   
    function deleteDiagnosis(response: any) {
        setPatientDetailsId(response);
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteDiagnosisSuccess('');
    }

    const closeDeleteDiagnosisModalSucessError = () => {
        setDeleteDiagnosisSuccess('');
    }

    const [deleteDiagnosisSuccess, setDeleteDiagnosisSuccess] = useState('');
    const successDeleteProblemModalDiv = deleteDiagnosisSuccess
        ? <Banner
            onDismiss={closeDeleteDiagnosisModalSucessError}
            status="success">
            <p>{deleteDiagnosisSuccess}</p>
        </Banner>
        : '';

    const ideasBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "diagnosis",
        "description": diagnosisDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [diagnosisListValue, setDiagnosisListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 15 ? str.substring(0, 20) + "..." : str;
    }

    const getDiagnosisBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "diagnosis"
    }

    const [questionListValue, setQuestionListValue] = useState<any[]>([]);
    // Get questionnair list
    const getQuestionnaitList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientQuestionnaireList',
            getDiagnosisBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resultData = res.data.patientQuestionnaireList;

                console.log("SymptomList=>", resultData);
                console.log("question ", resultData[0].questionnaireType.displayValue);
                let ideasConfig = [];
                for (var i = 0; i < resultData.length; i++) {
                    let questionName = resultData[i].questionnaireType.displayValue;
                    let supportingDocuments = resultData[i].templateUrl;
                    ideasConfig.push([
                        questionName,
                        <Button onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}>View Question</Button>,
                    ]);
                }
                setQuestionListValue(ideasConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    // get document list
    const getIdeasConcernsList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getDiagnosisBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                console.log("Symptom List=======>", resData);
                let ideasConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponseID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    console.log("Award Name " + description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;

                    ideasConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        dateEnd,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteDiagnosis(selectedResponseID)}></Button>
                    ]);
                    console.log("Symptoms List ====> ", ideasConfig);
                }
                setDiagnosisListValue(ideasConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                setDiagnosisError(error.response.data.message);
            })
    }

    const addDiagnosis = () => {
        if (diagnosisDescription === '') {
            setDiagnosisDescriptionError("Please add description");
        }
        else {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                ideasBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
                        setDiagnosisError('');
                        setDiagnosisSuccess(result.data.message);
                        uploadhandleToggle();
                        getIdeasConcernsList();
                    }
                })
                .catch((error) => {
                    setDiagnosisSuccess('');
                    setDiagnosisError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }

    // Delete body....
    const deleteIdentifyDataBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    //delete call here ....
    const DeleteDiagnosisSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteIdentifyDataBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteDiagnosisSuccess(res.data.message);
                getIdeasConcernsList();
                handleDeleteDiagnosisModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    useEffect(() => {
        getQuestionnaitList();
        getIdeasConcernsList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const IdeasConcernsExpectationsAddInfo = (
        <div>
            <Card >
                <Card.Header title="Documents">
                    <Button primary={true} onClick={uploadhandleToggle}>Upload Data</Button>
                </Card.Header>
                <Card.Section>
                    {diagnosisListValue && diagnosisListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Document',
                                'Description',
                                'Start Date',
                                'End Date',
                                'Delete'
                            ]}
                            rows={diagnosisListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Documents is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    const questionBody = (
        <div>
            <Card>
                <Card.Header title="Questionnaire">
                    {/* <Button primary={true} onClick={handleToggle}>View</Button> */}
                </Card.Header>
                <Card.Section>
                    {questionListValue && questionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Question Name',
                                'View'
                            ]}
                            rows={questionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Question is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const deleteIdeaConcernComponent = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteDiagnosisModalChange}
            title={"Delete Diagnosis Details"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteDiagnosisSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteDiagnosisModalChange,
                },
            ]}
            footer={successDeleteProblemModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Diagnosis Details?
                </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const addIdeasDetails = (
        <Modal
            large
            open={modalActive}
            onClose={handleToggle}
            title={"Add Diagnosis Details"}
        >
            <Modal.Section>
                <Page>
                    <Box style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: "25px",
                        height: "0"
                    }}>
                        <iframe style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            border: "0px"
                        }} title="Diagnosis Document" src="https://virtualsathi-technicise-ehr.herokuapp.com/survey/15"></iframe>
                    </Box>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const uploadIdeasDetails = (
        <Modal
            large
            open={uploadmodalActive}
            onClose={uploadhandleToggle}
            title={"Upload Data of Diagnosis Details"}
            primaryAction={{
                content: "Submit",
                onAction: addDiagnosis,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <TextField
                                        label="Diagnosis Description"
                                        value={diagnosisDescription}
                                        onChange={handleDiagnosisDescriptionChange}
                                        placeholder="Please describe diagnosis description."
                                        multiline={4}
                                        id="diagnosisDescription"
                                    />
                                    <InlineError message={diagnosisDescriptionErrordiv} fieldID="diagnosisDescription" />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #006FBB solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                            console.log(imageId);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successDiv}</div>
                        <div>{errorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    return (
        <Page title="Diagnosis" fullWidth >
            {toSignin ? <Redirect to="/signin" /> : null}
            {loadingMarkup}
            {questionBody}
            {IdeasConcernsExpectationsAddInfo}
            {addIdeasDetails}
            {deleteIdeaConcernComponent}
            {docViewerComponent}
            {uploadIdeasDetails}
        </Page>
    );
}