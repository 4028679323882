import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import {
    Card,
    Layout,
    Page,
    Stack,
    TextStyle,
    Toast,
} from '@shopify/polaris';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PaymentIcon from '@material-ui/icons/Payment';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import CallIcon from '@material-ui/icons/Call';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { Box, Flex } from '@primer/components';
import { createMuiTheme, ThemeProvider, StepButton } from '@material-ui/core';
import AppointmentSelectPatient from './AppointmentSelectPatient';
import AppointmentSelectDoctor from './AppointmentSelectDoctor';
import AppointmentBooking from './AppointmentBooking';
import AppointmentPayment from './AppointmentPayment';
import AddhealthRecord from './FilePreparation/AddHealthRecord';
import ConsultationOption from './ConsultationOption';
import AddPatientTeam from './AddPatientTeam';
import DoctorPatientInteraction from './DoctorPatientInteraction';
import FinalOpinion from './FinalOpinion';
import axios from 'axios';
import baseUrl from '../../../ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { getPatientCaseList } from '../../../redux/effects/ApiCall';
import { CaseList } from '../../../redux/interfaces/Model';
import * as _ from 'lodash';
import AdminLoader from '../AdminLoader';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(65, 166, 214) 0%,rgb(54, 192, 214) 50%,rgb(187, 229, 179) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(71 151 232) 0%,rgb(18 126 236) 50%,rgb(11 142 226) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(16, 128, 19) 0%,rgb(49 156 197) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg,rgb(18, 149, 243) 60%,rgb(26, 225, 234) 100%)',
    },
});

const defaultMaterialTheme = createMuiTheme({
    palette: {
        text: {
            primary: "#FFFFFF",
            secondary: "#8a877f"
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#084E8A',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#B4E1FA',
        }
    },

    typography: {
        fontSize: 20,
    }
});

function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsIcon />,
        2: <GroupIcon />,
        3: <ContactPhoneIcon />,
        4: <FileCopyIcon />,
        5: <GroupAddIcon />,
        6: <PostAddIcon />,
        7: <PaymentIcon />,
        8: <EmojiPeopleIcon />,
        9: <CallIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            //backgroundColor: '#ccc',
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

export default function FilePreparation() {
    const [isLoading, setIsLoading] = useState(false);
    const userToken = localStorage.getItem("token");
    const [toSignin, setToSignin] = useState(false)
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const { patientName } = useParams() as {
        patientName: string;
    };

    const { secondOpinionReasons } = useParams() as {
        secondOpinionReasons: string;
    };
    const [caseName, setCaseName] = useState("");

    const dispatch = useDispatch();

    const [deleteCaseErrorToastActive, setDeleteCaseErrorToastActive] = useState(false);
    const [deleteCaseToastMessage, setDeleteCaseToastMessage] = useState("");
    const handleDeleteCaseToastActive = useCallback(() => setDeleteCaseErrorToastActive((deleteCaseErrorToastActive) => !deleteCaseErrorToastActive), []);
    const toastMarkup = deleteCaseErrorToastActive ? (
        <Toast content={deleteCaseToastMessage} error onDismiss={handleDeleteCaseToastActive} />
    ) : null;

    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);
    const [stepDetails, setStepDetails] = useState<any[]>([]);

    const handleNext = () => {

        if (localStorage.getItem("consultationopt") === 'Quick Video Consultation') {
            setActiveStep(3);
            localStorage.setItem("consultationopt", '');
        }
        else if (activeStep === steps.length - 1) {
            window.scrollTo(0, 0);
            history.goBack();
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    const [completed, setCompleted] = React.useState(new Set<number>());
    function isStepComplete(step: number) {
        // const newCompleted = new Set(completed);
        // newCompleted.add(activeStep);
        // setCompleted(newCompleted);
        // setCompleted(step);
        return completed.has(step);
    }

    const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
    const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, ['caseId', Number(caseid)])[0];

    const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);

    const [appointmentPatientTeamLoader, setAppointmentPatientTeamLoader] = useState(false);
    const [appointmentSelectPatientLoader, setAppointmentSelectPatientLoader] = useState(false);
    const [consultationLoader, setConsultationLoader] = useState(false);
    const [addHealthRecordLoader, setAddHealthRecordLoader] = useState(false);
    const [appointmentDoctorPatientInteractionLoader, setAppointmentDoctorPatientInteractionLoader] = useState(false);
    const [appointmentFinalOpinionLoader, setAppointmentFinalOpinionLoader] = useState(false);
    const [doctorId, setdoctorId] = useState(false);
    const [patientIdsend, setpatientIdsend] = useState(0);
    const loadingMarkup = <AdminLoader />;

    const [appointmentBookLoader, setAppointmentBookLoader] = useState(false);

    const [appointmentPaymentLoader, setAppointmentPaymentLoader] = useState(false);

    const getStepsAPI = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/patientcaseui/steps_and_sections',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                console.log(isLoading);
                setStepDetails(res.data.SecondOpinionUi);
                setSteps(res.data.SecondOpinionUi.map(function (el: { displayValue: any; }) { return el.displayValue; }));
                if (patientid) {
                    setActiveStep(1);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
    const getStepContent = (stepper: number) => {
        switch (stepDetails[stepper].name) {
            case "selectPatient":
                return (
                    <AppointmentSelectPatient patientIdsend={setpatientIdsend} appointmentSelectPatientLoader={setAppointmentSelectPatientLoader} />
                );
            case "consultationType":
                return (
                    <ConsultationOption consultationLoader={setConsultationLoader} />
                );
            case "filePreparation":
                return (
                    <AddhealthRecord addHealthRecordLoader={setAddHealthRecordLoader} />
                );
            case "selectDoctor":
                return (
                    <AppointmentSelectDoctor appointmentDoctorPageLoader={setAppointmentBookLoader} />
                );
            case "bookAppointments":
                return (
                    <AppointmentBooking appointmentBookLoader={setAppointmentBookLoader} />
                );
            case "payment":
                return (
                    <AppointmentPayment appointmentPaymentLoader={setAppointmentPaymentLoader} />
                );
            case "patientTeam":
                return (
                    <AddPatientTeam appointmentPatientTeamLoader={setAppointmentPatientTeamLoader} />
                );
            case "doctorpatientinteraction":
                return (
                    <DoctorPatientInteraction appointmentDoctorPatientInteractionLoader={setAppointmentDoctorPatientInteractionLoader} />
                );
            case "secondopinion":
                return (
                    <FinalOpinion appointmentFinalOpinionLoader={setAppointmentFinalOpinionLoader} />
                );
            default:
                return 'Unknown step';
        }
    };
    const history = useHistory();
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };
    // Delete body....
    const deletePastHistoryBody = {
        "patientCaseId": Number(caseid),
        "isActive": false
    }
    const deleteCase = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/deactivate_patient_case',
            deletePastHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                history.push(`/allcaselist`);
            })
            .catch((error) => {
                setIsLoading(false);
                setDeleteCaseToastMessage(error.response.data.message);
                setDeleteCaseErrorToastActive(true);
            })
    }

    const pagebody = (
        <Card>
            <div className={classes.root}>
                {/* <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />} style={{ backgroundColor: "transparent" }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper> */}
                <Stepper alternativeLabel nonLinear activeStep={activeStep} connector={<ColorlibConnector />} style={{ backgroundColor: "transparent" }}>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepButton onClick={handleStep(index)} completed={isStepComplete(index)}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                </StepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                <Box p={3}>
                    {activeStep === steps.length ? (
                        ''
                    ) : (
                            <Layout>
                                <Layout.Section>
                                    <TextStyle>{getStepContent(activeStep)}</TextStyle>
                                </Layout.Section>
                                <Layout.Section>
                                    <Stack>
                                        <Stack.Item fill>
                                            &nbsp;
                                    </Stack.Item>
                                        <Stack.Item>
                                            <Flex justifyContent='end'>
                                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                                    Back
                                            </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                >
                                                    {activeStep === steps.length - 1 ? 'Close' : 'Next'}
                                                </Button>
                                            </Flex>
                                        </Stack.Item>
                                    </Stack>
                                </Layout.Section>
                            </Layout>
                        )}
                </Box>
            </div>
        </Card>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
        getStepsAPI();
        dispatch(getPatientCaseList(patientid));
        console.log("ParamValue", doctorId);
    }, []);

    return (
        <div>
            {(doctorId === true || appointmentSelectPatientLoader === true || consultationLoader === true || appointmentBookLoader === true || appointmentPaymentLoader === true || appointmentPatientTeamLoader === true || addHealthRecordLoader === true || appointmentDoctorPatientInteractionLoader === true || appointmentFinalOpinionLoader === true ) ? loadingMarkup : ""}
            <Page
                fullWidth
                breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
                title={`${(caseDetails === undefined) ? `${(casehistoryname === undefined) ? "" : casehistoryname + "'s Details"}` : `${(casehistoryname === undefined) ? "" : casehistoryname + "'s Details"}`}`}
                subtitle={`${(caseDetails === undefined) ? "" : (caseDetails.whyWant2ndOpinion)}`}
                secondaryActions={[{ content: 'Delete Case', onAction: deleteCase }]}>
                {toSignin ? <Redirect to="/signin" /> : null}
                <ThemeProvider theme={defaultMaterialTheme}>
                    {pagebody}
                </ThemeProvider>
                {toastMarkup}
            </Page>
        </div>

    );
}