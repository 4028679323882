import React, { useEffect, useState, useCallback } from 'react';
import { Page, Loading, Layout, Tabs, TextStyle, Caption } from '@shopify/polaris';
import { Box } from '@primer/components';
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CardHeader, CardContent, CardMedia, Typography, Avatar, IconButton, Button, CardActions, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import Url from '../../ApiConfig';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { useDispatch, useSelector } from 'react-redux';
import { DoctorList } from '../../redux/interfaces/Model';
import { AppState } from '../../redux/store/Store';
import { render } from 'react-dom';
import DoctorsOnboardingReject from './DoctorsOnboardingReject';
import DoctorsOnboardingRequest from './DoctorsOnboardingRequest';
import DoctorsOnboardingImcomplete from './DoctorsOnboardingImcomplete';
import AdminLoader from './AdminLoader';


const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#DE3618',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#BF0711',
        },
    },
    typography: {
        fontSize: 22,
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "500px",
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            color: '#F5FAFA',
            backgroundColor: "#2f3032"
        },
        content: {
            flex: '1 0 auto',
            color: '#F5FAFA'
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: "#DE3618",
        },
    }),
);

export default function DoctorsOnboarding() {
    const loadingMarkup = <AdminLoader />;
    const classes = useStyles();
    const dispatch = useDispatch();

    const [docOndoardingRequestLoading, setDocOndoardRequestLoading] = useState(false);
    const [docOndoardingRejectLoading, setDocOndoardRejectLoading] = useState(false);
     const [docOndoardingIncompleteLoading, setDocOndoardIncompleteLoading] = useState(false);
    const [selected, setSelected] = useState(0);
    const [pageTitle, setPageTitle] = useState("");
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedOnboardingtab", String(selectedTabIndex));
    }, []);


    useEffect(() => {
        setSelected(Number(localStorage.getItem("selctedOnboardingtab")));

    });

    const tabs = [
        {
            id: 'onboarding-list',
            content: 'Onboarding Requested',
            body: (
                <DoctorsOnboardingRequest docOndoardingRequestLoading={setDocOndoardRequestLoading} />
            ),
        },
        {
            id: 'onboarding-reject',
            content: 'Onboarding Rejected',
            body: (
                <DoctorsOnboardingReject docOndoardingRejectLoading={setDocOndoardRejectLoading} />
            ),
        },
        {
            id: 'onboarding-incomplete',
            content: 'Onboarding Incomplete',
            body: (
                <DoctorsOnboardingImcomplete docOndoardingIncompleteLoading={setDocOndoardIncompleteLoading}/>
            ),
        },


    ];
    return (
        <div>
            {(docOndoardingRequestLoading === true || docOndoardingRejectLoading === true || docOndoardingIncompleteLoading) ? loadingMarkup : ""}
            <Page fullWidth>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    )

}