import React, { useState, useCallback, useEffect } from 'react';
import {
    Banner,
    Button,
    Card,
    Scrollable,
    ExceptionList,
    DataTable,
    Modal,
    TextField,
    InlineError,
    TextStyle,
    Page,
    Toast
} from '@shopify/polaris';
import { NoteMinor, AddNoteMajor, RefreshMinor, EditMinor } from '@shopify/polaris-icons';
import { Box } from '@primer/components';
import axios from 'axios';
import baseUrl from '../../../../ApiConfig';
import { useHistory, useParams } from 'react-router-dom';
import AdminLoader from '../../AdminLoader';

export default function ActiveDrNotes(props: any) {
    const { activeNoteLoader } = props;

    const [isLoading, setIsLoading] = useState(false);
    activeNoteLoader(isLoading);

    const { patientid } = useParams() as {
        patientid: string;
    };

    const { caseid } = useParams() as {
        caseid: string;
    };

    const { doctorId } = useParams() as {
        doctorId: string;
    };

    const history = useHistory();

    const [notesError, setNotesError] = useState('');
    const notesErrorDiv = notesError
        ? <TextStyle variation="negative">{notesError}</TextStyle>
        : '';

    const [notes, setNotes] = useState('');
    const handleNotesChange = useCallback((newValue) => {
        setNotes(newValue);
    }, []);

    const closeNotesSubmitSuccess = () => {
        setNotesSubmitSuccess('')
    }

    const [notesSubmitSuccess, setNotesSubmitSuccess] = useState('');
    const notesSubmitSuccessDiv = notesSubmitSuccess
        ? <Banner
            onDismiss={closeNotesSubmitSuccess}
            status="success">
            <p>{notesSubmitSuccess}</p>
        </Banner>
        : '';

    const closeNotesSubmitError = () => {
        setNotesSubmitError('')
    }

    const [notesSubmitError, setNotesSubmitError] = useState('');
    const notesSubmitErrorDiv = notesSubmitError
        ? <Banner
            onDismiss={closeNotesSubmitError}
            status="critical">
            <p>{notesSubmitError}</p>
        </Banner>
        : '';

    const [notesModalActive, setNotesModalActive] = useState(false);
    const handleNotesModalToggle = () => {
        setNotesModalActive((notesModalActive) => !notesModalActive);
        setNotesError("");
        setNotesSubmitSuccess("");
        setNotesSubmitError("");
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [noteStatusSuccess, setNoteStatusSuccess] = useState('');
    const [noteStatusSuccessActive, setNoteStatusSuccessActive] = useState(false);
    const togglenoteStatusSuccessActive = useCallback(() => setNoteStatusSuccessActive((noteStatusSuccessActive) => !noteStatusSuccessActive), []);
    const noteStatusSuccessMarkup = noteStatusSuccessActive ? (
        <Toast content={noteStatusSuccess} onDismiss={togglenoteStatusSuccessActive} />
    ) : null;

    const [noteStatusError, setNoteStatusError] = useState('');
    const [noteStatusErrorActive, setNoteStatusErrorActive] = useState(false);
    const togglenoteStatusErrorActive = useCallback(() => setNoteStatusErrorActive((noteStatusErrorActive) => !noteStatusErrorActive), []);
    const noteStatusErrorMarkup = noteStatusErrorActive ? (
        <Toast content={noteStatusError} onDismiss={togglenoteStatusErrorActive} />
    ) : null;


    // Notes Status change API
    function toggleNoteStatus(response: any) {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + `/erpapp/api/adminNote/${Number(response)}/toggle_active`,
            { headers })
            .then((res) => {
                setIsLoading(false);
                setNoteStatusError("");
                setNoteStatusSuccess(res.data.message);
                activeNoteList();
                togglenoteStatusSuccessActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setNoteStatusSuccess("");
                setNoteStatusError(error.response.data.message);
                activeNoteList();
                togglenoteStatusErrorActive();
            })
    }
    
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [noteListValue, setNoteListValue] = useState<any[]>([]);
    const activeNoteList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/erpapp/api/adminNote/get_admin_notes_by_patient', { "patientId": patientid, "caseId": caseid, "doctorId": doctorId, "isActive": true }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.noteList;
                let noteListConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i].id;
                    let note = (resData[i].note === "") ? "" : resData[i].note;
                    let createdDate = (resData[i].createdDate === null) ? null : (new Date(resData[i].createdDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let createBy = (resData[i].createdBy === null) ? "" : resData[i].createdBy.user.first_name + " " + resData[i].createdBy.user.last_name;
                    let modifiedDate = (resData[i].modifiedDate === null) ? null : (new Date(resData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let modifyBy = (resData[i].modifiedBy === null) ? "" : resData[i].modifiedBy.user.first_name + " " + resData[i].modifiedBy.user.last_name;
                    noteListConfig.push([
                        note,
                        createdDate,
                        createBy,
                        <Button icon={EditMinor} size="slim" onClick={() => toggleNoteStatus(selectedResponse)}></Button>,
                        modifiedDate,
                        modifyBy
                    ]);
                }
                setNoteListValue(noteListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    // update dr. sumaary details
    const addNotes = () => {
        if (notes === "") {
            setNotesError("Please add notes");
        }
        else {
            setIsLoading(true);
            axios.post(baseUrl.baseUrl + '/erpapp/api/adminNote/add_adminNote_for_patient',
                { "patientId": patientid, "caseId": caseid, "doctorId": doctorId, "note": notes }, { headers })
                .then(result => {
                    setIsLoading(false);
                    setNotesSubmitError("");
                    setNotesSubmitSuccess(result.data.message);
                    handleNotesModalToggle();
                    activeNoteList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setNotesSubmitSuccess("");
                    setNotesSubmitError(error.response.data.message);
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        activeNoteList();
    }, []);

    const notelistmarkup = (
        <Card>
            <Card.Header title={localStorage.getItem("DocName")}>
                <Button icon={RefreshMinor} primary={false} onClick={() => activeNoteList()}>Reload Note List</Button>
                <Button icon={AddNoteMajor} primary={true} onClick={handleNotesModalToggle}>Add Note</Button>
            </Card.Header>
            <Card.Section>
                <Scrollable horizontal={false}> {/*shadow style={{ height: '200px' }}*/}
                    {noteListValue && noteListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Note',
                                'Create Date',
                                'Created By',
                                'Change Status',
                                'Modified Date',
                                'Modified By'
                            ]}
                            rows={noteListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Notes is available!',
                                },
                            ]}
                        />)}
                </Scrollable>
            </Card.Section>
        </Card >
    )

    //Dr. Notes Modal
    const drNotesModalMarkup = (
        <div>
            <Modal
                large
                open={notesModalActive}
                onClose={handleNotesModalToggle}
                title="Add Note"
                primaryAction={{
                    content: 'Submit',
                    onAction: addNotes,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleNotesModalToggle,
                    },
                ]}
            >
                <Modal.Section>
                    <div>
                        <TextField
                            value={notes}
                            label="Patient Notes"
                            placeholder="Please provide notes"
                            id="notesID"
                            onChange={handleNotesChange}
                        />
                        <InlineError message={notesErrorDiv} fieldID="notesID" />
                    </div>
                    <Box p={1}>{notesSubmitSuccessDiv}</Box>
                    <Box p={1}>{notesSubmitErrorDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )

    return (
        <Page fullWidth>
            {notelistmarkup}
            {drNotesModalMarkup}
            {noteStatusSuccessMarkup}
            {noteStatusErrorMarkup}
        </Page>
    );
}