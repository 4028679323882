import {
  getOnboardingDoctorRequestAction,
  getOnboardingDoctorRejecttAction,
  getOnboardingDoctorIncompleteAction,
  getDoctorListAction,
  getPatientAction,
  getTaskListAction,
  getQuestionListAction,
  setVerifyAction,
  getCaseAction,
  getQuestionAction,
  getChiefComplaintAction,
  AddChiefComplaintAction,
  DeleteDocumentAction,
  getAllTaskListAction,
  getAllCaseAction,
  getAppointmentAction,
  getPatientListAction,
  getAllPaymentAction,
  getPatientQueryAction,
  getDoctorQueryAction,
  getAllInactiveTaskListAction,
  getActiveCorporateListActions,
  getInactiveCorporateListActions,
  getActiveCorporatepatientListActions,
  getInactiveCorporatepatientListActions
} from "../actions/Actions";
import { Dispatch } from "redux";
import {
  DoctorOnboardingRequestActionTypes,
  DoctorOnboardingRejectActionTypes,
  DoctorOnboardingIncompleteActionTypes,
  DoctorAdminGetActionTypes,
  PatientGetActionTypes,
  PATIENT_LIST_LOADING,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_FAIL,
  TASK_LIST_LOADING,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAIL,
  DOCTOR_REQUEST_LIST_LOADING,
  DOCTOR_REQUEST_LIST_SUCCESS,
  DOCTOR_REQUEST_LIST_FAIL,
  DOCTOR_REJECT_LIST_LOADING,
  DOCTOR_REJECT_LIST_SUCCESS,
  DOCTOR_REJECT_LIST_FAIL,
  DOCTOR_INCOMPLETE_LIST_LOADING,
  DOCTOR_INCOMPLETE_LIST_SUCCESS,
  DOCTOR_INCOMPLETE_LIST_FAIL,
  AdminGetTaskListActionTypes,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,
  AdminGetQuestionListActionTypes,
  QUESTION_LIST_LOADING,
  QUESTION_LIST_FAIL,
  QUESTION_LIST_SUCCESS,
  SetVerifyActionTypes,
  SET_VERIFY,
  CaseGetActionTypes,
  CASE_LIST_LOADING,
  CASE_LIST_SUCCESS,
  CASE_LIST_FAIL,
  QuestionGetActionTypes,
  ChiefComplaintGetActionTypes,
  CC_LIST_LOADING,
  CC_LIST_SUCCESS,
  CC_LIST_FAIL,
  AddChiefComplaintTypes,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_SUCCESS,
  CC_ADD_LIST_FAIL,
  DeleteDocumentTypes,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_SUCCESS,
  DELETE_DOCUMENT_LIST_FAIL,
  DELETE_DOCUMENT,
  ADD_CC,
  AdminGetAllTaskListActionTypes,
  ALL_TASK_LIST_LOADING,
  ALL_TASK_LIST_FAIL,
  ALL_TASK_LIST_SUCCESS,
  AllCaseGetActionTypes,
  ALL_CASE_LIST_LOADING,
  ALL_CASE_LIST_SUCCESS,
  ALL_CASE_LIST_FAIL,
  AdminAllAppointmentGetActionTypes,
  APPOINTMENT_LIST_LOADING,
  APPOINTMENT_LIST_SUCCESS,
  APPOINTMENT_LIST_FAIL,
  AdminGetPatientTeamListActionTypes,
  ALL_PATIENT_TEAM_LOADING,
  ALL_PATIENT_TEAM_SUCCESS,
  ALL_PATIENT_TEAM_FAIL,
  AdminGetAllPaymentListActionTypes,
  ALL_PAYMENT_LOADING,
  ALL_PAYMENT_SUCCESS,
  ALL_PAYMENT_FAIL,
  AdminPatientQueryActionTypes,
  PATIENT_QUERY_LOADING,
  PATIENT_QUERY_SUCCESS,
  PATIENT_QUERY_FAIL,
  DOCTOR_QUERY_LOADING,
  DOCTOR_QUERY_SUCCESS,
  DOCTOR_QUERY_FAIL,
  AdminDoctorQueryActionTypes,
  AdminGetAllInactiveTaskListActionTypes,
  ALL_INACTIVE_TASK_LIST_LOADING,
  ALL_INACTIVE_TASK_LIST_SUCCESS,
  ALL_INACTIVE_TASK_LIST_FAIL,
  ActiveCorporatePackagesTypes,
  ACTIVE_CORPORATE_LIST_LOADING,
  ACTIVE_CORPORATE_LIST_SUCCESS,
  ACTIVE_CORPORATE_LIST_FAIL,
  InactiveCorporatePackagesTypes,
  INACTIVE_CORPORATE_LIST_LOADING,
  INACTIVE_CORPORATE_LIST_SUCCESS,
  INACTIVE_CORPORATE_LIST_FAIL,
  ActiveCorporatePatientTypes,
  ACTIVE_CORPORATE_PATIENT_LIST_LOADING,
  ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS,
  ACTIVE_CORPORATE_PATIENT_LIST_FAIL,
  InactiveCorporatePatientTypes,
  INACTIVE_CORPORATE_PATIENT_LIST_LOADING,
  INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS,
  INACTIVE_CORPORATE_PATIENT_LIST_FAIL,
} from "../types/ActionTypes";
import Url from "../../ApiConfig";
import axios from "axios";

const userToken = localStorage.getItem("token");
const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  Authorization: `Token ${userToken}`,
};


export const getDoctorOnboardingListValue = () => {
  return function (dispatch: Dispatch<DoctorOnboardingRequestActionTypes>) {
    dispatch({
      type: DOCTOR_REQUEST_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl + "/adminapp/api/doctorOnboarding/requestListByType",
        { type: "requested" },
        { headers }
      )
      .then((res) => {
        let resData = res.data.doctoronboardingList;
        dispatch(getOnboardingDoctorRequestAction(resData));
        console.log("ONBOARDING DOCTOR LIST=======>", resData);
        dispatch({
          type: DOCTOR_REQUEST_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        window.alert(error);
        dispatch({
          type: DOCTOR_REQUEST_LIST_FAIL,
        });
      });
  };
};

export const getDoctorOnboardingRejectList = () => {
  return function (dispatch: Dispatch<DoctorOnboardingRejectActionTypes>) {
    dispatch({
      type: DOCTOR_REJECT_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl + "/adminapp/api/doctorOnboarding/requestListByType",
        { type: "rejected" },
        { headers }
      )
      .then((res) => {
        let resData = res.data.doctoronboardingList;
        dispatch(getOnboardingDoctorRejecttAction(resData));
        console.log("ONBOARDING DOCTOR LIST=======>", resData);
        dispatch({
          type: DOCTOR_REJECT_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        window.alert(error);
        dispatch({
          type: DOCTOR_REJECT_LIST_FAIL,
        });
      });
  };
};

export const getDoctorOnboardingIncompleteList = () => {
  return function (dispatch: Dispatch<DoctorOnboardingIncompleteActionTypes>) {
    dispatch({
      type: DOCTOR_INCOMPLETE_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl + "/adminapp/api/doctorOnboarding/requestListByType",
        { type: "incomplete" },
        { headers }
      )
      .then((res) => {
        let resData = res.data.doctoronboardingList;
        dispatch(getOnboardingDoctorIncompleteAction(resData));
        console.log("ONBOARDING INCOMPLETE DOCTOR LIST=======>", resData);
        dispatch({
          type: DOCTOR_INCOMPLETE_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        window.alert(error);
        dispatch({
          type: DOCTOR_INCOMPLETE_LIST_FAIL,
        });
      });
  };
};

export const getDoctorListValueAdmin = () => {
  return function (dispatch: Dispatch<DoctorAdminGetActionTypes>) {
    dispatch({
      type: DOCTOR_LIST_LOADING,
    });
    axios
      .get(Url.baseUrl + "/doctorapp/api/doctor/active_doctor_list", {
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          Authorization: `Token ${userToken}`,
        },
      })
      .then((res) => {
        let resData = res.data.doctorsList;
        dispatch(getDoctorListAction(resData));
        console.log("DOCTOR LIST=======>", resData);
        dispatch({
          type: DOCTOR_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: DOCTOR_LIST_FAIL,
        });
      });
  };
};

export const getPatientListValue = (pageNo: any) => {
  return function (dispatch: Dispatch<PatientGetActionTypes>) {
    dispatch({
      type: PATIENT_LIST_LOADING,
    });
    axios
      .get(
        Url.baseUrl +
          `/adminapp/api/patient/patient_list_all?size=10&page=${Number(
            pageNo
          )}`,
        { headers }
      )
      .then((res) => {
        let patientresData = res.data.patientList;
        let patientPageCount = res.data.numberOfPages;
        console.log("APIPageCount====", patientPageCount);
        dispatch(getPatientAction(patientresData, patientPageCount));
        console.log("PATIENTLIST=======>", patientresData, patientPageCount);
        dispatch({
          type: PATIENT_LIST_SUCCESS,
          payload: patientresData,
          patientPageCount: patientPageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: PATIENT_LIST_FAIL,
        });
      });
  };
};

export const getPatientCaseList = (patientid: string) => {
  return function (dispatch: Dispatch<CaseGetActionTypes>) {
    dispatch({
      type: CASE_LIST_LOADING,
    });

    axios
      .post(
        Url.baseUrl + "/adminapp/api/adminActions/patient_case_list",
        { patientId: patientid },
        { headers }
      )
      .then((res) => {
        let resData = res.data.patientCaseList;
        let patientName = res.data;
        dispatch(getCaseAction(resData, patientName));
        console.log("ADMINCASELIST=======>", patientName);
        dispatch({
          type: CASE_LIST_SUCCESS,
          payload: resData,
          patientname: patientName,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: CASE_LIST_FAIL,
        });
      });
  };
};

export const getAllPatientCaseList = (pageNo: any, isactive: any) => {
  return function (dispatch: Dispatch<AllCaseGetActionTypes>) {
    dispatch({
      type: ALL_CASE_LIST_LOADING,
    });

    axios.post(Url.baseUrl +`/adminapp/api/patientcase/patient_case_list_all?page=${Number(pageNo)}&size=10`,{"isActive":isactive},{ headers })
      .then((res) => {
        let resData = res.data.patientCaseList;
        let pageCount = res.data.numberOfPages;
        dispatch(getAllCaseAction(resData, pageCount));
        dispatch({
          type: ALL_CASE_LIST_SUCCESS,
          payload: resData,
          pageCount: pageCount,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: ALL_CASE_LIST_FAIL,
        });
      });
  };
};

export const getTaskListValue = () => {
  return function (dispatch: Dispatch<AdminGetTaskListActionTypes>) {
    dispatch({
      type: TASK_LIST_LOADING,
    });
    axios
      .get(Url.baseUrl + "/adminapp/api/adminActions/admin_self_task_list", {
        headers,
      })
      .then((res) => {
        let taskData = res.data.taskDataList;
        dispatch(getTaskListAction(taskData));
        dispatch({
          type: TASK_LIST_SUCCESS,
          payload: taskData,
        });
      })
      .catch((error) => {
        dispatch({
          type: TASK_LIST_FAIL,
        });
      });
  };
};
export const getAllTaskListValue = (pageNo: any) => {
  return function (dispatch: Dispatch<AdminGetAllTaskListActionTypes>) {
    dispatch({
      type: ALL_TASK_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/adminTasks/admin_task_list_by_status?page=${Number(
            pageNo
          )}&size=10`,
        { taskStatus: 1 },
        { headers }
      )
      .then((res) => {
        let allTaskData = res.data.taskDataList;
        console.log("APITaskList===", allTaskData);
        let count = res.data.current_page_no;
        let pageCount = res.data.numberOfPages;
        console.log("APIPAGECOUNT====", pageCount);
        dispatch(getAllTaskListAction(allTaskData, count, pageCount));
        dispatch({
          type: ALL_TASK_LIST_SUCCESS,
          payload: allTaskData,
          count: count,
          pageCount: pageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: ALL_TASK_LIST_FAIL,
        });
      });
  };
};

// Inactive task list
export const getAllInactiveTaskListValue = (pageNo: any) => {
  return function (dispatch: Dispatch<AdminGetAllInactiveTaskListActionTypes>) {
    dispatch({
      type: ALL_INACTIVE_TASK_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/adminapp/api/adminTasks/admin_task_list_by_status?page=${Number(
            pageNo
          )}&size=10`,
        { taskStatus: 0 },
        { headers }
      )
      .then((res) => {
        let allTaskData = res.data.taskDataList;
        console.log("APITaskList===", allTaskData);
        let count = res.data.current_page_no;
        let pageCount = res.data.numberOfPages;
        console.log("APIPAGECOUNT====", pageCount);
        dispatch(getAllInactiveTaskListAction(allTaskData, count, pageCount));
        dispatch({
          type: ALL_INACTIVE_TASK_LIST_SUCCESS,
          payload: allTaskData,
          count: count,
          pageCount: pageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: ALL_INACTIVE_TASK_LIST_FAIL,
        });
      });
  };
};

export const getQuestionListValue = () => {
  return function (dispatch: Dispatch<AdminGetQuestionListActionTypes>) {
    dispatch({
      type: QUESTION_LIST_LOADING,
    });
    axios
      .get(
        Url.baseUrl +
          "/questionandanswerapp/api/question/list_Question_for_admin",
        { headers }
      )
      .then((res) => {
        let questionData = res.data.questionList;
        dispatch(getQuestionListAction(questionData));
        dispatch({
          type: QUESTION_LIST_SUCCESS,
          payload: questionData,
        });
      })
      .catch((error) => {
        dispatch({
          type: QUESTION_LIST_FAIL,
        });
      });
  };
};

export const setVerifyValue = (state = {}) => {
  return function (dispatch: Dispatch<SetVerifyActionTypes>) {
    // dispatch(setPostsAction(SET_POSTS));
    console.log("VALUEINPOST==>", state);
    dispatch(setVerifyAction(SET_VERIFY));
    axios
      .post(
        Url.baseUrl + "/questionandanswerapp/api/question/verify_Question",
        state,
        { headers }
      )
      .then((result) => {
        if (result) {
          console.log("CCADDED==>", result);
          // get question list call............
          dispatch({
            type: QUESTION_LIST_LOADING,
          });
          axios
            .get(
              Url.baseUrl +
                "/questionandanswerapp/api/question/list_Question_for_admin",
              { headers }
            )
            .then((res) => {
              let questionData = res.data.questionList;
              dispatch(getQuestionListAction(questionData));
              dispatch({
                type: QUESTION_LIST_SUCCESS,
                payload: questionData,
              });
            })
            .catch((error) => {
              dispatch({
                type: QUESTION_LIST_FAIL,
              });
            });
        }
      })
      .catch((error) => {});
  };
};

export const getQuestionList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<QuestionGetActionTypes>) {
    dispatch({
      type: QUESTION_LIST_LOADING,
    });

    axios
      .post(
        Url.baseUrl +
          "/patientapp/api/patientMedicalData/get_patientQuestionnaireList",
        getChiefComplaintBody,
        { headers }
      )
      .then((res) => {
        let resData = res.data.patientQuestionnaireList;
        dispatch(getQuestionAction(resData));
        console.log("QUESTIONLIST=======>", resData);
        dispatch({
          type: QUESTION_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: QUESTION_LIST_FAIL,
        });
      });
  };
};

export const getChiefComplaintList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<ChiefComplaintGetActionTypes>) {

    dispatch({
      type: CC_LIST_LOADING,
    });

    axios
      .post(
        Url.baseUrl +
          "/patientapp/api/patientMedicalData/get_patientDetailsList",
        getChiefComplaintBody,
        { headers }
      )
      .then((res) => {
        let resData = res.data.patientDetailList;
        dispatch(getChiefComplaintAction(resData));
        console.log("CCLIST=======>", resData);
        dispatch({
          type: CC_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        console.log("error record====", error);
        dispatch({
          type: CC_LIST_FAIL,
        });
      });
  };
};

export const addChiefComplaint = (state = {}) => {
  return function (dispatch: Dispatch<AddChiefComplaintTypes>) {
    dispatch({
      type: CC_ADD_LIST_LOADING,
    });
    dispatch(AddChiefComplaintAction(ADD_CC));
    axios
      .post(
        Url.baseUrl + "/patientapp/api/patientMedicalData/add_patientDetails",
        state,
        { headers }
      )
      .then((result) => {
        if (result) {
          console.log("CCADDED==>", result);
          dispatch({
            type: CC_ADD_LIST_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: CC_ADD_LIST_FAIL,
        });
      });
  };
};

export const deleteFilePreparationDocument = (state = {}) => {
  return function (dispatch: Dispatch<DeleteDocumentTypes>) {
    dispatch({
      type: DELETE_DOCUMENT_LIST_LOADING,
    });
    dispatch(DeleteDocumentAction(DELETE_DOCUMENT));
    axios
      .post(
        Url.baseUrl +
          "/patientapp/api/patientMedicalData/delete_patientDetails",
        state,
        { headers }
      )
      .then((result) => {
        if (result) {
          console.log("CCADDED==>", result);
          dispatch({
            type: DELETE_DOCUMENT_LIST_SUCCESS,
            payload: result.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DOCUMENT_LIST_FAIL,
        });
      });
  };
};

// Admin All Appointment List API
export const getAdminAllAppointmentList = (
  appointmentBody: object,
  pageNo: any
) => {
  return function (dispatch: Dispatch<AdminAllAppointmentGetActionTypes>) {
    dispatch({
      type: APPOINTMENT_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/appointmentapp/api/appointmentList/get_appointment_list_for_admin?size=10&page=${pageNo}`,
        appointmentBody,
        { headers }
      )
      .then((res) => {
        let appointmentResData = res.data.appointmentList;
        let appointmentPageCount = res.data.numberOfPages;
        console.log("AppointmentAPIPageCount====", appointmentResData);
        dispatch(
          getAppointmentAction(appointmentResData, appointmentPageCount)
        );
        console.log(
          "APPOINTMENTLIST=======>",
          appointmentResData,
          appointmentPageCount
        );
        dispatch({
          type: APPOINTMENT_LIST_SUCCESS,
          payload: appointmentResData,
          appointmentPageCount: appointmentPageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: APPOINTMENT_LIST_FAIL,
        });
      });
  };
};

// Admin get Patient Team List API
export const getPatientTeamList = (pageNo: any) => {
  return function (dispatch: Dispatch<AdminGetPatientTeamListActionTypes>) {
    dispatch({
      type: ALL_PATIENT_TEAM_LOADING,
    });
    axios
      .get(
        Url.baseUrl +
          `/adminapp/api/patientTeam/patient_team_list_all?page=${Number(
            pageNo
          )}&size=10`,
        { headers }
      )
      .then((res) => {
        let patientTeamList = res.data.patientList;
        let patientTeamPageCount = res.data.numberOfPages;
        let patientTeamCurrentPageCount = res.data.current_page_no;
        dispatch(getPatientListAction(patientTeamList, patientTeamPageCount));
        dispatch({
          type: ALL_PATIENT_TEAM_SUCCESS,
          payload: patientTeamList,
          patientListPageCount: patientTeamPageCount,
          patientTeamCurrentPageCount: patientTeamCurrentPageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: ALL_PATIENT_TEAM_FAIL,
        });
      });
  };
};

// Admin get All payment List API
export const getPaymentList = (pageNo: any, body = {}) => {
  return function (dispatch: Dispatch<AdminGetAllPaymentListActionTypes>) {
    dispatch({
      type: ALL_PAYMENT_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/paymentapp/api/paymentListForAdmin/get_payment_list_for_admin?page=${Number(
            pageNo
          )}&size=10`,
        body,
        { headers }
      )
      .then((res) => {
        let allPaymentList = res.data.paymentList;
        let PageCount = res.data.numberOfPages;
        dispatch(getAllPaymentAction(allPaymentList, PageCount));
        dispatch({
          type: ALL_PAYMENT_SUCCESS,
          payload: allPaymentList,
          PageCount: PageCount,
        });
      })
      .catch((error) => {
        dispatch({
          type: ALL_PAYMENT_FAIL,
        });
      });
  };
};

// Admin Patient Query List API
export const getPatientQueryList = () => {
  return function (dispatch: Dispatch<AdminPatientQueryActionTypes>) {
    dispatch({
      type: PATIENT_QUERY_LOADING,
    });
    axios
      .get(Url.baseUrl + `/adminapp/api/queries/patient_query_list`, {
        headers,
      })
      .then((res) => {
        let patientQueryList = res.data.patientHelpQueries;
        dispatch(getPatientQueryAction(patientQueryList));
        dispatch({
          type: PATIENT_QUERY_SUCCESS,
          payload: patientQueryList,
        });
      })
      .catch((error) => {
        dispatch({
          type: PATIENT_QUERY_FAIL,
        });
      });
  };
};

// Admin Doctor Query List API
export const getDoctorQueryList = () => {
  return function (dispatch: Dispatch<AdminDoctorQueryActionTypes>) {
    dispatch({
      type: DOCTOR_QUERY_LOADING,
    });
    axios
      .get(Url.baseUrl + `/adminapp/api/queries/doctor_query_list`, { headers })
      .then((res) => {
        let doctorQueryList = res.data.doctorHelpQueries;
        dispatch(getDoctorQueryAction(doctorQueryList));
        dispatch({
          type: DOCTOR_QUERY_SUCCESS,
          payload: doctorQueryList,
        });
      })
      .catch((error) => {
        dispatch({
          type: DOCTOR_QUERY_FAIL,
        });
      });
  };
};

// Active Corporate List API
export const getActiveCorporateList = () => {
  return function (dispatch: Dispatch<ActiveCorporatePackagesTypes>) {
    dispatch({
      type: ACTIVE_CORPORATE_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/paymentapp/api/corporatePackageForAdmin/corporatePackageList`,
          { "active": Number(1) },{ headers }
      )
      .then((res) => {
        let corporatePackageList = res.data.corporatePackageList;
        dispatch(getActiveCorporateListActions(corporatePackageList));
        dispatch({
          type: ACTIVE_CORPORATE_LIST_SUCCESS,
          payload: corporatePackageList,
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTIVE_CORPORATE_LIST_FAIL,
        });
      });
  };
};

// Active Corporate List API
export const getInactiveCorporateList = () => {
  return function (dispatch: Dispatch<InactiveCorporatePackagesTypes>) {
    dispatch({
      type: INACTIVE_CORPORATE_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/paymentapp/api/corporatePackageForAdmin/corporatePackageList`,
          { "inactive": Number(0) },{ headers }
      )
      .then((res) => {
        let corporatePackageList = res.data.corporatePackageList;
        dispatch(getInactiveCorporateListActions(corporatePackageList));
        dispatch({
          type: INACTIVE_CORPORATE_LIST_SUCCESS,
          payload: corporatePackageList,
        });
      })
      .catch((error) => {
        dispatch({
          type: INACTIVE_CORPORATE_LIST_FAIL,
        });
      });
  };
};

// Active Corporate Patient List API
export const getActiveCorporatePatinetList = (packageId:any) => {
  return function (dispatch: Dispatch<ActiveCorporatePatientTypes>) {
    dispatch({
      type: ACTIVE_CORPORATE_PATIENT_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/paymentapp/api/corporatePackageForAdmin/corporatePackagePatientList`,
          { "packageId": Number(packageId) },{ headers }
      )
      .then((res) => {
        let activeCorporatePatientList = res.data.patientTeamList;
        let totalNoOfPatientTeam = res.data.totalNoOfPatientTeam;
        dispatch(getActiveCorporatepatientListActions(activeCorporatePatientList,totalNoOfPatientTeam));
        dispatch({
          type: ACTIVE_CORPORATE_PATIENT_LIST_SUCCESS,
          payload: activeCorporatePatientList,
          totalNoOfPatientTeam:totalNoOfPatientTeam
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTIVE_CORPORATE_PATIENT_LIST_FAIL,
        });
      });
  };
};
// Inactive Corporate Patient List API
export const getInactiveCorporatePatinetList = (packageId:any) => {
  return function (dispatch: Dispatch<InactiveCorporatePatientTypes>) {
    dispatch({
      type: INACTIVE_CORPORATE_PATIENT_LIST_LOADING,
    });
    axios
      .post(
        Url.baseUrl +
          `/paymentapp/api/corporatePackageForAdmin/corporatePackagePatientList`,
          { "packageId": Number(packageId) },{ headers }
      )
      .then((res) => {
        let inactiveCorporatePatientList = res.data.patientTeamList;
        let totalNoOfPatientTeam = res.data.totalNoOfPatientTeam;
        dispatch(getInactiveCorporatepatientListActions(inactiveCorporatePatientList,totalNoOfPatientTeam));
        dispatch({
          type: INACTIVE_CORPORATE_PATIENT_LIST_SUCCESS,
          payload: inactiveCorporatePatientList,
          totalNoOfPatientTeam:totalNoOfPatientTeam
        });
      })
      .catch((error) => {
        dispatch({
          type: INACTIVE_CORPORATE_PATIENT_LIST_FAIL,
        });
      });
  };
};