import React from "react";
import Errorimage from "../image/Error-page.png"
import {
    Layout,
    Page,
    DisplayText,
    Button
} from '@shopify/polaris';
import { Box, Flex } from "@primer/components";

function NotFound() {
    return (
        <Page title="Page Not Found" fullWidth>
            <Layout>
                <Layout.Section oneHalf>
                    <Flex style={{height:"50vh"}} alignItems="center" justifyContent="center">
                        <Box pt={5} mt={5}>
                            <DisplayText size="medium">Something went wrong</DisplayText>
                            <p><br />The page you are looking for might have been removed had its name changed or temporarily unavailable.<br /><br /></p>
                            <div>
                                <Button primary url="/signin">Get back to Home</Button>
                            </div>
                        </Box>
                    </Flex>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <Box pt={5} mt={5}>
                        <div><img
                            width="100%"
                            height="100%"
                            alt="" 
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }} src={Errorimage} /></div>
                    </Box>
                </Layout.Section>
            </Layout>
        </Page>
    )
}

export default NotFound;