import React, { useEffect, useState } from 'react';
import { Page, Card, DataTable, ExceptionList, Button } from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../../../ApiConfig';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import { AppState } from '../../../../../redux/store/Store';
import { ActiveCorporateList, ActiveCorporatePatientList } from '../../../../../redux/interfaces/Model';
import * as _ from 'lodash';
import { getActiveCorporateList, getActiveCorporatePatinetList } from '../../../../../redux/effects/ApiCall';
import AdminLoader from '../../../AdminLoader';

export default function ActivePatientListUnderPatientTeam(props: any) {
    const { activePatientListUnderPatientTeamLoader } = props;
    const { packageId } = useParams() as {
        packageId: string;
    };
    const { profileId } = useParams() as {
        profileId: string;
    };

    const dispatch = useDispatch();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [selectedDoctorListValue, setSelectedDoctorListValue] = useState<any[]>([]);

    function gotoDetails(patientId: number) {
        history.push(`/patientcaselist/${patientId}`);
    }
    const truncate = (str: string,) => {
        return str.length > 20 ? str.substring(0, 25) + "..." : str;
    }

    // call Active Corporate Patient List Store
    const activeCorporatePatientList = useSelector((state: AppState) => state.activeCorporatePatientValue);
    const activeCorporatePatientListValues: ActiveCorporatePatientList = _.filter(activeCorporatePatientList.activeCorporatePatientListValue, ['profileId', Number(profileId)])[0];

    // call Active Package List Store
    const activeCorporatePackageList = useSelector((state: AppState) => state.activeCorporateListValue);
    const activeCorporateListDetails: ActiveCorporateList = _.filter(activeCorporatePackageList.activeCorporateListValue, ['id', Number(packageId)])[0];

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Get Selected Doctor API
    const getPatientListByPatientTeam = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/adminapp/api/adminActions/patient_list_by_patientTeam_id',
            { "patientTeamProfileId": profileId }, { headers }).then((res) => {
                setIsLoading(false);
                let patientListValue = res.data.patientList;
                let patientListConfig = [];
                for (var i = 0; i < patientListValue.length; i++) {
                    let taskSeqNo: number = i;
                    let patientId: number = patientListValue[i].id;
                    let firstname = (patientListValue[i].firstname === "") || (patientListValue[i].firstname === null) ? ' ' : patientListValue[i].firstname;
                    let lastname = (patientListValue[i].lastname === "") || (patientListValue[i].lastname === null) ? ' ' : patientListValue[i].lastname;
                    let dob = (patientListValue[i].dob === "") || (patientListValue[i].dob === null) ? ' ' : (new Date(patientListValue[i].dob)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let patientRelationshipWithOwner = (patientListValue[i].patientRelationshipWithOwner?.displayValue);
                    let gender = (patientListValue[i].gender?.label);
                    let bloodGroup = (patientListValue[i].bloodGroup?.label);
                    let description = (patientListValue[i].description === "") || (patientListValue[i].description === null) ? ' ' : truncate(patientListValue[i].description);

                    patientListConfig.push([
                        <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(patientId)}>View</Button>,
                        patientId,
                        firstname,
                        lastname,
                        dob,
                        patientRelationshipWithOwner,
                        gender,
                        bloodGroup,
                        description
                    ]);
                }
                setSelectedDoctorListValue(patientListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        //setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                //console.log(toSignin);
            });
    };

    useEffect(() => {
        getPatientListByPatientTeam();
        if (activeCorporateListDetails === undefined && activeCorporatePatientListValues === undefined) {
            dispatch(getActiveCorporateList());
            dispatch(getActiveCorporatePatinetList(packageId));
        }
    }, [dispatch, activeCorporateListDetails, activeCorporatePatientListValues]);
    const actualPageMarkup = (
        <Card>
            <Card.Header title="Patient Details">
                <Button primary={false} onClick={() => getPatientListByPatientTeam()}>Reload</Button>
            </Card.Header>
            <Card.Section>
                {selectedDoctorListValue && selectedDoctorListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View',
                            'Patient Id',
                            'First Name',
                            'Last Name',
                            'DOB',
                            'Relationship',
                            'Gender',
                            'BloodGroup',
                            'Description'
                        ]}
                        rows={selectedDoctorListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Patient Details Is Available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )
    return (
        <div>
            {(activeCorporatePatientListValues === undefined || activeCorporateListDetails === undefined || isLoading === true) ? activePatientListUnderPatientTeamLoader(true) : activePatientListUnderPatientTeamLoader(false)}
            <Page fullWidth>
                {actualPageMarkup}
            </Page>
        </div>

    )

}