import React, { useCallback, useEffect, useState } from 'react';
import { Page, Card, DataTable, ExceptionList, Loading, Button, TextStyle, Banner, Modal, InlineError, Form, Stack } from '@shopify/polaris';
import { NoteMinor, ViewMajor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCorporatePatinetList } from '../../../../redux/effects/ApiCall';
import { AppState } from '../../../../redux/store/Store';
import { SelectPicker } from 'rsuite';
import { Box } from '@primer/components';
import axios from 'axios';
import baseUrl from '../../../../ApiConfig';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        "& $notchedOutline": {
            borderColor: "#006FBB",
            outlineWidth: 0,
        },
        "&:hover $notchedOutline": {
            borderColor: "#006FBB",
            outlineWidth: 0,
        },
        "&$focused $notchedOutline": {
            borderColor: "#006FBB",
            outlineWidth: 0,
        },
        height: "38px",
        background: "white"
    },
    input: {
        "&::placeholder": {
            color: "#006FBB"
        },
        color: "#00000", // if you also want to change the color of the input, this is the prop you'd use   
    },
    focused: {},
    notchedOutline: {}
}));


export default function ActivePatientList(id: any) {
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#006FBB",
            },
            secondary: {
                main: '#330101',
            },
            text: {
                primary: "#000000",
                secondary: "#006FBB"
            }
        },
        typography: {
            fontSize: 22,
        },
        overrides: {
            MuiInputBase: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },

    });
    const outlinedInputClasses = useOutlinedInputStyles();
    let packageId = id.packageId;

    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = <Loading />;

    const gotoDetails = (profileId: any) => {
        history.push(`/activepatientpaymentdetails/${packageId}/${profileId}`)
    }
    const [pickerDisable, setPickerDisable] = useState(false);
    const [executiveValues, setExecutiveValues] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState("");
    const handleSelectedExecutiveChange = useCallback((value) => setSelectedExecutive(value), []);
    const [notesError, setNotesError] = useState('');
    const notesErrorDiv = notesError
        ? <TextStyle variation="negative">{notesError}</TextStyle>
        : '';
    const closeNotesSubmitSuccess = () => {
        setNotesSubmitSuccess('')
    }

    const [notesSubmitSuccess, setNotesSubmitSuccess] = useState('');
    const notesSubmitSuccessDiv = notesSubmitSuccess
        ? <Banner
            onDismiss={closeNotesSubmitSuccess}
            status="success">
            <p>{notesSubmitSuccess}</p>
        </Banner>
        : '';

    const closeNotesSubmitError = () => {
        setNotesSubmitError('')
    }

    const [notesSubmitError, setNotesSubmitError] = useState('');
    const notesSubmitErrorDiv = notesSubmitError
        ? <Banner
            onDismiss={closeNotesSubmitError}
            status="critical">
            <p>{notesSubmitError}</p>
        </Banner>
        : '';

    const [notesModalActive, setNotesModalActive] = useState(false);
    const handleNotesModalToggle = () => {
        setNotesModalActive((notesModalActive) => !notesModalActive);
        setPickerDisable(true);
        setNotesError("");
        setNotesSubmitSuccess("");
        setNotesSubmitError("");
    }
    interface State {
        doctorName: string;
    }
    const [doctorName, setDoctorName] = React.useState<State>({
        doctorName: '',
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setDoctorName({ ...doctorName, [prop]: event.target.value });
    };

    const clearDoctorName = () => {
        setDoctorName({ ...doctorName, doctorName: '' });
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    // Get PatientTeam List API
    const getPatientTeamList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/search-patient/', { "searchKey": String(doctorName.doctorName) }, { headers })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.patientTeamList;
                setPickerDisable(false);
                setExecutiveValues(response);
            })
            .catch((error) => {
                setIsLoading(false);
                //setPatientrelatnlistError(error.response.data.message);
            })
    };

    const callPatientList = () => {
        dispatch(getActiveCorporatePatinetList(packageId));
    }
    const addPatientTeamBody = {
        "packageId": packageId,
        "profileId": selectedExecutive
    }
    // AddUser To CorporatePackage API
    const addPatientTeaminCorporatePackage = () => {
        if (selectedExecutive === "" || selectedExecutive === null) {
            setNotesError("Patient Team should not be empty");
        }
        else {
            setIsLoading(true);
            axios.post(baseUrl.baseUrl + '/paymentapp/api/corporatePackageForAdmin/addUserToCorporatePackage',
                addPatientTeamBody, { headers })
                .then((res) => {
                    setIsLoading(false);
                    setNotesError("");
                    setNotesSubmitError("");
                    setNotesSubmitSuccess(res.data.status);
                    handleNotesModalToggle();
                    callPatientList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsLoading(false);
                    setNotesSubmitSuccess("");
                    setNotesSubmitError(error.response.data.message);
                })
        }
    }



    // call Store
    const activeCorporatePatientList = useSelector((state: AppState) => state.activeCorporatePatientValue);
    const activeCorporatePatientListValues = activeCorporatePatientList.activeCorporatePatientListValue;
    const activeCorporatePatientTeamCount = activeCorporatePatientList.totalNoOfPatientTeam;

    useEffect(() => {
        dispatch(getActiveCorporatePatinetList(packageId));
    }, [dispatch]);

    let activeCorporatePatientListConfig: any = [];
    for (var i = 0; i < activeCorporatePatientListValues.length; i++) {
        let profileId = (activeCorporatePatientListValues[i].profileId === null) ? "" : activeCorporatePatientListValues[i].profileId;
        let firstname = (activeCorporatePatientListValues[i].firstname === null || activeCorporatePatientListValues[i].firstname === "") ? "" : activeCorporatePatientListValues[i].firstname;
        let lastname = (activeCorporatePatientListValues[i].lastname === null || activeCorporatePatientListValues[i].lastname === "") ? "" : activeCorporatePatientListValues[i].lastname;
        let email = (activeCorporatePatientListValues[i].email === null || activeCorporatePatientListValues[i].email === "") ? "" : activeCorporatePatientListValues[i].email;
        activeCorporatePatientListConfig.push([
            <Button icon={ViewMajor} size="slim" onClick={() => gotoDetails(profileId)}></Button>,
            firstname,
            lastname,
            email
        ]);
    }
    const corporateActivePatientListValue: any[] = activeCorporatePatientListConfig;
    const actualPageMarkup = (
        <Card>
            <Card.Header title="Patient Team Details">
                <Button primary={true} onClick={handleNotesModalToggle}>Add Patient Team</Button>
            </Card.Header>
            <Card.Section>
                <Stack vertical={true}>
                    <Stack.Item>
                        <TextStyle>No. of Patient Team: {activeCorporatePatientTeamCount}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                        {corporateActivePatientListValue && corporateActivePatientListValue.length ? (
                            <DataTable
                                columnContentTypes={[
                                    'text',
                                    'text',
                                    'text',
                                    'text'
                                ]}
                                headings={[
                                    'View Details',
                                    'First Name',
                                    'Last Name',
                                    'Email'
                                ]}
                                rows={corporateActivePatientListValue}
                            />
                        ) : (
                            <ExceptionList
                                items={[
                                    {
                                        icon: NoteMinor,
                                        description: 'No Patient Team Details is available!',
                                    },
                                ]}
                            />)}
                    </Stack.Item>
                </Stack>
            </Card.Section>
        </Card>
    )
    //add Patient Team Modal
    const addPatientTeamModalMarkup = (
        <div>
            <Modal
                large
                open={notesModalActive}
                onClose={handleNotesModalToggle}
                title="Add Patient Team"
                primaryAction={{
                    content: 'Submit',
                    onAction: addPatientTeaminCorporatePackage,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleNotesModalToggle,
                    },
                ]}
            >
                <Modal.Section>
                    <div style={{ marginBottom: "5px" }}>Select by Patient Team Name</div>
                    <ThemeProvider theme={theme}>
                        <Form onSubmit={getPatientTeamList}>
                            <InputLabel htmlFor="outlined-adornment-doctorName"></InputLabel>
                            <OutlinedInput
                                id="doctorNameID"
                                placeholder="Enter patient team name"
                                value={doctorName.doctorName}
                                onChange={handleChange('doctorName')}
                                fullWidth
                                classes={outlinedInputClasses}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {doctorName.doctorName === "" ? "" :
                                            <IconButton
                                                aria-label="toggle clear doctor name"
                                                onClick={clearDoctorName}
                                                edge="end"
                                            >
                                                <ClearIcon />
                                            </IconButton>}
                                        <IconButton
                                            aria-label="Enter patient team name"
                                            onClick={getPatientTeamList}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={0}
                            />
                        </Form>
                    </ThemeProvider>
                    <br />
                    <div>
                        <SelectPicker
                            block
                            onChange={handleSelectedExecutiveChange}
                            data={executiveValues}
                            value={selectedExecutive}
                            //onSelect={addPatientTeaminCorporatePackage}
                            disabled={pickerDisable}
                            menuStyle={{ zIndex: 200, color: "rgb(8, 78, 138) !important" }}
                        />
                        <InlineError message={notesErrorDiv} fieldID="contactNumberID" />
                    </div>
                    <Box p={1}>{notesSubmitSuccessDiv}</Box>
                    <Box p={1}>{notesSubmitErrorDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )
    return (
        <Page fullWidth>
            {(activeCorporatePatientList === undefined || isLoading === true) ? "" : loadingMarkup}
            {actualPageMarkup}
            {addPatientTeamModalMarkup}
        </Page>
    )
}