import React, { useState, useCallback } from 'react';
import { Box } from '@primer/components';
import {
    Tabs,
    Page,
} from '@shopify/polaris';
import ActiveDrNotes from './ActivePatientCaseNotes';
import InActiveDrNotes from './InActivePatientCaseNotes';
import AdminLoader from '../../AdminLoader';
import { useHistory } from 'react-router-dom';

export default function Task() {
    const history = useHistory();

    const loadingMarkup = <AdminLoader />;
    const [activeNoteLoader, setActiveNoteLoader] = useState(false);
    const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);

    const tabs = [
        {
            id: 'activeNote-list',
            content: 'Active Note List',
            body: (
                <ActiveDrNotes activeNoteLoader={setActiveNoteLoader} />
            ),
        },
        {
            id: 'inactiveNote-list',
            content: 'Inactive Note List',
            body: (
                <InActiveDrNotes inactiveNoteLoader={setInactiveNoteLoader} />
            ),
        },
    ];

    return (
        <div>
            {(activeNoteLoader === true || inactiveNoteLoader === true) ? loadingMarkup : ""}
            <Page fullWidth title="Patient Notes"
                breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
            >
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    )
}