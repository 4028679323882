import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    Page,
    Button,
    ExceptionList,
    Card,
    Banner,
    Toast,
    Modal,
    Popover, 
    ActionList,
    Stack,
    SkeletonPage,
    TextContainer,
    FormLayout,
    SkeletonBodyText,
    SkeletonDisplayText
} from '@shopify/polaris';
import { NoteMinor, SettingsMinor, ViewMinor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DataGrid, GridCellParams,  GridColDef, GridSelectionModelChangeParams } from '@material-ui/data-grid';
import { getAllInactiveTaskListValue, getAllTaskListValue } from '../../../redux/effects/ApiCall';
import { AppState } from '../../../redux/store/Store';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import Url from '../../../ApiConfig';
import { SelectPicker } from 'rsuite';
import { Box } from '@primer/components';

export default function InactiveTaskList(props: any) {

    const {inactiveTaskLoader} = props;

    const [isLoading, setIsLoading] = useState(false);
    inactiveTaskLoader(isLoading)

    const [selectedItems, setSelectedItems] = useState<GridSelectionModelChangeParams>();
    console.log("Selected_Items===", selectedItems);

    const [popoverActive, setPopoverActive] = useState(false);
    const togglePopoverActive = useCallback(
      () => setPopoverActive((popoverActive) => !popoverActive),
      [],
    );
    const activator = (
      <Button icon={SettingsMinor} disabled={(selectedItems === null || selectedItems === undefined || selectedItems.selectionModel.length === 0) ? true : false} onClick={togglePopoverActive} disclosure></Button>
    );

    let token = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${token}`
    };

    const [executiveValues, setExecutiveValues] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState(null);
    const handleSelectedExecutiveChange = useCallback((value) => setSelectedExecutive(value), []);

    const [executiveModalActive, setExecutiveModalActive] = useState(false);
    const handleExecutiveModalChange = () => {
        setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
        setSelectedExecutive(null);
        setSuccessAssignBannerTitle("");
        setErrorAssignBannerTitle("");
    }

    const [executiveAPIStatus, setExecutiveAPIStatus] = useState();
    console.log("MainStatus====", executiveAPIStatus);
    const [successAssignBannerTitle, setSuccessAssignBannerTitle] = useState("");
    const successAssignBannerDiv = successAssignBannerTitle
        ? <Banner
            status="success">
            <p>{successAssignBannerTitle}</p>
        </Banner>
        : '';
    const [errorAssignBannerTitle, setErrorAssignBannerTitle] = useState("");
    const errorAssignBannerDiv = errorAssignBannerTitle
        ? <Banner
            status="critical">
            <p>{errorAssignBannerTitle}</p>
        </Banner>
        : '';
    const [assignExecutiveSuccessMessage, setAssignExecutiveSuccessMessage] = useState("");
    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [toastSuccessActive, setToastSuccessActive] = useState(false);
    const toggleToastSuccessActive = useCallback(() => setToastSuccessActive((toastSuccessActive) => !toastSuccessActive), []);

    const toastExecutiveSuccessMarkup = toastSuccessActive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleToastSuccessActive} />
    ) : null;

    const [assignExecutiveErrorMessage, setAssignExecutiveErrorMessage] = useState("");
    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [toastErrorActive, setToastErrorActive] = useState(false);
    const toggleToastErrorActive = useCallback(() => setToastErrorActive((toastErrorActive) => !toastErrorActive), []);

    const toastExecutiveErrorMarkup = toastErrorActive ? (
        <Toast content={toastErrorMessage} onDismiss={toggleToastErrorActive} error />
    ) : null;

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'View',
            width: 100,
            renderCell: (params: GridCellParams) => (
                <Button icon={ViewMinor} onClick={() => {
                    gotoTaskDetails(currentPage, params.value)
                }}>View</Button>),
        },
        { field: 'assigned_to', headerName: 'Assigned To', width: 250 },
        { field: 'title', headerName: 'Title', width: 400 },
        {
            field: 'consultationType',
            headerName: 'Type',
            width: 200
        },
        { field: 'patientName', headerName: 'Patient name', width: 230 },
    ];

    const dispatch = useDispatch();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    const bulkAssignExecutive = () => {
        getExecutiveList();
        handleExecutiveModalChange();
    }

    const [page, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log("PAGECOUNT==>", value);
        setPage(value);
        dispatch(getAllTaskListValue(value));
    };

    const history = useHistory();
    const gotoTaskDetails = (currentPage: any, rowIndex: any) => {
        history.push(`/taskdetails/${currentPage}/${rowIndex}`);
    }

    // Reload Self Task List
    const reloadSelfTaskList = () => {
        dispatch(getAllTaskListValue(page));
    }

    // Get Executive List API
    const getExecutiveList = () => {
        setIsLoading(true);
        axios.get(Url.baseUrl + '/adminapp/api/executives/executive_list_dropdown', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `token ${token}`
            }
        })
            .then((res) => {
                setIsLoading(false);
                let response = res.data.executiveList;
                setExecutiveValues(response);
            })
            .catch((error) => {
                setIsLoading(false);
                //setPatientrelatnlistError(error.response.data.message);
            })
    };

    // Assign Executive API
    const assignExecutive = () => {
        if (selectedExecutive != null) {
            if (typeof selectedItems != undefined) {
                var errorMessage = '';
                // let value = selectedItems?.selectionModel.length!;
                let id = selectedItems?.selectionModel!
                console.log("iDNO===", id);
                id.forEach((element) => {
                    console.log("BULKID==>", element)
                    setIsLoading(true);
                    axios.post(Url.baseUrl + '/adminapp/api/adminActions/assign_task_to_admin',
                        { "taskId": element, "executiveId": Number(selectedExecutive) }, { headers })
                        .then((res) => {
                            setIsLoading(false);
                            setExecutiveModalActive(false);
                            setExecutiveAPIStatus(res.data.status);
                            setToastSuccessMessage("Task assigned successfully");
                            setToastErrorMessage("");
                            setToastSuccessActive(true);
                        })
                        .catch((error) => {
                            setIsLoading(false);
                            if (error.response.data.status === -1) {
                                setIsLoading(false);
                                setToastErrorActive(true);
                                setToastErrorMessage(error.response.data.message);
                            }
                            else if (error.response.data.status === -2) {
                                setIsLoading(false);
                                setToastErrorActive(true);
                                setToastErrorMessage(error.response.data.message);
                                setToastSuccessMessage("");
                            }
                            else if (error.response.data.status === -3) {
                                setIsLoading(false);
                                setToastErrorActive(true);
                                setToastErrorMessage(error.response.data.message);
                                setToastSuccessMessage("");
                            }
                        })
                }

                );
            }
        }
        else {
            setToastErrorMessage("Please select atleast one executive");
            toggleToastErrorActive();
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(getAllInactiveTaskListValue(page));
    }, [dispatch]);

    const AdminAllTaskList = useSelector((state: AppState) => state.allinactivetasklist);
    const pageCount = useSelector((state: AppState) => state.allinactivetasklist.pageCount);
    console.log("PageCount===", pageCount);
    const taskValues = AdminAllTaskList.allinactivetasklist;
    const currentPage = AdminAllTaskList.count;
    console.log("CURRENTPAGECOUNTREDUX==>", currentPage)

    const assignExecutiveModalMarkup = (
        <div>
            <Modal
                large
                open={executiveModalActive}
                onClose={handleExecutiveModalChange}
                title="Select Executive"
                primaryAction={{
                    content: 'Assign',
                    onAction: assignExecutive,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleExecutiveModalChange,
                    },
                ]}
            >
                <Modal.Section>
                    <div>
                        <SelectPicker
                            block
                            onChange={handleSelectedExecutiveChange}
                            data={executiveValues}
                            value={selectedExecutive}
                            menuStyle={{ zIndex: 200, color: "rgb(8, 78, 138) !important" }}
                        />
                    </div>
                    <Box p={1}>{successAssignBannerDiv}</Box>
                    <Box p={1}>{errorAssignBannerDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )

    const DataGridMarkup = (
        <Card>
            <Card.Header>
                <Typography>Page: {page}</Typography>
                <Pagination color="primary" count={Number(pageCount)} page={page} onChange={handleChange} />
                <Button primary={false} onClick={reloadSelfTaskList}>Reload</Button>
            </Card.Header>
            <Card.Section>
                <div style={{ height: 600, width: '100%' }}>
                    {taskValues && taskValues.length ? (
                        <DataGrid
                            rows={taskValues}
                            columns={columns}
                            pageSize={10}
                            disableSelectionOnClick
                            checkboxSelection
                            hideFooter={true}
                            onSelectionModelChange={(value) => {
                                console.log("SELECTED VALUES = ", value);
                                setSelectedItems(value);
                            }
                            }
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Task is available!',
                                },
                            ]}
                        />)}
                </div>
            </Card.Section>
        </Card>
    )

    const loaderskeleton = (
        <Page fullWidth>
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>


                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (

        <div>
            {(AdminAllTaskList.loading === true || isLoading === true) ? inactiveTaskLoader(true) && <Stack vertical>{loaderskeleton}</Stack> :
                <Page fullWidth
                    primaryAction={
                        <Popover
                            active={popoverActive}
                            activator={activator}
                            onClose={togglePopoverActive}
                        >
                            <ActionList items={[{ content: 'Assign Executive', onAction: bulkAssignExecutive },]} />
                        </Popover>
                    }
                >
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {DataGridMarkup}
                    </ThemeProvider>
                    {assignExecutiveModalMarkup}
                    {toastExecutiveSuccessMarkup}
                    {toastExecutiveErrorMarkup}
                </Page>}
        </div>
    );
}

