import React, { Component } from 'react';
import {
  Page,
  Card,
  DescriptionList
} from '@shopify/polaris';


class AdminDetails extends Component {

  render() {
    let firstName = localStorage.getItem("firstname");
    let lastName = localStorage.getItem("lastname");
    let email = localStorage.getItem("email");

    const adminDetailsMarkup = (
      <Card>
        <Card.Section>
          <DescriptionList
            items={[
              {
                term: 'First Name',
                description: firstName
              },
              {
                term: 'Last Name',
                description: lastName
              },
              {
                term: 'Email',
                description: email
              },
            ]}
          />
        </Card.Section>
      </Card>
    )
    return (
      <Page title="Admin Details" fullWidth>
        {adminDetailsMarkup}
      </Page>
    )
  }
}

export default AdminDetails;