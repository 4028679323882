import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    Button,
    Page,
    Scrollable,
    DataTable,
    ExceptionList,
    Toast
} from '@shopify/polaris';

import axios from 'axios';
import Url from '../../../ApiConfig';
import { EditMinor, NoteMinor } from '@shopify/polaris-icons';

export default function InActiveNotes(props: any) {
    const { inactiveTaskLoader } = props;

    const [isLoading, setIsLoading] = useState(false);
    inactiveTaskLoader(isLoading);

    const [noteStatusSuccess, setNoteStatusSuccess] = useState('');
    const [noteStatusSuccessActive, setNoteStatusSuccessActive] = useState(false);
    const togglenoteStatusSuccessActive = useCallback(() => setNoteStatusSuccessActive((noteStatusSuccessActive) => !noteStatusSuccessActive), []);
    const noteStatusSuccessMarkup = noteStatusSuccessActive ? (
        <Toast content={noteStatusSuccess} onDismiss={togglenoteStatusSuccessActive} />
    ) : null;

    const [noteStatusError, setNoteStatusError] = useState('');
    const [noteStatusErrorActive, setNoteStatusErrorActive] = useState(false);
    const togglenoteStatusErrorActive = useCallback(() => setNoteStatusErrorActive((noteStatusErrorActive) => !noteStatusErrorActive), []);
    const noteStatusErrorMarkup = noteStatusErrorActive ? (
        <Toast content={noteStatusError} onDismiss={togglenoteStatusErrorActive} />
    ) : null;

    const doctorId = localStorage.getItem("doctorID");

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Notes Status change API
    function editProfessionalDetails(response: any) {
        setIsLoading(true);
        axios.get(Url.baseUrl + `/erpapp/api/adminNote/${Number(response)}/toggle_active`,
            { headers })
            .then((res) => {
                setIsLoading(false);
                setNoteStatusError("");
                setNoteStatusSuccess(res.data.message);
                inActiveNoteList();
                togglenoteStatusSuccessActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setNoteStatusSuccess("");
                setNoteStatusError(error.response.data.message);
                inActiveNoteList();
                togglenoteStatusErrorActive();
            })
    }

    const [noteListValue, setNoteListValue] = useState<any[]>([]);
    // Get InActive Notes List
    const inActiveNoteList = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/erpapp/api/adminNote/get_admin_notes_by_doctor', { "doctorId": doctorId, "isActive": false }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.noteList;
                let noteListConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i].id;
                    let note = (resData[i].note === "") ? "" : resData[i].note;
                    let createdDate = (resData[i].createdDate === null) ? null : (new Date(resData[i].createdDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let createBy = (resData[i].createdBy === null) ? "" : resData[i].createdBy.user.first_name + " " + resData[i].createdBy.user.last_name;
                    let modifiedDate = (resData[i].modifiedDate === null) ? null : (new Date(resData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let modifyBy = (resData[i].modifiedBy === null) ? "" : resData[i].modifiedBy.user.first_name + " " + resData[i].modifiedBy.user.last_name;
                    noteListConfig.push([
                        note,
                        createdDate,
                        createBy,
                        <Button icon={EditMinor} size="slim" onClick={() => editProfessionalDetails(selectedResponse)}></Button>,
                        modifyBy,
                        modifiedDate,
                    ]);
                }
                setNoteListValue(noteListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }


    useEffect(() => {
        inActiveNoteList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const getInActiveNoteList = (
        <Card>
            <Card.Header title="Inactive Notes">
                <Button primary={false} onClick={inActiveNoteList}>Reload</Button>
            </Card.Header>
            <Card.Section>
                {noteListValue && noteListValue.length ? (
                    <Scrollable shadow focusable>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Note',
                                'Create Date',
                                'Create By', ,
                                'Modify Status',
                                'Modify By',
                                'Modify Date'
                            ]}
                            rows={noteListValue}
                        />
                    </Scrollable>
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Inactive Notes Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    return (
        <Page>
            {getInActiveNoteList}
            {noteStatusSuccessMarkup}
            {noteStatusErrorMarkup}
        </Page>
    );
}