import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Card,
    Page,
    ExceptionList,
    DataTable,
    Button,
    SkeletonPage,
    TextContainer,
    FormLayout,
    SkeletonDisplayText,
    SkeletonBodyText,
    Stack,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPatientCaseList } from '../../../redux/effects/ApiCall';
import { AppState } from '../../../redux/store/Store';
import { useHistory } from 'react-router-dom';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AdminLoader from '../AdminLoader';

export default function ActiveAllCaseList() {
    const loadingMarkup = <AdminLoader />;
    const dispatch = useDispatch();
    const history = useHistory();

    const truncateWhyWant2ndOpinion = (str: string,) => {
        return str.length > 21 ? str.substring(0, 21) + "..." : str;
    }
    const truncateSecondOpinionReasons = (str: string,) => {
        return str.length > 20 ? str.substring(0, 20) + "..." : str;
    }

    const truncateDoctorTeamType = (str: string,) => {
        return str.length > 17 ? str.substring(0, 17) + "..." : str;
    }

    function gotoDetails(patientId: number, caseId: number) {
        history.push(`/filepreparation/${patientId}/${caseId}`);
    }

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    // encounter list api call ...
    useEffect(() => {
        dispatch(getAllPatientCaseList(page, 1));
    }, [dispatch]);

    const AllCaseListValue = useSelector((state: AppState) => state.allcaseValue);
    const allcaseValues = AllCaseListValue.allcaseValue;
    const pageCount = useSelector((state: AppState) => state.allcaseValue.pageCount);

    let allCaseListConfig: any = [];
    for (var i = 0; i < allcaseValues.length; i++) {
        let taskSeqNo: number = i;
        let caseId: number = allcaseValues[i].caseId;
        let patientId: number = allcaseValues[i].patientId;
        let patientName = (allcaseValues[i].patientName === "") || (allcaseValues[i].patientName === null) ? ' ' : allcaseValues[i].patientName;
        let whyWant2ndOpinion = (allcaseValues[i].whyWant2ndOpinion === "") || (allcaseValues[i].whyWant2ndOpinion === null) ? ' ' : truncateWhyWant2ndOpinion(allcaseValues[i].whyWant2ndOpinion);
        let secondOpinionReasons = (allcaseValues[i].secondOpinionReasons === "") || (allcaseValues[i].secondOpinionReasons === null) ? ' ' : truncateSecondOpinionReasons(allcaseValues[i].secondOpinionReasons);
        let consultationtype = (allcaseValues[i].consultationType === "") || (allcaseValues[i].consultationType === null) ? ' ' : allcaseValues[i].consultationType;
        let doctorTeamType = (allcaseValues[i].doctorTeamType === "") || (allcaseValues[i].doctorTeamType === null) ? ' ' : truncateDoctorTeamType(allcaseValues[i].doctorTeamType);
        allCaseListConfig.push([
            <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(patientId, caseId)}></Button>,
            caseId,
            patientId,
            patientName,
            whyWant2ndOpinion,
            secondOpinionReasons,
            consultationtype,
            doctorTeamType
        ]);
    }
    const allCaseListValue: any[] = allCaseListConfig;

    const [page, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        dispatch(getAllPatientCaseList(value, 1));
    };

    const taskTable = allCaseListValue ? (
        <Card>
            <Card.Header >
                <Typography>Page: {page}</Typography>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination color="primary" count={Number(pageCount)} page={page} onChange={handleChange} />
                </ThemeProvider>
            </Card.Header>
            <Card.Section>
                {allCaseListValue && allCaseListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View',
                            'CaseId',
                            'PatientId',
                            'Patient Name',
                            'WhyWant2ndOpinion',
                            'Second Opinion Reasons',
                            'Consultationtype',
                            'DoctorTeamType'
                        ]}
                        rows={allCaseListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Case Details Is Available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    ) : (
        null
    );

    const loaderskeleton = (
        <Page fullWidth >
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    return (

        <div>
            {AllCaseListValue.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth >
                {
                    AllCaseListValue.loading ? "" :
                        <ThemeProvider theme={theme}>
                            {taskTable}
                        </ThemeProvider>
                }
            </Page>}
        </div>
    );
}