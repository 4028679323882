import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Thumbnail,
    Loading,
    Caption,
    Page,
    DataTable,
    Modal,
    Button,
    TextContainer,
    Subheading,
    ExceptionList
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom';
import baseUrl from '../../../ApiConfig';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#50B83C',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});


export default function FinalOpinion(props: any) {
    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [patientDetailsId, setPatientDetailsId] = useState('');

    const [toSignin, setToSignin] = useState(false);
     const {appointmentFinalOpinionLoader} = props;
    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [secondOpinionDescription, setSecondOpinionDescription] = useState('');
    const handleSecondOpinionDescriptionChange = useCallback((newValue) => {
        setSecondOpinionDescription(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };

    const closeerror = () => {
        setSecondOpinionError('')
    }
    const [secondOpinionError, setSecondOpinionError] = useState('');
    const medicalSummaryErrorDiv = secondOpinionError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{secondOpinionError}</p>
        </Banner>
        : '';

    const closeSecondOpinionSuccess = () => {
        setSecondOpinionSuccess('');
    }
    const [secondOpinionSuccess, setSecondOpinionSuccess] = useState('');
    const successDiv = secondOpinionSuccess
        ? <Banner
            onDismiss={closeSecondOpinionSuccess}
            status="success">
            <p>{secondOpinionSuccess}</p>
        </Banner>
        : '';

    const [secondOpinionDescriptionError, setSecondOpinionDescriptionError] = useState('');
    const secondOpinionDescriptionErrordiv = secondOpinionDescriptionError
        ? <TextStyle variation="negative">{secondOpinionDescriptionError}</TextStyle>
        : '';

    const uploadbody = {
        'typeName': 'patient-image'
    };

    const [descriptionModalActive, setDescriptionModalActive] = useState(false);
    const handleDescriptionToggle = () => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setImageInfo('');
        setSecondOpinionDescriptionError('');
        setSecondOpinionDescription('');
        setSecondOpinionSuccess('');
        setSecondOpinionError('');
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteSecondOpinionModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    // Delete popup open ........   
    function deleteMedicalSummary(response: any) {
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteMedicalSummarySuccess('');
    }

    const closeProblemDeleteModalSucessError = () => {
        setSecondOpinionSuccess('');
    }

    const [deleteMedicalSummarySuccess, setDeleteMedicalSummarySuccess] = useState('');
    const successDeleteProblemModalDiv = deleteMedicalSummarySuccess
        ? <Banner
            onDismiss={closeProblemDeleteModalSucessError}
            status="success">
            <p>{deleteMedicalSummarySuccess}</p>
        </Banner>
        : '';

    //child to parent value send section.....
    const secondOpinionBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "finalOpinion",
        "description": secondOpinionDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [chiefComplaintListValue, setChiefComplaintListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const getSecondOpinionBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "finalOpinion"
    }
    const getSecondOpinionList = () => {
        appointmentFinalOpinionLoader(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getSecondOpinionBody, { headers })
            .then((res) => {
                appointmentFinalOpinionLoader(false);
                let resData = res.data.patientDetailList;
                console.log("Symptom List=======>", resData);
                let symptomConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i];
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    console.log("Award Name " + description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    console.log("supportingDocuments==> ", supportingDocuments);
                    setPatientDetailsId(patientDetailsID);
                    symptomConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        dateEnd,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteMedicalSummary(selectedResponse)}></Button>
                    ]);
                    console.log("Symptoms List ====> ", symptomConfig);
                }
                setChiefComplaintListValue(symptomConfig);
            })
            .catch((error) => {
                appointmentFinalOpinionLoader(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    // const secondOpinionSubmit = () => {
    //     console.log(secondOpinionBody);
    //     if (secondOpinionDescription === '') {
    //         setSecondOpinionDescriptionError("Please add description");
    //     }
    //     else {
    //         axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
    //             secondOpinionBody, { headers })
    //             .then(result => {
    //                 setIsLoading(false);
    //                 if (result) {
    //                     setSecondOpinionError('');
    //                     setSecondOpinionDescriptionError('');
    //                     setSecondOpinionSuccess(result.data.message);
    //                     getSecondOpinionList();
    //                     handleDescriptionToggle();
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error.response);
    //                 setSecondOpinionDescriptionError('');
    //                 setSecondOpinionSuccess('');
    //                 setSecondOpinionError(error.response.data.message);
    //                 setIsLoading(false);
    //             })
    //     }
    // }
    // Delete body....
    const deleteSecondOpinionRecordBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeleteSecondOpinionSubmit = () => {
        appointmentFinalOpinionLoader(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteSecondOpinionRecordBody, { headers })
            .then((res) => {
                appointmentFinalOpinionLoader(false);
                setDeleteMedicalSummarySuccess(res.data.message);
                getSecondOpinionList();
                handleDeleteSecondOpinionModalChange();
            })

            .catch((error) => {
                appointmentFinalOpinionLoader(false);
                // console.log("error record====" + error);
            })
    }

    useEffect(() => {
        window.scrollTo(0,0);
        getSecondOpinionList();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const secondOpinionTableBody = (
        <div>
            <Card >
                <Card.Header title="Documents">
                    {/* <Button primary={true} onClick={handleDescriptionToggle}>Upload Document</Button> */}
                </Card.Header>
                <Card.Section>
                    {chiefComplaintListValue && chiefComplaintListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Document',
                                'Description',
                                'Start Date',
                                'End Date',
                                'Delete'
                            ]}
                            rows={chiefComplaintListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Final Opinion Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // const uploadDescriptionBody = (
    //     <Modal
    //         large
    //         open={descriptionModalActive}
    //         onClose={handleDescriptionToggle}
    //         title={"Upload Documents of Final Opinion"}
    //         primaryAction={{
    //             content: "Submit",
    //             onAction: secondOpinionSubmit,
    //         }}
    //     >
    //         <Modal.Section>
    //             <Page>
    //                 <FormLayout>
    //                     <FormLayout.Group condensed>
    //                         <div>
    //                             <div>
    //                                 <TextField
    //                                     label="Final Opinion Description"
    //                                     value={secondOpinionDescription}
    //                                     onChange={handleSecondOpinionDescriptionChange}
    //                                     placeholder="Please describe reason for second opinion"
    //                                     multiline={4}
    //                                     id="secondOpinionDescriptionID"
    //                                 />
    //                                 <InlineError message={secondOpinionDescriptionErrordiv} fieldID="secondOpinionDescriptionID" />
    //                             </div>
    //                         </div>
    //                         <div>
    //                             <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
    //                             <Box style={{ textAlign: "center", border: "1px #50B83C solid", borderRadius: "3px" }} p={1}>
    //                                 <Uploader
    //                                     action="https://webserver.aims2health.com/storageapp/api/upload-image/"
    //                                     draggable
    //                                     data={uploadbody}
    //                                     name="document"
    //                                     listType="text"
    //                                     onSuccess={(response: Object,) => {
    //                                         let res = JSON.stringify(response);
    //                                         setImageId(JSON.parse(res).image.id);
    //                                         setImageInfo(JSON.parse(res).image.document);
    //                                         console.log(imageId);
    //                                     }}
    //                                 >
    //                                     <button style={styles} type="button">
    //                                         {uploading && <Loader backdrop center />}
    //                                         {imageInfo ? (
    //                                             <img src={imageInfo} width="100%" height="100%" alt="" />
    //                                         ) : (
    //                                             <Thumbnail source={NoteMinor} size="large" alt="Small document" />

    //                                         )}
    //                                     </button>
    //                                 </Uploader>
    //                             </Box>
    //                             <Caption>
    //                                 <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
    //                             </Caption>
    //                         </div>
    //                     </FormLayout.Group>
    //                     <FormLayout.Group condensed>
    //                         <div>
    //                             <p style={{ marginBottom: "5px" }}>Start Date</p>
    //                             <ThemeProvider theme={defaultMaterialTheme}>
    //                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //                                     <KeyboardDatePicker
    //                                         autoOk
    //                                         inputVariant="outlined"
    //                                         fullWidth
    //                                         format="dd/MM/yyyy"
    //                                         value={selectedDate}
    //                                         InputAdornmentProps={{ position: "start" }}
    //                                         onChange={date => handleDateChange(date)}
    //                                         size="small"
    //                                         focused
    //                                         id="startDate"
    //                                         InputProps={{
    //                                             endAdornment: (
    //                                                 <IconButton onClick={() => handleDateChange(null)}>
    //                                                     <Icon icon="close" />
    //                                                 </IconButton>
    //                                             )
    //                                         }}
    //                                     />
    //                                 </MuiPickersUtilsProvider>
    //                             </ThemeProvider>
    //                         </div>
    //                         <div>
    //                             <p style={{ marginBottom: "5px" }}>End Date</p>
    //                             <ThemeProvider theme={defaultMaterialTheme}>
    //                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //                                     <KeyboardDatePicker
    //                                         autoOk
    //                                         inputVariant="outlined"
    //                                         fullWidth
    //                                         format="dd/MM/yyyy"
    //                                         value={selectedEndDate}
    //                                         InputAdornmentProps={{ position: "start" }}
    //                                         onChange={date => handleEndDateChange(date)}
    //                                         size="small"
    //                                         focused
    //                                         id="endDate"
    //                                         InputProps={{
    //                                             endAdornment: (
    //                                                 <IconButton onClick={() => handleEndDateChange(null)}>
    //                                                     <Icon icon="close" />
    //                                                 </IconButton>
    //                                             )
    //                                         }}
    //                                     />
    //                                 </MuiPickersUtilsProvider>
    //                             </ThemeProvider>
    //                         </div>
    //                     </FormLayout.Group>
    //                     <div>{successDiv}</div>
    //                     <div>{medicalSummaryErrorDiv}</div>
    //                 </FormLayout>
    //             </Page>
    //         </Modal.Section>
    //     </Modal>
    // )
    // Delete modal section ....
    const deleteSecondOpinionDetailsBody = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteSecondOpinionModalChange}
            title={" Delete Final Opinion Details"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteSecondOpinionSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteSecondOpinionModalChange,
                },
            ]}
            footer={successDeleteProblemModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Final Opinion Details ?
            </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    return (
        <Page fullWidth title="Final Opinion">
            {toSignin ? <Redirect to="/signin" /> : null}
            {secondOpinionTableBody}
            {/* {uploadDescriptionBody} */}
            {docViewerComponent}
            {deleteSecondOpinionDetailsBody}
        </Page>
    );
}