import React, { useEffect, useState } from 'react';
import { Page, Card, DataTable, ExceptionList, Button } from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../../../../ApiConfig';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NoteMinor, PaymentsMajor } from '@shopify/polaris-icons';
import { AppState } from '../../../../../redux/store/Store';
import { ActiveCorporateList, ActiveCorporatePatientList } from '../../../../../redux/interfaces/Model';
import * as _ from 'lodash';
import { getActiveCorporateList, getActiveCorporatePatinetList } from '../../../../../redux/effects/ApiCall';
import AdminLoader from '../../../AdminLoader';

export default function ActivePatientTeamPaymentDetails(props:any) {
    const {activePatientTeamPaymentDetailsLoader} = props;
    const { packageId } = useParams() as {
        packageId: string;
    };
    const { profileId } = useParams() as {
        profileId: string;
    };

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    let reduxAPILoader = <AdminLoader />;

    const [corporateActivePackageListValue, setCorporateActivePackageListValue] = useState<any[]>([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    function paymentLink(paymentLink: any) {
        window.open(paymentLink);
    }

    // call Active Corporate Patient List Store
    const activeCorporatePatientList = useSelector((state: AppState) => state.activeCorporatePatientValue);
    const activeCorporatePatientListValues: ActiveCorporatePatientList = _.filter(activeCorporatePatientList.activeCorporatePatientListValue, ['profileId', Number(profileId)])[0];

    // call Active Package List Store
    const activeCorporatePackageList = useSelector((state: AppState) => state.activeCorporateListValue);
    const activeCorporateListDetails: ActiveCorporateList = _.filter(activeCorporatePackageList.activeCorporateListValue, ['id', Number(packageId)])[0];

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const getCorporateActivePackagePatient = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/paymentapp/api/corporatePackageForAdmin/corporatePackagePatientPaymentList',
            { "packageId": Number(packageId), "profileId": Number(profileId) }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.paymentList;
                let corporateActivePackageConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let id = (resData[i].id === null) ? "" : resData[i].id;
                    let create_date = (resData[i].create_date === null) ? "" : (new Date(resData[i].create_date)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let amount = (resData[i].amount === null || resData[i].amount === "") ? "" : resData[i].amount;
                    let note = (resData[i].note === null || resData[i].note === "") ? "" : resData[i].note;
                    let paymentLink = (resData[i].paymentLink === null || resData[i].paymentLink === null) ? "" : resData[i].paymentLink;
                    let status = (resData[i].status === null || resData[i].status === "") ? "" : resData[i].status;
                    corporateActivePackageConfig.push([
                        <Button icon={PaymentsMajor} size="slim" disabled={(status === "paid" || status === "free") ? true : false} onClick={() => paymentLink(paymentLink)}>
                            Pay Now
                        </Button>,
                        amount,
                        note,
                        status,
                        create_date,
                    ]);
                }
                setCorporateActivePackageListValue(corporateActivePackageConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    useEffect(() => {
        getCorporateActivePackagePatient();
        if (activeCorporateListDetails === undefined && activeCorporatePatientListValues === undefined) {
            dispatch(getActiveCorporateList());
            dispatch(getActiveCorporatePatinetList(packageId));
        }
    }, [dispatch, activeCorporateListDetails, activeCorporatePatientListValues]);
    const actualPageMarkup = (
        <Card>
            <Card.Header title="Payment Details">
                <Button primary={false} onClick={() => getCorporateActivePackagePatient()}>Reload</Button>
            </Card.Header>
            <Card.Section>
                {corporateActivePackageListValue && corporateActivePackageListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            "Payment Link",
                            "Amount",
                            "Note",
                            "Status",
                            "Create Date",
                        ]}
                        rows={corporateActivePackageListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Patient Payment Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )
    return (
        <div>
            {(activeCorporatePatientListValues === undefined || activeCorporateListDetails === undefined || isLoading === true) ? activePatientTeamPaymentDetailsLoader(true) : activePatientTeamPaymentDetailsLoader(false)}
            <Page fullWidth>
                {actualPageMarkup}
            </Page>
        </div>

    )

}