import React, { useState, useEffect } from 'react';
import {
    Page,
    Loading,
    Card,
    DataTable,
    ExceptionList,
    Button,
    Modal,
    Heading,
    Stack,
    SkeletonPage,
    TextContainer,
    FormLayout,
    SkeletonBodyText,
    SkeletonDisplayText
} from '@shopify/polaris';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/store/Store';
import { getDoctorQueryList } from '../../redux/effects/ApiCall';
import { Box } from '@primer/components';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import AdminLoader from './AdminLoader';
import { useMediaQuery } from '@material-ui/core';


export default function Doctor() {
    const loadingMarkup = <AdminLoader />;
    const dispatch = useDispatch();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    const [descriptionModalActive, setDescriptionModalActive] = useState(false);
    const [description, setDescription] = useState("");

    const closeModal = () => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setDescription("");
    }
    const viewDescription = (descriptionText: any) => {
        setDescriptionModalActive((descriptionModalActive) => !descriptionModalActive);
        setDescription(descriptionText);
    }

    const truncate = (str: string,) => {
        return str.length > 25 ? str.substring(0, 25) + "..." : str;
    }

    // call Store
    const AdminDoctorQueryList = useSelector((state: AppState) => state.adminDoctorQueryListValue);
    const adminDoctorQueryValues = AdminDoctorQueryList.adminDoctorQueryListValue;

    // Get Patient Query List
    let adminDoctorQueryConfig: any = [];
    for (var i = 0; i < adminDoctorQueryValues.length; i++) {
        //let selectedResponse = adminDoctorQueryValues[i].id;
        let phoneNumber = (adminDoctorQueryValues[i].callbackNumbers === null) ? "" : adminDoctorQueryValues[i].callbackNumbers[0].number;
        let description = (adminDoctorQueryValues[i].description == null) ? "" : adminDoctorQueryValues[i].description;
        let tableDescription = (adminDoctorQueryValues[i].description == null) ? "" : truncate(adminDoctorQueryValues[i].description);
        adminDoctorQueryConfig.push([
            phoneNumber,
            tableDescription,
            <Button icon={ViewMinor} primary={false} onClick={() => viewDescription(description)}>View</Button>
        ]);
    }
    const doctorQueries: any[] = adminDoctorQueryConfig;

    const reloadPatientQueryList = () => {
        dispatch(getDoctorQueryList());
    }

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(getDoctorQueryList());
    }, []);


    const actualPageMarkup = (
        <Card>
            <Card.Header>
                <Button primary={false} onClick={reloadPatientQueryList}>Reload</Button>
            </Card.Header>
            <Card.Section>
                {doctorQueries && doctorQueries.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Phone Number',
                            'Description',
                            'View'
                        ]}
                        rows={doctorQueries}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Queries is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    const loaderskeleton = (
        <Page fullWidth title="Doctor Queries List">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )



    const descriptionModalBody = (
        <Modal
            large
            open={descriptionModalActive}
            onClose={closeModal}
            title="Description"
        >
            <Modal.Section>
                <Heading>
                    {description}
                </Heading>
            </Modal.Section>

        </Modal>
    );

    return (
        // <Page title="Doctor Queries List" fullWidth>
        //     {AdminDoctorQueryList.loading ? loadingMarkup : ""}
        //     {actualPageMarkup}
        //     {descriptionModalBody}
        // </Page>

        <div>
            {AdminDoctorQueryList.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth title="Doctor Queries List">
                {
                    AdminDoctorQueryList.loading ? "" :
                        <ThemeProvider theme={theme}>
                            {actualPageMarkup}
                        </ThemeProvider>
                }
            </Page>}
            {descriptionModalBody}
        </div>
    )
}
