import React, { useState, useEffect, useCallback } from 'react';
import {
    Layout,
    Banner,
    Heading,
    Card,
    Button,
    Toast,
    Stack,
    TextStyle,
    DataTable,
    ExceptionList,
    TextField,
    InlineError
} from '@shopify/polaris';
import { NoteMinor, RefreshMinor } from '@shopify/polaris-icons';
import { Box } from '@primer/components';
import { Typography } from '@material-ui/core';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../ApiConfig';
import { useDispatch } from 'react-redux';

export default function AppointmentBooking(props: any) {
    const [toSignin, setToSignin] = useState(false);

    const { caseid } = useParams() as {
        caseid: string;
    };
    const dispatch = useDispatch();
    const { appointmentBookLoader } = props;

    const [appoinmentRequestListValue, setAppoinmentRequestListValue] = useState<any[]>([]);

    const [consultationType, setConsultationType] = useState('');
    const [doctorTeamType, setDoctorTeamType] = useState('');
    const [responseTimeType, setResponseTimeType] = useState('');

    const [isBookAppointment, setIsBookAppoinment] = useState(false);
    const [title] = useState('Request Appointment');
    const [cardBody] = useState('Please click on Request Appointment button.    ');

    const [toastSuccessMessage, setToastSuccessMessage] = useState('');
    const [successModalactive, setSuccessModalActive] = useState(false);
    const toggleSuccessModalActive = useCallback(() => setSuccessModalActive((successModalactive) => !successModalactive), []);
    const toastSuccessMarkup = successModalactive ? (
        <Toast content={toastSuccessMessage} onDismiss={toggleSuccessModalActive} />
    ) : null;

    const [toastErrorMessage, setToastErrorMessage] = useState('');
    const [errorModalactive, setErrorModalActive] = useState(false);
    const toggleErrorModalActive = useCallback(() => setErrorModalActive((errorModalactive) => !errorModalactive), []);
    const toastErrorMarkup = errorModalactive ? (
        <Toast content={toastErrorMessage} error onDismiss={toggleErrorModalActive} />
    ) : null;

    const [addPhoneNumber, setPhoneNumber] = useState('');
    const handleAddPhoneNumberChange = useCallback((newValue) => {
        setPhoneNumber(newValue);
    }, []);
    const [addEmailError, setAddEmailError] = useState('');
    const addEmailErrordiv = addEmailError
        ? <TextStyle variation="negative">{addEmailError}</TextStyle>
        : '';

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const bookAppoinmentBody = {
        "caseId": caseid,
        "phoneNumberForPatientCase": addPhoneNumber
    }
    // Create Book Appointment API
    const bookAppoinment = () => { 
        console.log(bookAppoinmentBody);
        setIsBookAppoinment(true);
        appointmentBookLoader(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/book_appointment',
            bookAppoinmentBody, { headers })
            .then((response) => {
                appointmentBookLoader(false);
                setToastSuccessMessage(response.data.message);
                toggleSuccessModalActive();
                getAppoinmentRequestList();
            })
            .catch((error) => {
                appointmentBookLoader(false);
                setToastErrorMessage(error.response.data.message);
                setIsBookAppoinment(false);
                toggleErrorModalActive();
                getAppoinmentRequestList();
            })
    }


    const selectionTypeBody = {
        "caseId": caseid
    };


    //Selection Type GET API
    const getAppoinmentRequestList = () => {
        appointmentBookLoader(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/get_appointment_details_for_case', selectionTypeBody, { headers })
            .then((res) => {
                appointmentBookLoader(false);
                let resData = res.data;
                //let bookData = res.data.appointmentRequestList;
                let consultationValue = (resData.consultationType === "") ? "Not Selected" : resData.consultationType;
                let responseTimeValue = (resData.responseTimeType === "") ? "Not Selected" : resData.responseTimeType;
                let doctorSelect = (resData.doctorTeamType === "") ? "Not Selected" : resData.doctorTeamType;
                let phoneNumber = (resData.phoneNumberForPatientCase === "") ? "" : resData.phoneNumberForPatientCase;
                setConsultationType(consultationValue);
                setResponseTimeType(responseTimeValue);
                setDoctorTeamType(doctorSelect);
                setPhoneNumber(phoneNumber);
                let bookData = res.data.appointmentRequestList;
                console.log("AppoinmentLIST==>", bookData);
                let appoinmentValueList = [];
                for (var i = 0; i < bookData.length; i++) {
                    let description = (bookData[i].name == "" || bookData[i].name == null) ? "No Details Available" : bookData[i].name;
                    let createdDate = (bookData[i].createDate == null) ? "" : (new Date(bookData[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let modifiedDate = (bookData[i].modifiedDate == null) ? "" : (new Date(bookData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let appoinmentStatus = (bookData[i].hasOwnProperty('status')) ? (bookData[i].status === null || bookData[i].status === "") ? "" : bookData[i].status : "";
                    let isActivestatus = (bookData[i].isActive == true) ? "True" : "False";
                    console.log("ACTIVESTATUS==>", isActivestatus);
                    appoinmentValueList.push([
                        description,
                        createdDate,
                        isActivestatus,
                        modifiedDate,
                        appoinmentStatus

                    ]);
                }
                setAppoinmentRequestListValue(appoinmentValueList);
            })
            .catch((error) => {
                appointmentBookLoader(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    useEffect(() => {
        // Do api call here....
        window.scrollTo(0,0);
        getAppoinmentRequestList();
        //dispatch(appoinmentRequestListForCase(selectionTypeBody));
    }, []);

    const selectionOptionMarkup = (
        <Card >
            <Layout>
                <Layout.Section>
                    <Box pt={2} pl={5} pr={5}>
                        <label><h5>Selected Options</h5></label>
                        <Box p={3}>
                            <Stack distribution="fill">
                                <Stack.Item fill>
                                    <Heading>Consultation Type</Heading>
                                    <hr />
                                    <Box mt={2}>
                                        <Banner>
                                            {consultationType}
                                        </Banner>
                                    </Box>
                                </Stack.Item>
                                <Stack.Item fill>
                                    <Heading>Response Timing</Heading>
                                    <hr />
                                    <Box mt={2}>
                                        <Banner>
                                            {responseTimeType}
                                        </Banner>
                                    </Box>
                                </Stack.Item>
                                <Stack.Item fill>
                                    <Heading>Doctor Selection Type</Heading>
                                    <hr />
                                    <Box mt={2}>
                                        <Banner>
                                            {doctorTeamType}
                                        </Banner>
                                    </Box>
                                </Stack.Item>
                            </Stack>
                        </Box>
                    </Box>
                </Layout.Section>
            </Layout>
        </Card >
    )

    const bannerPageBody = (
        <div>
            <Card
                title={title}
            >
                <Card.Section>
                    <Stack distribution="fillEvenly">
                        <Stack.Item fill>
                            <div>
                                <TextField
                                    label="Phone Number"
                                    type="number"
                                    value={addPhoneNumber}
                                    onChange={handleAddPhoneNumberChange}
                                    placeholder="Enter Phone Number"
                                    maxLength={10}
                                    prefix="+91"
                                    id="emailID"
                                />
                                <InlineError message={addEmailErrordiv} fieldID="emailID" />
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <br />
                            <Button primary disabled={isBookAppointment} onClick={bookAppoinment}>Request Appointment</Button>
                        </Stack.Item>
                    </Stack>
                </Card.Section>
            </Card>
        </div>
    )
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const appointmentTableBody = (
        <div>
            <Card >
                <Card.Header title="Existing Appointment Details">
                    <Button icon={RefreshMinor} primary={false} onClick={getAppoinmentRequestList}>Reload Appointment List</Button>
                </Card.Header>
                <Card.Section>
                    {appoinmentRequestListValue && appoinmentRequestListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Description',
                                'Create Date',
                                'Active',
                                'Modified Date',
                                'Status',
                            ]}
                            rows={appoinmentRequestListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Appointment Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    return (
        <Typography>
            {toSignin ? <Redirect to="/signin" /> : null}
            {selectionOptionMarkup}
            <br />
            {bannerPageBody}
            <br />
            {appointmentTableBody}
            {toastSuccessMarkup}
            {toastErrorMarkup}
        </Typography>
    );
}