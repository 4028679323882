import React, { useCallback, useEffect, useState } from 'react';
import {
    Banner,
    Button,
    Caption,
    Card,
    DataTable,
    FormLayout,
    Loading,
    Modal,
    Page,
    Stack,
    TextContainer,
    TextField,
    Thumbnail,
    Subheading,
    TextStyle,
    InlineError,
    ExceptionList,
    Toast,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import { createMuiTheme, IconButton } from "@material-ui/core";

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from "@material-ui/styles";
import { Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addChiefComplaint, deleteFilePreparationDocument, getChiefComplaintList, getQuestionList } from '../../../../redux/effects/ApiCall';
import { AppState } from '../../../../redux/store/Store';

export default function ChiefComplaints() {

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#006FBB',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            text: {
                primary: "#FFFFF",
                secondary: "#006FBB"
            }
        },
        typography: {
            fontSize: 18,
        }
    });
    

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const [toSignin, setToSignin] = useState(false);

    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = <Loading />;

    const [chiefDescription, setChiefDescription] = useState('');
    const handleChiefDescriptionChange = useCallback((value) => {
        setChiefDescription(value);
    }, []);

    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');
    const [imageId, setImageId] = useState('');

    const [patientDetailsId, setPatientDetailsId] = useState('');

    // Start Date
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    // End Date
    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        null,
    );
    const handleEndDateChange = (enddate: Date | null) => {
        setSelectedEndDate(enddate);
    };

    // Image upload Body
    const uploadbody = {
        'typeName': 'patient-image'
    };

    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };

    const [uploadmodalActive, setuploadmodalActive] = useState(false);
    const uploadhandleToggle = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
        setChiefDescription('');
        setSelectedDate(new Date());
        handleEndDateChange(null);
        setImageInfo('');
        setAddEncounterModalSuccess('');
        setChiefError('');
        // getQuestionnaitList();
    }

    const closeUpload = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
    }

    const closeSuccess = () => {
        setAddEncounterModalSuccess('');
        setDeleteChifModalSuccess('');
        setChiefDeleteError('');
    }
    const [addEncounterModalSuccess, setAddEncounterModalSuccess] = useState('');
    const successModalDiv = addEncounterModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addEncounterModalSuccess}</p>
        </Banner>
        : '';
    const [chiefError, setChiefError] = useState('');
    const chiefErrorDiv = chiefError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{chiefError}</p>
        </Banner>
        : '';

    const [deleteChifModalSuccess, setDeleteChifModalSuccess] = useState('');
    const successDelChifModalDiv = deleteChifModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deleteChifModalSuccess}</p>
        </Banner>
        : '';

    const [chiefDescriptionError, setChiefDescriptionError] = useState('');
    const chiefDescriptionErrordiv = chiefDescriptionError
        ? <TextStyle variation="negative">{chiefDescriptionError}</TextStyle>
        : '';

    const [chiefDeleteError, setChiefDeleteError] = useState('');
    const chiefDeleteErrorDiv = chiefDeleteError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{chiefDeleteError}</p>
        </Banner>
        : '';


    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteChifModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    const dispatch = useDispatch();

    // Call Add chief complaint store
    const AddChiefComplaintMessage = useSelector((state: AppState) => state.ccAddVlaue.response.message);
    const AddChiefComplaintStatus = useSelector((state: AppState) => state.ccAddVlaue.response.status);

    // Add Chief complaint success toast
    const [successAddChiefComplaintToastActive, setSuccessAddChiefComplaintToastActive] = useState(false);
    const toggleSuccessAddChiefComplaintActive = () => {
        setSuccessAddChiefComplaintToastActive((successAddChiefComplaintToastActive) => !successAddChiefComplaintToastActive);
        dispatch({ type: "VOTE_DOWN" })
        dispatch(getChiefComplaintList(getChiefComplaintBody));
    }

    const toastAddChiefComplaintSuccessMarkup = successAddChiefComplaintToastActive ? (
        <Toast content={AddChiefComplaintMessage} onDismiss={toggleSuccessAddChiefComplaintActive} duration={1000} />
    ) : null;

    // Add Chief complaint error toast
    const [addChiefComplaintErrorToastActive, setAddChiefComplaintErrorToastActive] = useState(false);
    const handleAddChiefComplaintErrorToastChange = () => {
        setAddChiefComplaintErrorToastActive((addChiefComplaintErrorToastActive) => !addChiefComplaintErrorToastActive);
    }
    const toastAddChiefComplaintErrorMarkup = addChiefComplaintErrorToastActive ? (
        <Toast content={AddChiefComplaintMessage} error onDismiss={handleAddChiefComplaintErrorToastChange} />
    ) : null;

    // Call delete Chief Complaint Store
    const deleteChiefComplaintMessage = useSelector((state: AppState) => state.deleteDocumentValue.response.message);
    const deleteChiefComplaintStatus = useSelector((state: AppState) => state.deleteDocumentValue.response.status);

    // Delete Chief complaint success toast
    const [successDeleteChiefComplaintToastActive, setDeleteAddChiefComplaintToastActive] = useState(false);
    const toggleSuccessDeleteChiefComplaintActive = () => {
        setDeleteAddChiefComplaintToastActive((successDeleteChiefComplaintToastActive) => !successDeleteChiefComplaintToastActive);
        dispatch({ type: "VOTE_DOWN" })
        dispatch(getChiefComplaintList(getChiefComplaintBody));
    }

    const toastDeleteChiefComplaintSuccessMarkup = successDeleteChiefComplaintToastActive ? (
        <Toast content={deleteChiefComplaintMessage} onDismiss={toggleSuccessDeleteChiefComplaintActive} duration={1000}/>
    ) : null;

    // Delete Chief complaint error toast
    const [deleteChiefComplaintErrorToastActive, setDeleteChiefComplaintErrorToastActive] = useState(false);
    const handleDeleteChiefComplaintSuccessToastChange = () => {
        setDeleteChiefComplaintErrorToastActive((deleteChiefComplaintErrorToastActive) => !deleteChiefComplaintErrorToastActive);
    }
    const toastDeleteChiefComplaintErrorMarkup = deleteChiefComplaintErrorToastActive ? (
        <Toast content={deleteChiefComplaintMessage} error onDismiss={handleDeleteChiefComplaintSuccessToastChange} />
    ) : null;

    const body = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "chiefCompliants",
        "description": chiefDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const getChiefComplaintBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "chiefCompliants"
    }

    const CCADDLISTTOAPI = useSelector((state: AppState) => state.ccAddVlaue);
    console.log("SUCCESSSTATUS==>", CCADDLISTTOAPI.response.status);
    console.log("SUCCESSMSG==>", CCADDLISTTOAPI.response.message);

    const DELETEDOCUMENTFROMAPI = useSelector((state: AppState) => state.deleteDocumentValue);

    const ChiefComplaintSubmit = () => {
        if (chiefDescription === "") {
            setChiefDescriptionError("Please add description");
        }
        else {
            setChiefDescriptionError("");
            dispatch(addChiefComplaint(body));
            setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
            setSuccessAddChiefComplaintToastActive((successAddChiefComplaintToastActive) => !successAddChiefComplaintToastActive);
            setAddChiefComplaintErrorToastActive((addChiefComplaintErrorToastActive) => !addChiefComplaintErrorToastActive);
        }
    }

    // Delete body....
    const deleteChiefComplaintBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeleteChiefComplaintSubmit = () => {
        dispatch(deleteFilePreparationDocument(deleteChiefComplaintBody));
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteAddChiefComplaintToastActive((successDeleteChiefComplaintToastActive) => !successDeleteChiefComplaintToastActive);
        setDeleteChiefComplaintErrorToastActive((deleteChiefComplaintErrorToastActive) => !deleteChiefComplaintErrorToastActive);
    }

    // Delete popup open ........   
    function deleteChiefComplaints(patientId: any) {
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteChifModalSuccess('');
        setPatientDetailsId(patientId);
    }

    // QUESTION LIST
    const QUESTIONLISTFROMAPI = useSelector((state: AppState) => state.questionValue);
    console.log("QUESTIONLISTFROMAPIREDUX==>", QUESTIONLISTFROMAPI);
    const questionListValues = QUESTIONLISTFROMAPI.questionValue;
    const questionListConfig: any = [];
    for (var i = 0; i < questionListValues.length; i++) {
        let questionName = questionListValues[i].questionnaireType.displayValue;
        let supportingDocuments = questionListValues[i].templateUrl;
        questionListConfig.push([
            questionName,
            <Button onClick={() => {
                setDocViwerActive(true);
                setImageInfo(supportingDocuments);
            }}>View Question</Button>,
        ]);
    }
    const questionrows: any[] = questionListConfig;

    useEffect(() => {
        dispatch(getChiefComplaintList(getChiefComplaintBody));
        dispatch(getQuestionList(getChiefComplaintBody));
    }, []);

    // CHIEF COMPLAINT LIST
    const CCLISTFROMAPI = useSelector((state: AppState) => state.ccValue);
    console.log("CCLISTFROMAPIREDUX==>", CCLISTFROMAPI);
    const chiefcomplaintValues = CCLISTFROMAPI.ccValue;
    const ccListConfig: any = [];
    for (var i = 0; i < chiefcomplaintValues.length; i++) {
        let selectedId = chiefcomplaintValues[i].id;
        let description = (chiefcomplaintValues[i].description == null) ? "" : chiefcomplaintValues[i].description;
        let startDate = (chiefcomplaintValues[i].startDate == null) ? "" : (new Date(chiefcomplaintValues[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
        let endDate = (chiefcomplaintValues[i].endDate == null) ? "" : (new Date(chiefcomplaintValues[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
        let supportingDocuments = (chiefcomplaintValues[i].patientDocument == null) ? '' : chiefcomplaintValues[i].patientDocument.document;
        ccListConfig.push([
            <Button icon={NoteMinor} size="large" onClick={() => {
                setDocViwerActive(true);
                setImageInfo(supportingDocuments);
            }}></Button>,
            description,
            startDate,
            endDate,
            <Button icon={DeleteMinor} size="slim" onClick={() => deleteChiefComplaints(selectedId)}></Button>
        ]);
    }
    const rows: any[] = ccListConfig;

    const questionBody = (
        <div>
            <Card>
                <Card.Header title="Questionnaire"></Card.Header>
                <Card.Section>
                    {questionrows && questionrows.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Question Name',
                                'View'
                            ]}
                            rows={questionrows}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Question is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    const tableMarkup = (
        <Card>
            <Card.Header title="Documents">
                <Button primary={true} onClick={uploadhandleToggle}>Upload Data</Button>
            </Card.Header>
            <Card.Section>
                {rows && rows.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Document',
                            'Description',
                            'Start Date',
                            'End Date',
                            'Delete'
                        ]}
                        rows={rows}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Documents is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    // Delete modal section ....
    const deletechiefComponent = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteChifModalChange}
            title={"Delete Chief Complaints"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteChiefComplaintSubmit,
            }}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Chief Complaints ?
                    </Subheading>
                    <div>{successDelChifModalDiv}</div>
                    <div>{chiefDeleteErrorDiv}</div>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const uploadChiefComplaintDetails = (
        <Modal
            large
            open={uploadmodalActive}
            onClose={closeUpload}
            title={"Upload Data of Chief Complaints Details"}
            primaryAction={{
                content: "Submit",
                onAction: ChiefComplaintSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Mention your health problem"
                                    type="text"
                                    value={chiefDescription}
                                    onChange={handleChiefDescriptionChange}
                                    multiline={4}
                                    placeholder="Please enter chief complaint description"
                                    id="descriptionID"
                                />
                                <InlineError message={chiefDescriptionErrordiv} fieldID="descriptionID" />
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload supporting document</p>
                                <Box style={{ textAlign: "center", border: "1px #006FBB solid", borderRadius: "3px" }} p={1}>
                                    <Stack distribution="fill">
                                        <Stack>
                                            <Uploader
                                                action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                                draggable
                                                data={uploadbody}
                                                name="document"
                                                listType="text"
                                                onSuccess={(response: Object,) => {
                                                    let res = JSON.stringify(response);
                                                    setImageId(JSON.parse(res).image.id);
                                                    setImageInfo(JSON.parse(res).image.document);
                                                }}
                                                id='imageUpload'
                                            >
                                                <button style={styles} type="button">
                                                    {uploading && <Loader backdrop center />}
                                                    {imageInfo ? (
                                                        <img src={imageInfo} alt='' width="100%" height="100%" />
                                                    ) : (
                                                        <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                                    )}
                                                </button>
                                            </Uploader>
                                        </Stack>
                                    </Stack>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Please upload profile picture of 200px (Width) X 200px (Height)</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p>Start date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p>End date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={enddate => handleEndDateChange(enddate)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successModalDiv}</div>
                        <div>{chiefErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    return (
        <Page title="Presenting Complaint(s)" fullWidth>
            {toSignin ? <Redirect to="/signin" /> : null}
            { CCLISTFROMAPI.loading ? loadingMarkup : ''}
            { CCADDLISTTOAPI.loading ? loadingMarkup : ''}
            { DELETEDOCUMENTFROMAPI.loading ? loadingMarkup : ''}
            {questionBody}
            {tableMarkup}
            {docViewerComponent}
            {deletechiefComponent}
            {uploadChiefComplaintDetails}
            {AddChiefComplaintStatus! === 1 ? toastAddChiefComplaintSuccessMarkup : ""}
            {AddChiefComplaintStatus! === 0 ? toastAddChiefComplaintErrorMarkup : ""}
            {deleteChiefComplaintStatus! === 1 ? toastDeleteChiefComplaintSuccessMarkup : ""}
            {(deleteChiefComplaintStatus! === 0 || deleteChiefComplaintStatus! === -1 || deleteChiefComplaintStatus! === -2) ? toastDeleteChiefComplaintErrorMarkup : ""}
        </Page>
    );
}
