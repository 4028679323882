import * as React from 'react';
import { useEffect, useCallback, useState } from 'react';
import {
    Card,
    Page,
    Banner,
    ExceptionList,
    DataTable,
    Button,
    FormLayout,
    Modal,
    TextField,
    InlineError,
    TextStyle,
    Stack,
    SkeletonPage,
    TextContainer,
    SkeletonDisplayText,
    SkeletonBodyText,
} from '@shopify/polaris';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import Pagination from '@material-ui/lab/Pagination';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import baseUrl from '../../ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientTeamList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { useHistory } from 'react-router-dom';
import AdminLoader from './AdminLoader';
import { Box } from '@primer/components';

export default function PatientTeam() {
    const dispatch = useDispatch();
    const history = useHistory();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );
    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const [page, setPage] = useState(1);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log("PAGECOUNT==>", value);
        setPage(value);
        dispatch(getPatientTeamList(value));

    };

    const [pageCount, setPageCount] = useState();
    const [toSignin, setToSignin] = useState(false);
    const [email, setEmail] = useState('');
    const handleEmailChange = useCallback((newValue) => {
        setEmail(newValue);
    }, []);
    const [firstName, setFirstName] = useState('');
    const handleFirstNameChange = useCallback((newValue) => {
        setFirstName(newValue);
    }, []);
    const [lastName, setLastName] = useState('');
    const handleLastNameChange = useCallback((newValue) => {
        setLastName(newValue);
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = <AdminLoader/>;

    const closeerror = () => {
        setAddUserError('')
    }
    const [emailError, setEmailError] = useState('');
    const emailErrordiv = emailError
        ? <TextStyle variation="negative">{emailError}</TextStyle>
        : '';

    const [firstNameError, setFirstNameError] = useState('');
    const firstNameErrordiv = firstNameError
        ? <TextStyle variation="negative">{firstNameError}</TextStyle>
        : '';
    const [lastNameError, setLastNameError] = useState('');
    const lastNameErrordiv = lastNameError
        ? <TextStyle variation="negative">{lastNameError}</TextStyle>
        : '';
    const [addUserError, setAddUserError] = useState('');
    const addUserErrorDiv = addUserError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{addUserError}</p>
        </Banner>
        : '';


    const [addUserSuccess, setAddUserSuccess] = useState('');
    const closeSuccess = () => {
        setAddUserSuccess('');
    }

    const addUserSuccessDiv = addUserSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{addUserSuccess}</p>
        </Banner>
        : '';


    const [uploadmodalActive, setuploadmodalActive] = useState(false);
    const uploadhandleToggle = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
        setEmail("");
        setEmailError("");
        setFirstName("");
        setFirstNameError("");
        setLastName("");
        setLastNameError("");
        setAddUserSuccess("");
        setAddUserError("");
    }



    //child to parent value send section.....
    const addPatientTeamBody = {
        "email": email.toLowerCase(),
        "first_name": firstName,
        "last_name": lastName
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    function patientTeamDetails(patientTeamCurrentPage: any, patientDetailsID: any) {
        history.push(`/patientteamdetails/${patientTeamCurrentPage}/${patientDetailsID}`);
    }

    const addUser = () => {
        console.log("Add User Body===", addPatientTeamBody);
        if (email === '' && firstName === '' && lastName === '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("Please enter first name");
            setLastNameError("Please enter last name")
        }
        else if ((new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.toLowerCase())) === false && (new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase())) === false) {
            setEmailError('Please enter valid email id or phone number');
        }
        else if (email === '' && firstName === '' && lastName != '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("Please enter first name");
            setLastNameError("")
        }
        else if (email === '' && firstName != '' && lastName === '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("");
            setLastNameError("Please enter last name")
        }
        else if (email === '' && firstName != '' && lastName != '') {
            setEmailError("Please enter email or phone number");
            setFirstNameError("");
            setLastNameError("")
        }
        else if (email != '' && firstName === '' && lastName === '') {
            setEmailError("");
            setFirstNameError("Please enter first name");
            setLastNameError("Please enter last name")
        }
        else if (email != '' && firstName === '' && lastName != '') {
            setEmailError("");
            setFirstNameError("Please enter first name");
            setLastNameError("")
        }
        else if (email != '' && firstName != '' && lastName === '') {
            setEmailError("");
            setFirstNameError("");
            setLastNameError("Please enter last name")
        }
        else {
            axios.post(baseUrl.baseUrl + '/adminapp/api/adminActions/add_patient_user_account',
                addPatientTeamBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
                        setAddUserSuccess(result.data.message);
                        dispatch(getPatientTeamList(page));
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setAddUserError(error.response.data.message);
                })
        }
    }

    useEffect(() => {
        dispatch(getPatientTeamList(page));
    }, [dispatch]);

    // Reload Appointment API
    const reloadPatientList = () => {
        dispatch(getPatientTeamList(page));;
    };

    // get Patient Team List api store value
    const patientTeamList = useSelector((state: AppState) => state.allPatientTeamListValue);
    const patientTeamPageCount = useSelector((state: AppState) => state.allPatientTeamListValue.patientListPageCount);

    const patientTeamCurrentPage = patientTeamList.patientTeamCurrentPageCount;
    console.log("CURRENTPAGECOUNTREDUX==>", patientTeamCurrentPage)

    const adminPatientTeamListValues = patientTeamList.allPatientTeamListValue;
    let allPatientValueList: any = [];
    for (var i = 0; i < adminPatientTeamListValues.length; i++) {
        let patientDetailsID = adminPatientTeamListValues[i].profileId;
        let firstName = (adminPatientTeamListValues[i].firstname === null) ? "" : adminPatientTeamListValues[i].firstname;
        let lastName = (adminPatientTeamListValues[i].lastname === null) ? "" : adminPatientTeamListValues[i].lastname;
        let email = (adminPatientTeamListValues[i].email === null) ? "" : adminPatientTeamListValues[i].email;
        let username = (adminPatientTeamListValues[i].phone === null) ? "" : adminPatientTeamListValues[i].phone;
        allPatientValueList.push([
            <Button icon={ViewMinor} size="slim" onClick={() => patientTeamDetails(patientTeamCurrentPage, patientDetailsID)}>Details</Button>,
            patientDetailsID,
            firstName,
            lastName,
            email,
            username
        ]);
    }
    const allPatientTeamListValue: any[] = allPatientValueList;

    const patirntTeamTable = (
        <div>
            <Card >
                <Card.Header>
                    <ThemeProvider theme={theme}>
                        <Stack>
                            <Typography>Page: {page}</Typography>
                            <CssBaseline />
                            <Pagination color="primary" page={page} count={Number(patientTeamPageCount)} onChange={handleChange} />
                        </Stack>
                    </ThemeProvider>
                    <Button primary={false} onClick={reloadPatientList}>Reload</Button>
                    <Button primary={true} onClick={uploadhandleToggle}>Add User</Button>

                </Card.Header>
                <Card.Section>
                    {allPatientTeamListValue && allPatientTeamListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'View',
                                'User ID',
                                'First Name',
                                'Last Name',
                                'Email',
                                'Phone Number'
                            ]}
                            rows={allPatientTeamListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Documents is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    );

    const loaderskeleton = (
        <Page fullWidth title="Patient Team List">
            <Card >
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <SkeletonDisplayText size="small" />
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            </Card>
        </Page>
    )

    const addUserMarkup = (
        <Modal
            open={uploadmodalActive}
            onClose={uploadhandleToggle}
            title={"Add User"}
            primaryAction={{
                content: "Submit",
                onAction: addUser,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout.Group condensed>
                        <div>
                            <TextField
                                label="Email / Phone Number"
                                value={email}
                                onChange={handleEmailChange}
                                placeholder="Please email or phone number"
                                id="emailPhoneID"
                            />
                            <InlineError message={emailErrordiv} fieldID="emailPhoneID" />
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group condensed>
                        <div>
                            <TextField
                                label="First Name"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                placeholder="Enter First Name"
                                id="firstNameIdD"
                            />
                            <InlineError message={firstNameErrordiv} fieldID="firstNameIdD" />
                        </div>
                        <div>
                            <TextField
                                label="Last Name"
                                value={lastName}
                                onChange={handleLastNameChange}
                                placeholder="Please Last Name"
                                id="lastNameId"
                            />
                            <InlineError message={lastNameErrordiv} fieldID="lastNameId" />
                        </div>
                    </FormLayout.Group>
                    <FormLayout.Group>{addUserSuccessDiv}</FormLayout.Group>
                    <FormLayout.Group>{addUserErrorDiv}</FormLayout.Group>
                </Page>
            </Modal.Section>
        </Modal>
    );
    return (
        // <div>
        //     {loadingMarkup}
        //     <Page fullWidth title={`Patient Team List`}>
        //         {patirntTeamTable}
        //         {addUserMarkup}
        //     </Page>
        // </div>

        <div>
            {patientTeamList.loading ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> : <Page fullWidth title="Patient Team List">
                {
                    patientTeamList.loading ? "" :
                        <ThemeProvider theme={theme}>
                            {patirntTeamTable}
                            {addUserMarkup}
                        </ThemeProvider>
                }
            </Page>}
        </div>
    );
}