import { combineReducers } from 'redux';
import {
     getDoctorReducer, getOnboardingDoctorRequestReducer, getOnboardingDoctorRejectReducer,
     getOnboardingDoctorIncompleteReducer, getDoctorListReducer, getPatientReducer, getTaskListReducer,
     getQuestionListReducer, getCaseReducer, getQuestionReducer, getCCReducer, addCCReducer, deleteDocumentReducer, getAllTaskListReducer, getAllCaseReducer, getAllAppointmentListReducer,getpatientTeamListReducer, getallPymentListReducer, getAdminPatientQueryListReducer, getAdminDoctorQueryListReducer, getAllInactiveTaskListReducer, getActiveCorporateListReducer, getInactiveCorporateListReducer, getActiveCorporatePatientListReducer, getInactiveCorporatePatientListReducer,
} from './Reducer';

const Reducer = combineReducers({
     doctorValue: getDoctorReducer, 
     doctorRequestValue: getOnboardingDoctorRequestReducer,
     doctorRejectValue: getOnboardingDoctorRejectReducer, 
     doctorIncompleteValue: getOnboardingDoctorIncompleteReducer,
     doctorListValue: getDoctorListReducer, 
     patientValue: getPatientReducer, 
     taskListValue: getTaskListReducer,
     alltaskListValue: getAllTaskListReducer, 
     allinactivetasklist: getAllInactiveTaskListReducer,
     questionListValue: getQuestionListReducer, 
     caseValue: getCaseReducer,
     allcaseValue: getAllCaseReducer,
     questionValue: getQuestionReducer,
     ccValue: getCCReducer,
     ccAddVlaue: addCCReducer,
     deleteDocumentValue: deleteDocumentReducer,
     allAppointmentListValue: getAllAppointmentListReducer,
     allPatientTeamListValue: getpatientTeamListReducer,
     allPaymentListValue: getallPymentListReducer,
     adminPatientQueryListValue: getAdminPatientQueryListReducer,
     adminDoctorQueryListValue: getAdminDoctorQueryListReducer,
     activeCorporateListValue: getActiveCorporateListReducer,
     inactiveCorporateListValue: getInactiveCorporateListReducer,
     activeCorporatePatientValue: getActiveCorporatePatientListReducer,
     inactiveCorporatePatientValue: getInactiveCorporatePatientListReducer 
});

export default Reducer;
