import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    Page,
    Stack,
    Checkbox,
    Loading,
    FormLayout,
    List,
    Card,
} from '@shopify/polaris';
import AdminLoader from '../../AdminLoader';

import { useHistory, useParams } from 'react-router-dom';
import { SelectPicker } from 'rsuite';
import axios from 'axios';
import { Box } from '@primer/components';
import { createMuiTheme, makeStyles, Tabs } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import DemographicDetails from './DemographicDetails';
import PresentingComplaints from './ChiefComplaints';
import IdeasConcernsExpectations from './IdeasConcernsExpectations';
import ReviewOfSystems from './SystemsReview';
import MedicationPrescriptions from './MedicationHistory';
import PastHistory from './PastHistory';
import Immunization from './Immunization';
import FamilyHistory from './FamilyHistory';
import NutritionalHistory from './NutritionalHistory';
import PersonalHistory from './PersonalHistory';
import HealthMaintenanceHistory from './HealthMaintenanceHistory';
import RiskFactors from './RiskFactors';
import Diagnosis from './Diagnosis';
import MedicalSummary from './MedicalSummary';
import ProblemList from './ProblemListAndTreatmentPlan';
import TreatmentPlan from './TreatmentPlan';
import Importeddata from './ImportedData';
import { AppState } from '../../../../redux/store/Store';
import { CaseList } from '../../../../redux/interfaces/Model';
import { getPatientCaseList } from '../../../../redux/effects/ApiCall';
import baseUrl from '../../../../ApiConfig';

export default function Preview() {

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#BBE5B3',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main: '#10ff14',
            }
        },
        typography: {
            fontSize: 20,
        },
    });
    const history = useHistory();
    const { patientid } = useParams() as {
        patientid: string;
    };
    const useStyles = makeStyles(() => ({
        root: {
            width: '260px',
            backgroundColor: '#047ab3',
            borderRadius: '5px',
        },
        tabs: {
            textAlign: "start",
        },
        tab: {
            textAlign: "center",
            textTransform: "none",
            fontSize: "1.5rem",
        },
        rightSection: {
            width: 'auto',
        }
    }));

    const classes = useStyles();
    const { caseid } = useParams() as {
        caseid: string;
    };

    const [isLoading, setIsLoading] = useState(false);

    const [steps, setSteps] = useState<any[]>([]);
    const [found, setFound] = useState<any[]>([]);

    const [checked, setChecked] = useState(false);
    const handleChange = useCallback((newChecked) => setChecked(newChecked), []);

    const [templateListValues, setTemplateListValues] = useState<any[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const handleTemplateSelectChange = useCallback((value) => setSelectedTemplate(value), []);

    const [demographicsLoader, setDemographicsLoader] = useState(false);
    // addHealthRecordLoader(demographicsLoader);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const selectionTypeBody = {
        "stepId": 3,
        "caseId": caseid,
    };

    // Selection Type GET API
    const previewApi = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/secondopinionapp/api/patientcaseui/sections_by_step_id_for_preview', selectionTypeBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setSteps(res.data.SecondOpinionUiSection);
                var foundvalue = res.data.SecondOpinionUiSection.map(function (el: { name: any; }) { return el.name; });
                setFound(foundvalue);
                console.log("array", res.data.SecondOpinionUiSection)
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    const templateListApicall = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/secondopinionapp/api/patientcaseui/sections_template_list', { headers })
            .then((res) => {
                setIsLoading(false);
                setTemplateListValues(res.data.SecondOpinionUiTemplate);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }

    const dispatch = useDispatch();
    // Call Store
    const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
    const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, ['caseId', Number(caseid)])[0];
    const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);

    const selectTemplate = (selectedTemplate: any) => {
        axios.post(baseUrl.baseUrl + '/secondopinionapp/api/patientcaseui/sections_by_step_id_for_preview', { "stepId": 3, "caseId": caseid, "templateId": selectedTemplate }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSteps(res.data.SecondOpinionUiSection);
                var foundvalue = res.data.SecondOpinionUiSection.map(function (el: { name: any; }) { return el.name; });
                setFound(foundvalue);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        previewApi();
        dispatch(getPatientCaseList(patientid));
        templateListApicall();
    }, [dispatch]);

    const viewPreviewModalMarkup = (
        <Card>
            <Box p={3}>
                <Stack>
                    <Stack.Item fill>
                        <div>
                            <p style={{ marginBottom: "5px" }}>Select Template</p>
                            <SelectPicker
                                block
                                onChange={handleTemplateSelectChange}
                                data={templateListValues}
                                value={selectedTemplate}
                                searchable={true}
                                onSelect={selectTemplate}
                                placeholder="Please Select a Template"
                                menuStyle={{ zIndex: 200, color: "rgb(8, 78, 138) !important" }}
                            />
                        </div>
                    </Stack.Item>
                    <Stack.Item >
                        <div>&nbsp;</div>
                        <Checkbox
                            label="View Empty Sections"
                            checked={checked}
                            onChange={handleChange}
                        />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Stack.Item>
                        <Box pt={2}>
                            <ThemeProvider theme={defaultMaterialTheme}>
                                <div className={classes.root}>
                                    <Tabs>
                                        <Box pt={1}>
                                            <FormLayout>
                                                {steps.map((consultation) => (
                                                    <List>{consultation.displayValue}</List>
                                                ))}
                                            </FormLayout>
                                        </Box>
                                    </Tabs>
                                </div>
                            </ThemeProvider>
                        </Box>
                    </Stack.Item>
                    <Stack.Item fill>
                        <Box pt={2}>
                            <div className={classes.rightSection}>
                                {found.length > 0 ? (<Stack vertical>
                                    {found.includes("demographics") ? (<Stack.Item>
                                        <DemographicDetails demographicsLoader={setDemographicsLoader} />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("chiefCompliants") ? (<Stack.Item>
                                        <PresentingComplaints />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("ideasConcernsandExpectations") ? (<Stack.Item>
                                        <IdeasConcernsExpectations />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("systemsReview") ? (<Stack.Item>
                                        <ReviewOfSystems />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("pastHistory") ? (<Stack.Item>
                                        <PastHistory />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("medication/DrugHistory") ? (<Stack.Item>
                                        <MedicationPrescriptions />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("immunization") ? (<Stack.Item>
                                        <Immunization />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("familyHistory") ? (<Stack.Item>
                                        <FamilyHistory />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("nutritionalHistory") ? (<Stack.Item>
                                        <NutritionalHistory />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("personalandSocialHistory") ? (<Stack.Item>
                                        <PersonalHistory />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("healthMaintenanceHistory") ? (<Stack.Item>
                                        <HealthMaintenanceHistory />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("riskFactors") ? (<Stack.Item>
                                        <RiskFactors />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("diagnosis") ? (<Stack.Item>
                                        <Diagnosis />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("medicalSummary") ? (<Stack.Item>
                                        <MedicalSummary />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("problemListandTreatmentPlan") ? (<Stack.Item>
                                        <ProblemList />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("treatmentPlan") ? (<Stack.Item>
                                        <TreatmentPlan />
                                    </Stack.Item>) : ("")
                                    }
                                    {found.includes("importedData") ? (<Stack.Item>
                                        <Importeddata />
                                    </Stack.Item>) : ("")
                                    }
                                </Stack>) : ("Template not found")}

                            </div>
                        </Box>
                    </Stack.Item>
                </Stack>
            </Box>
        </Card>

    )

    return (
        <div>
            {isLoading === true || demographicsLoader === true ? <AdminLoader /> : null}
            <Page fullWidth
                title="Preview Section"
                subtitle={`${(caseDetails === undefined) ? "" : "For " + casehistoryname + "'s ( " + (caseDetails.whyWant2ndOpinion) + " )"}`}
                breadcrumbs={[{ content: 'previous section', onAction: () => { history.goBack() } }]}>
                {viewPreviewModalMarkup}
            </Page>
        </div>

    );
}