import React, { useCallback, useState, useEffect } from 'react';
import {
    Card,
    Page,
    Modal,
    FormLayout,
    Banner,
    DataTable,
    ExceptionList,
    Button,
    TextContainer,
    Subheading
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme, IconButton } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import { Icon, SelectPicker, DatePicker } from 'rsuite';
import { DeleteMinor, NoteMinor, RefreshMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../ApiConfig';
import { useParams } from 'react-router-dom';
const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#006FBB',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        }
    },
    typography: {
        fontSize: 18,
    }
});


export default function WorkSchedule(props: any) {
    const { Loader } = props;

    const [isLoading, setIsLoading] = useState(false);
    Loader(isLoading);

    const [scheduleId, setScheduleId] = useState('');

    const [error, setError] = useState('');
    const [addWorkScheduleModalActive, setaddWorkScheduleModalActive] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => { setSelectedDate(date); };
    const [selectedStartTime, setSelectedStartTime] = React.useState<Date | null>(
        new Date(),
    );
    const doctorID = localStorage.getItem("doctorID");
    const { id } = useParams() as {
        id: string;
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(new Date());
    const handleEndDateChange = (enddate: Date | null) => { setSelectedEndDate(enddate) };

    const handleStartTimeChange = (time: Date) => {
        setSelectedStartTime(time);
    };

    const [selectedEndTime, setSelectedEndTime] = React.useState<Date | null>(new Date());

    const handleEndTimeChange = (time: Date) => {
        setSelectedEndTime(time);
    };

    const [weekList, setWeekValues] = useState([]);
    const [selected1, setSelected1] = useState("");
    const handleSelectnewChange = useCallback((value) => setSelected1(value), []);

    const closeSuccess = () => {
        setscheduleSubmitAPIRes('');
        setscheduleSubmitAPIResError('');
    }
    const [scheduleSubmitAPIRes, setscheduleSubmitAPIRes] = useState('');
    const successDiv = scheduleSubmitAPIRes
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{scheduleSubmitAPIRes}</p>
        </Banner>
        : '';

    const [scheduleError, setscheduleSubmitAPIResError] = useState('');
    const scheduleErrordiv = scheduleError
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{scheduleError}</p>
        </Banner>
        : '';


    // popup close action...........
    const closeSchedule = () => {
        setaddWorkScheduleModalActive((addWorkScheduleModalActive) => !addWorkScheduleModalActive);
    }

    //popup open action..............

    const handleAddWorkScheduleToggle = () => {
        setaddWorkScheduleModalActive((addWorkScheduleModalActive) => !addWorkScheduleModalActive);
        setSelected1("");
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setSelectedStartTime(new Date());
        setSelectedEndTime(null);
    }

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteIdeaConcernModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);
    const [deletePastHistoryModalSuccess, setDeletePastHistoryModalSuccess] = useState('');
    const successPastHistoryModalDiv = deletePastHistoryModalSuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{deletePastHistoryModalSuccess}</p>
        </Banner>
        : '';
    const [deletePastHistoryModalError, setDeletePastHistoryModalError] = useState('');
    const errorPastHistoryModalDiv = deletePastHistoryModalError
        ? <Banner
            onDismiss={closeSuccess}
            status="critical">
            <p>{deletePastHistoryModalError}</p>
        </Banner>
        : '';

    const deleteIdeaConcernData = (response: any) => {
        setScheduleId(response);
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeletePastHistoryModalSuccess('');
        setDeletePastHistoryModalError("");
    }


    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }
    const [workScheduleList, setWorkScheduleList] = useState<any[]>([]);
    const getScheduleBody = {
        "doctorId": id,
    }
    const getScheduleList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/workSchedule/get_workScheduleList', getScheduleBody, { headers }).then((res) => {
            setIsLoading(false);
            let response = res.data.workSchedule;
            let scheduleList = [];
            for (var i = 0; i < response.length; i++) {
                let tableScheduleId = response[i].id === "" ? "" : response[i].id;
                let dayOfTheWeek = response[i].dayOfTheWeek === "" ? "" : response[i].dayOfTheWeek.label;
                let startTime = response[i].startTime === null ? '' : timeConvert(response[i].startTime);
                let endTime = response[i].endTime === null ? '' : timeConvert(response[i].endTime);
                let startDateOfValidity = response[i].startDateOfValidity === null ? '' : (new Date(response[i].startDateOfValidity)).toLocaleDateString('en-US', DATE_OPTIONS);
                let endDateOfValidity = response[i].endDateOfValidity === null ? '' : (new Date(response[i].endDateOfValidity)).toLocaleDateString('en-US', DATE_OPTIONS);
                scheduleList.push([
                    dayOfTheWeek,
                    startTime,
                    endTime,
                    startDateOfValidity,
                    endDateOfValidity,
                    <Button icon={DeleteMinor} size="slim" onClick={() => deleteIdeaConcernData(tableScheduleId)}></Button>
                ]);
            }

            setWorkScheduleList(scheduleList);
        })
            .catch((error) => {
                setIsLoading(false);

                setError(error);
            })
    }

    const timeConvert = (time: string) => {
        let hours: any = ((Number(time.split(":")[0]) + 11) % 12 + 1);
        let suffix = Number(time.split(":")[0]) >= 12 ? "PM" : "AM";
        hours = ((hours + 11) % 12 + 1);
        hours = hours.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        hours = hours + ":" + time.split(":")[1] + " " + suffix;
        return hours;
    }

    const weeklistApicall = () => {
        setIsLoading(true);
        axios.get(baseUrl.baseUrl + '/appointmentapp/api/workSchedule/weekDayList',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            })
            .then((res) => {
                setIsLoading(false);
                setWeekValues(res.data.weekDays);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("error record====" + error);
            })
    }
    // Add schedule submit Action.....
    const scheduleBody = {
        "doctorId": id,
        "weekDayId": String(selected1),
        "startTime": selectedStartTime?.toLocaleTimeString(),
        "endTime": selectedEndTime?.toLocaleTimeString(),
        "startDateOfValidity": selectedDate?.toISOString().split("T")[0],
        "endDateOfValidity": selectedEndDate?.toISOString().split("T")[0]
    };
    // Add schedule api submit Action.....
    const SubmitScheduleDetails = () => {
        console.log("BODY===>", scheduleBody);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/workSchedule/add_workSchedule',
            scheduleBody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setaddWorkScheduleModalActive((uploadmodalActive) => !uploadmodalActive);
                    getScheduleList();
                }
            })
            .catch((error) => {
                console.log(error.response);
                setIsLoading(false);
            })

    }
    // Delete body....
    const deleteScheduleBody = {
        "workScheduleId": scheduleId
    }
    const DeleteSchedule = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/workSchedule/delete_workSchedule',
            deleteScheduleBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeletePastHistoryModalError("");
                setDeletePastHistoryModalSuccess(res.data.message);
                getScheduleList();
                setDeleteModalActive((deleteModalActive) => !deleteModalActive);
            })

            .catch((error) => {
                setIsLoading(false);
                setDeletePastHistoryModalSuccess("")
                setDeletePastHistoryModalError(error.response.data.message);
                console.log("error record====" + error);
            })
    }
    useEffect(() => {
        getScheduleList();
        weeklistApicall();
    }, []);

    // ModalMarkUp-1
    const addWorkSchedulemodalMarkup = (
        <Modal
            large
            open={addWorkScheduleModalActive}
            onClose={closeSchedule}
            title={"Add Work Schedule"} //"Add Schedule"
            primaryAction={{
                content: 'Submit',
                onAction: SubmitScheduleDetails,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginTop: "-2px" }}>Schedule Day</p>
                                <SelectPicker
                                    block
                                    onChange={handleSelectnewChange}
                                    searchable={false}
                                    data={weekList}
                                    value={selected1}
                                    menuStyle={{ zIndex: 1000, color: "rgb(76, 2, 2) !important" }}
                                />
                            </div>
                            <div>
                                <p>Start Time</p>
                                <DatePicker
                                    format="HH:mm"
                                    ranges={[]}
                                    Value={selectedStartTime}
                                    onChange={date => date && handleStartTimeChange(date)}
                                    hideMinutes={minute => minute % 15 !== 0}
                                    block
                                />
                            </div>
                            <div>
                                <p>End Time</p>
                                <DatePicker
                                    format="HH:mm"
                                    ranges={[]}
                                    Value={selectedEndTime}
                                    onChange={date => date && handleEndTimeChange(date)}
                                    hideMinutes={minute => minute % 15 !== 0}
                                    block
                                />
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date of Validity</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            focused
                                            format="yyyy-MM-dd"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date of Validity</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            focused
                                            format="yyyy-MM-dd"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            style={{ border: "0.1rem solid rgb(222 54 24 / 60%)", borderRadius: "6px" }}
                                            onChange={enddate => handleEndDateChange(enddate)}
                                            size="small"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>
                            {successDiv}
                            {scheduleErrordiv}
                        </div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    const scheduleDetails = (
        <div>
            <Card.Header title="Schedule List">
                <Button primary={false} onClick={getScheduleList}>Reload</Button>
                <Button primary={true} onClick={handleAddWorkScheduleToggle}>Add Schedule</Button>
            </Card.Header>
            <Card.Section>
                {workScheduleList && workScheduleList.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Schedule Day',
                            'Start Time',
                            'End Time',
                            'Start Date of Validity',
                            'End Date of Validity',
                            'Delete'
                        ]}
                        rows={workScheduleList}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Work Schedule is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </div>
    )
    const deleteSchedule = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteIdeaConcernModalChange}
            title={"Delete Schedule"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteSchedule,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteIdeaConcernModalChange,
                },
            ]}
            footer={successPastHistoryModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Schedule?
                    </Subheading>
                </TextContainer>
                <div>{errorPastHistoryModalDiv}</div>
            </Modal.Section>
        </Modal>
    );

    return (
        <div >
            {addWorkSchedulemodalMarkup}
            {scheduleDetails}
            {deleteSchedule}
        </div>
    );
}
