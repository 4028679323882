import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, DataTable, ExceptionList, Page } from '@shopify/polaris';
import axios from 'axios';
import { NoteMinor } from '@shopify/polaris-icons';
import Url from '../../../ApiConfig';

export default function InactiveMeeting(props: any) {

    const [toSignin, setToSignin] = useState(false);

    const { inactivemeetingLoader } = props;

    const [meetingListValue, setMeetingListValue] = useState<any[]>([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };


    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Meeting List Body
    const meetingListBody = {
        "type": false
    };
    // Meeting List GET API
    const getMeetingList = () => {
        inactivemeetingLoader(true);
        axios.post(Url.baseUrl + '/adminapp/api/meeting/inactive_meeting_list', meetingListBody, { headers })
            .then((res) => {
                inactivemeetingLoader(false);
                let meetingData = res.data.meetingList;
                console.log("AppoinmentLIST==>", meetingData);
                let meetingValueList = [];
                for (var i = 0; i < meetingData.length; i++) {
                    let displayName = (meetingData[i].hasOwnProperty('displayName') ? (meetingData[i].displayName === "" || meetingData[i].displayName === null) ? "No Details Available" : meetingData[i].displayName : "");
                    let meetingId = (meetingData[i].hasOwnProperty('id') ? meetingData[i].id === null ? "" : meetingData[i].id : "");
                    let patientCaseId = (meetingData[i].hasOwnProperty('patientCase') ? (meetingData[i].patientCase.id === null && meetingData[i].patientCase.id === "") ? "" : meetingData[i].patientCase.id : "");
                    //let meetingURL = (meetingData[i].hasOwnProperty('meetingUrl') ? (meetingData[i].meetingUrl === "" || meetingData[i].meetingUrl == null) ? "No Details Available" : meetingData[i].meetingUrl : "");
                    let appointmentDate = (meetingData[i].hasOwnProperty('appointmentDate') ? (meetingData[i].appointmentDate === null) ? "" : (new Date(meetingData[i].appointmentDate)).toLocaleDateString('en-US', DATE_OPTIONS) + " at " + (new Date(meetingData[i].appointmentDate)).getHours() + ":" + (new Date(meetingData[i].appointmentDate)).getMinutes() : "");
                    let createdDate = (meetingData[i].dateCreated == null) ? "" : (new Date(meetingData[i].dateCreated)).toLocaleDateString('en-US', DATE_OPTIONS);
                    meetingValueList.push([
                        meetingId,
                        patientCaseId,
                        displayName,
                        appointmentDate,
                        createdDate,
                    ]);
                }
                setMeetingListValue(meetingValueList);
            })
            .catch((error) => {
                inactivemeetingLoader(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    useEffect(() => {
        getMeetingList();
    }, []);

    const appointmentTableBody = (
        <div>
            <Card >
                <Card.Header title="Inactive Meeting Details">
                    <Button primary={false} onClick={getMeetingList}>Reload Meeting List</Button>
                </Card.Header>
                <Card.Section>
                    {meetingListValue && meetingListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Meeting Id',
                                'Patient Case Id',
                                'Appointment Name',
                                'Appointment Date & Time',
                                'Create Date',
                            ]}
                            rows={meetingListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Inactive Meeting Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    return (
        <Page fullWidth>
            {appointmentTableBody}
        </Page>
    )
}