import * as React from 'react';
import { useEffect } from 'react';
import {
  TextStyle,
  Card,
  ResourceItem,
  ResourceList,
  Page,
  Stack,
  Layout,
  DisplayText,
} from '@shopify/polaris';
import { Box, Flex } from '@primer/components';
import FeatureImg from '../image/featureimage-doctor.png';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientCaseList } from '../../redux/effects/ApiCall';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { AppState } from '../../redux/store/Store';
import { useHistory, useParams } from 'react-router-dom';
import AdminLoader from './AdminLoader';

export default function PatientCaseList() {
  const history = useHistory();
  const loadingMarkup = <AdminLoader />;
  const dispatch = useDispatch();

  const { patientid } = useParams() as {
    patientid: string;
  };
  const { patientName } = useParams() as {
    patientName: string;
  };

  const handleViewNotesToggle = () => {
    history.push(`/patientnotes/${patientid}`);
  }

  const handleAddEncounterToggle = () => {
    history.push(`/filepreparation/${patientid}`);
  }

  // encounter list api call ...
  useEffect(() => {
    dispatch(getPatientCaseList(patientid));
  }, [dispatch]);

  const caseListValue = useSelector((state: AppState) => state.caseValue);
  console.log("DOCTORCASELISTREDUX==>", caseListValue);

  const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);
  console.log("ADMINCASELISTREDUXNAME==>", casehistoryname);

  const emptyPageMarkup = (
    <Layout>
      <Layout.Section oneHalf>
        <Flex style={{ height: "50vh" }} alignItems="center" justifyContent="center">
          <Box pt={5} mt={5}>
            <DisplayText size="medium">No Case History Available</DisplayText>
          </Box>
        </Flex>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Box pt={5} mt={5}>
          <div><img
            width="100%"
            height="100%"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',

            }} src={FeatureImg} alt="" /></div>
        </Box>
      </Layout.Section>
    </Layout>
  );

  const pagebody = (
    <Layout>
      {caseListValue && caseListValue.caseValue.length ? (
        <Layout.Section>
          <Card>
            <ResourceList
              items={caseListValue.caseValue}
              renderItem={(item) => {
                const { patientId, caseId, secondOpinionReasons, whyWant2ndOpinion, consultationType } = item;
                return (
                  <ResourceItem
                    id={`/filepreparation/${patientId}/${caseId}`}
                    url={`/filepreparation/${patientId}/${caseId}`}
                    accessibilityLabel={`View details for ${whyWant2ndOpinion}`}
                    persistActions
                  >
                    <Stack alignment="center">
                      <Stack.Item>
                        <VisibilityIcon style={{ fill: "white" }} />
                      </Stack.Item>
                      <Stack.Item fill>
                        <Box>
                          <h5>
                            <TextStyle variation="strong">{whyWant2ndOpinion}</TextStyle>
                          </h5>
                          <Box maxWidth="40vw"><em>{secondOpinionReasons}</em></Box>
                          {/* <Box maxWidth="40vw"><em>{caseStatus}</em></Box> */}
                          {/* {consultationType ? (<div><Badge progress="complete">{consultationType}</Badge>&nbsp;&nbsp;<Badge status="warning">{patientName}</Badge></div>) : ''} */}
                        </Box>
                      </Stack.Item>
                      <Stack.Item>
                        <ArrowForwardIosIcon style={{ fill: "white" }} />
                      </Stack.Item>
                    </Stack>
                  </ResourceItem>
                );
              }}
            />
          </Card>
        </Layout.Section>

      ) : (
        <Layout.Section>
          {emptyPageMarkup}
        </Layout.Section>
      )}

    </Layout>
  );

  return (
    <div>
      {caseListValue.loading === true ? loadingMarkup : ""}
      <Page fullWidth title={`Patient Case List of ( ${casehistoryname ? casehistoryname : ''} )`}
        secondaryActions={[{ content: 'View Note', onAction: handleViewNotesToggle }]}
        primaryAction={{ content: 'Add Case', onAction: handleAddEncounterToggle }}
        breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
      >
        {pagebody}
      </Page>
    </div>
  );
}