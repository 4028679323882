import React, { useEffect } from 'react';
import { Page, Card, DataTable, ExceptionList, Loading, Button, Stack, TextStyle } from '@shopify/polaris';
import { NoteMinor, ViewMajor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/store/Store';
import { getInactiveCorporatePatinetList } from '../../../../redux/effects/ApiCall';

export default function InactivePatientList(id: any) {
    let packageId = id.packageId;

    const history = useHistory();
    const dispatch = useDispatch();

    const loadingMarkup = <Loading />;

    const gotoDetails = (profileId: any) => {
        history.push(`/inactivepatientpaymentdetails/${packageId}/${profileId}`)
    }

    useEffect(() => {
        dispatch(getInactiveCorporatePatinetList(packageId));
    }, [dispatch]);

    // call Store
    const inActiveCorporatepatientListDetails = useSelector((state: AppState) => state.inactiveCorporatePatientValue);
    const inActiveCorporatePatientListValues = inActiveCorporatepatientListDetails.inActiveCorporatePatientListValue;
    const activeCorporatePatientTeamCount = inActiveCorporatepatientListDetails.totalNoOfPatientTeam;

    let inActiveCorporatePatientListConfig: any = [];
    for (var i = 0; i < inActiveCorporatePatientListValues.length; i++) {
        let profileId = (inActiveCorporatePatientListValues[i].profileId === null) ? "" : inActiveCorporatePatientListValues[i].profileId;
        let firstname = (inActiveCorporatePatientListValues[i].firstname === null || inActiveCorporatePatientListValues[i].firstname === "") ? "" : inActiveCorporatePatientListValues[i].firstname;
        let lastname = (inActiveCorporatePatientListValues[i].lastname === null || inActiveCorporatePatientListValues[i].lastname === "") ? "" : inActiveCorporatePatientListValues[i].lastname;
        let email = (inActiveCorporatePatientListValues[i].email === null || inActiveCorporatePatientListValues[i].email === "") ? "" : inActiveCorporatePatientListValues[i].email;
        inActiveCorporatePatientListConfig.push([
            <Button icon={ViewMajor} size="slim" onClick={() => gotoDetails(profileId)}></Button>,
            firstname,
            lastname,
            email
        ]);
    }
    const corporateInactivePatientListTableValue: any[] = inActiveCorporatePatientListConfig;

    const actualPageMarkup = (
        <Card>
            <Card.Header title="Patient Team Details">
            </Card.Header>
            <Card.Section>
                <Stack vertical={true}>
                    <Stack.Item>
                        <TextStyle>No. of Patient Team: {activeCorporatePatientTeamCount}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                        {corporateInactivePatientListTableValue && corporateInactivePatientListTableValue.length ? (
                            <DataTable
                                columnContentTypes={[
                                    'text',
                                    'text',
                                    'text',
                                    'text'
                                ]}
                                headings={[
                                    'View Details',
                                    'First Name',
                                    'Last Name',
                                    'Email'
                                ]}
                                rows={corporateInactivePatientListTableValue}
                            />
                        ) : (
                            <ExceptionList
                                items={[
                                    {
                                        icon: NoteMinor,
                                        description: 'No Patient Team Details is available!',
                                    },
                                ]}
                            />)}
                    </Stack.Item>
                </Stack>
            </Card.Section>
        </Card>
    )
    return (
        <Page fullWidth>
            {inActiveCorporatepatientListDetails === undefined ? "" : loadingMarkup}
            {actualPageMarkup}
        </Page>
    )

}