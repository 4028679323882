import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    Page,
    DescriptionList,
    Button,
    Modal,
    TextField,
    Banner,
    TextStyle,
    InlineError,
    ButtonGroup,
    Stack,
    FormLayout
} from '@shopify/polaris';

import { Redirect, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Url from '../../ApiConfig';
import { Box } from '@primer/components';
import AdminLoader from './AdminLoader';
import Schedule from './Schedule';
import ContactDetails from './ContactDetails';
import Notes from './Notes/Notes';

export default function DoctorDetails(props: any) {
    const [toSignin, setToSignin] = useState(false);

    const { id } = useParams() as {
        id: string;
    };
    const [Loader, setLoader] = useState(false);
    const [contactDetailsLoader, setContactDetailsLoader] = useState(false);
    const [notesLoader, setNotesLoader] = useState(false);

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = (isLoading === true || Loader === true || contactDetailsLoader ===true || notesLoader === true) 
        ? <AdminLoader /> : null;

    const [qualification, setQualification] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [secondHighestqualification, setSecondHighestqualification] = useState('');
    const [yearexp, setYearexp] = useState('');
    const [mbbsDegree, setMbbsDegree] = useState('');
    const [registration, setRegistration] = useState<any[]>([]);
    const [workExpName, setWorkExpName] = useState<any[]>([]);
    const [awardName, setAwardName] = useState<any[]>([]);
    const [languageName, setLanguageName] = useState<any[]>([]);
    const [membershipName, setMembershipName] = useState<any[]>([]);
    const [educationSummary, setEducationSummary] = useState('');
    const [workSummary, setWorkSummary] = useState('');

    const [modalTitle, setModalTitle] = useState("");

    const [isDisableEducationSummaryField, setIsDisableEducationSummaryField] = useState(false);
    const [isDisableWorkSummaryField, setIsDisableWorkSummaryField] = useState(false);

    const [updateEducationSummary, setUpdateEducationSummary] = useState('');
    const handleUpdateEducationSummaryChange = useCallback((newValue) => setUpdateEducationSummary(newValue), []);

    const [updateWorkSummary, setUpdateWorkSummary] = useState('');
    const handleUpdateWorkSummaryChange = useCallback((newValue) => setUpdateWorkSummary(newValue), []);

    const [executiveModalActive, setExecutiveModalActive] = useState(false);
    const handleExecutiveModalChange = () => {
        setExecutiveModalActive((executiveModalActive) => !executiveModalActive);
    }

    const [educationSummaryFieldError, setEducationSummaryFieldError] = useState('');
    const educationSummaryFieldErrorDiv = educationSummaryFieldError
        ? <TextStyle variation="negative">{educationSummaryFieldError}</TextStyle>
        : '';
    const [workSummaryFieldError, setWorkSummaryFieldError] = useState('');
    const workSummaryFieldErrorDiv = workSummaryFieldError
        ? <TextStyle variation="negative">{workSummaryFieldError}</TextStyle>
        : '';
    const closeUpdateSummarySucess = () => {
        setUpdateSummarySuccess('')
    }
    const [updateSummarySuccess, setUpdateSummarySuccess] = useState('');
    const updateSummarySuccessDiv = updateSummarySuccess
        ? <Banner
            onDismiss={closeUpdateSummarySucess}
            status="success">
            <p>{updateSummarySuccess}</p>
        </Banner>
        : '';
    const closeerror = () => {
        setUpdateSummaryError('')
    }
    const [updateSummaryError, setUpdateSummaryError] = useState('');
    const updateSummaryErrorDiv = updateSummaryError
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{updateSummaryError}</p>
        </Banner>
        : '';

    const [isFirstButtonActive, setIsFirstButtonActive] = useState(true);
    const [isSecondButtonActive, setIsSecondButtonActive] = useState(false);
    const [isThirdButtonActive, setIsThirdButtonActive] = useState(false);
    const [isFourthButtonActive, setIsFourthButtonActive] = useState(false);

    // Doctor Summary
    const handleFirstButtonClick = useCallback(() => {
        if (isFirstButtonActive) return;
        setIsFirstButtonActive(true);
        setIsSecondButtonActive(false);
        setIsThirdButtonActive(false);
        setIsFourthButtonActive(false);
    }, [isFirstButtonActive]);

    // Schedule Details Button Toggle
    const handleSecondButtonClick = useCallback(() => {
        if (!isFirstButtonActive && !isThirdButtonActive &&!isFourthButtonActive) return;
        setIsFirstButtonActive(false);
        setIsSecondButtonActive(true);
        setIsThirdButtonActive(false);
        setIsFourthButtonActive(false);
    }, [isSecondButtonActive]);

    // Contact Details Button Toggle
    const handleThirdButtonClick = useCallback(() => {
        if (!isFirstButtonActive && !isSecondButtonActive &&!isFourthButtonActive) return;
        setIsFirstButtonActive(false);
        setIsSecondButtonActive(false);
        setIsThirdButtonActive(true);
        setIsFourthButtonActive(false);
    }, [isThirdButtonActive]);

    // Notes Details Button Toggle
    const handleFourthButtonClick = useCallback(() => {
        if (!isFirstButtonActive && !isSecondButtonActive && !isThirdButtonActive) return;
        setIsFirstButtonActive(false);
        setIsSecondButtonActive(false);
        setIsThirdButtonActive(false);
        setIsFourthButtonActive(true);
        localStorage.setItem("doctorID", String(id));
    }, [isFourthButtonActive]);


    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const drSummaryBody = {
        "doctorId": Number(id),
        "workSummaryText": updateWorkSummary,
        "edicationSummaryText": updateEducationSummary
    }

    // update dr. sumaary details
    const updateDrSummary = () => {
        if (updateWorkSummary === "" && updateEducationSummary === "") {
            setWorkSummaryFieldError("Please add summary");
            setEducationSummaryFieldError("Please add summary");
        }
        else if (updateWorkSummary === "" && updateEducationSummary != "") {
            setWorkSummaryFieldError("Please add summary");
            setEducationSummaryFieldError("");
        }
        else if (updateWorkSummary != "" && updateEducationSummary === "") {
            setWorkSummaryFieldError("");
            setEducationSummaryFieldError("Please add summary");
        }
        else {
            setIsLoading(true);
            setWorkSummaryFieldError("");
            setEducationSummaryFieldError("");
            axios.post(Url.baseUrl + '/adminapp/api/adminActions/update_doctor_summary',
                drSummaryBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    setUpdateSummarySuccess(result.data.message);
                    setUpdateSummaryError("");
                    getDoctorDetailsList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setUpdateSummaryError(error.response.data.message);
                    setUpdateSummarySuccess("");
                })
        }
    }

    const updateDetails = () => {
        setModalTitle("Update Doctor Summary");
        handleExecutiveModalChange();
        setIsDisableEducationSummaryField(false);
        setIsDisableWorkSummaryField(false);
        setUpdateWorkSummary(workSummary);
        setUpdateEducationSummary(educationSummary);
        setUpdateSummarySuccess("");
        setUpdateSummaryError("");
        setEducationSummaryFieldError("");
        setWorkSummaryFieldError("");
    }

    // doctor list GET API
    const getDoctorDetailsList = () => {
        setIsLoading(true);
        axios.get(Url.baseUrl + `/landingeapp/api/doctor-details-by-id/${id}`, { headers })
            .then((res) => {
                setIsLoading(false);
                let doctorData = res.data.doctorDetails;
                setFirstName((doctorData.firstname == "" || doctorData.firstname == null) ? " " : doctorData.firstname);
                setLastName((doctorData.lastname == "" || doctorData.lastname == null) ? " " : doctorData.lastname);
                setEducationSummary(doctorData.hasOwnProperty('educationSummaryDisplayText') ? ((doctorData.educationSummaryDisplayText === "" || doctorData.educationSummaryDisplayText === null) ? "" : doctorData.educationSummaryDisplayText) : "");
                setQualification((doctorData.qualification == "" || doctorData.qualification == null) ? " " : doctorData.qualification.highestQualification.label);
                setSecondHighestqualification((doctorData.qualification == "" || doctorData.qualification == null || doctorData.qualification.secondHighestQualification == null) ? " " : doctorData.qualification.secondHighestQualification.label);
                setMbbsDegree((doctorData.qualification == "" || doctorData.qualification == null || doctorData.qualification.mbbsDegree == null) ? " " : doctorData.qualification.mbbsDegree.label);

                setYearexp((doctorData.yearofExperience == "" || doctorData.yearofExperience == null) ? " " : doctorData.yearofExperience.totalYearsOfPracticeAfterHighestQualification);
                let doctorexp = res.data.doctorDetails.workexperience;
                console.log("docexp===>", doctorexp);
                let workValueList = [];
                for (var i = 0; i < doctorexp.length; i++) {
                    console.log("docexplist===>", doctorexp[i].name);
                    let description = (doctorexp[i].name == "") ? "No Details Available" : doctorexp[i].name;
                    workValueList.push([
                        description

                    ]);
                }
                setWorkSummary(doctorData.hasOwnProperty('workSummaryDisplayText') ? ((doctorData.workSummaryDisplayText === "" || doctorData.workSummaryDisplayText === null) ? "" : doctorData.workSummaryDisplayText) : "");
                setWorkExpName(workValueList);
                let doctorAward = res.data.doctorDetails.awards;
                let awardList = [];
                for (var j = 0; j < doctorAward.length; j++) {
                    let awards = (doctorAward[j].description == "" || doctorAward[j].description == null) ? "No Details Available" : doctorAward[j].description;
                    awardList.push([
                        awards

                    ]);
                }
                setAwardName(awardList);
                let doctorLanguage = res.data.doctorDetails.language;
                let languageList = [];
                for (var k = 0; k < doctorLanguage.length; k++) {
                    let languages = (doctorLanguage[k].label == "") ? "No Details Available" : doctorLanguage[k].label;
                    languageList.push([
                        languages

                    ]);
                }
                setLanguageName(languageList);
                let doctorMembership = res.data.doctorDetails.membership;
                let membershipList = [];
                for (var l = 0; l < doctorMembership.length; l++) {
                    let membership = (doctorMembership[l].name == "") ? "No Details Available" : doctorMembership[l].name;
                    membershipList.push([
                        membership

                    ]);
                }
                setMembershipName(membershipList);
                let doctorRegIdNumber = res.data.doctorDetails.registration;
                let doctorRegIdNumberList = [];
                for (var m = 0; m < doctorRegIdNumber.length; m++) {
                    let regIdNumber = (doctorRegIdNumber[m].idNumber == "") ? "No Details Available" : doctorRegIdNumber[m].idNumber;
                    let regIdType = (doctorRegIdNumber[m].idType == null) ? "No Details Available" : doctorRegIdNumber[m].idType.label;
                    let regNumber = regIdNumber + " " + regIdType;
                    doctorRegIdNumberList.push([
                        regNumber
                    ]);
                }
                setRegistration(doctorRegIdNumberList);


            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    useEffect(() => {
        // Do api call here....
        window.scrollTo(0, 0)
        getDoctorDetailsList()
    }, []);

    const editDoctorDetailsMarkup = (
        <div>
            <Modal
                large
                open={executiveModalActive}
                onClose={handleExecutiveModalChange}
                title={modalTitle}
                primaryAction={{
                    content: 'Submit',
                    onAction: updateDrSummary,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleExecutiveModalChange,
                    },
                ]}
            >
                <Modal.Section>
                    <div>
                        <div>
                            <TextField
                                value={updateEducationSummary}
                                label="Education Summary"
                                placeholder="Enter education summary details"
                                id="educationSummaryID"
                                onChange={handleUpdateEducationSummaryChange}
                                disabled={isDisableEducationSummaryField}
                            />
                            <InlineError message={educationSummaryFieldErrorDiv} fieldID="educationSummaryID" />
                        </div>
                        <div>
                            <TextField
                                value={updateWorkSummary}
                                label="Work Summary"
                                placeholder="Enter work summary"
                                id="workSummaryID"
                                onChange={handleUpdateWorkSummaryChange}
                                disabled={isDisableWorkSummaryField}
                            />
                            <InlineError message={workSummaryFieldErrorDiv} fieldID="educationSummaryID" />
                        </div>
                    </div>
                    <Box p={1}>{updateSummarySuccessDiv}</Box>
                    <Box p={1}>{updateSummaryErrorDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )

    const doctorDetails = (
        <Card>
            <Card.Header title={`${firstName}` + " " + `${lastName}`}>
                <ButtonGroup segmented>
                    <Button pressed={isFirstButtonActive} onClick={handleFirstButtonClick}>Doctor Details</Button>
                    <Button pressed={!isFirstButtonActive && !isSecondButtonActive && !isFourthButtonActive} onClick={handleThirdButtonClick}>Contact Details</Button>
                    <Button pressed={!isFirstButtonActive && !isThirdButtonActive && !isFourthButtonActive} onClick={handleSecondButtonClick}>Schedule Details</Button>
                    <Button pressed={!isFirstButtonActive && !isThirdButtonActive && !isSecondButtonActive} onClick={handleFourthButtonClick}>Note List</Button>
                </ButtonGroup>
            </Card.Header>
            <Card.Section>
                
                {isFirstButtonActive === true ?
                    <div>
                        <Card.Header>
                            <Button primary onClick={updateDetails}>Update Summary Details</Button>
                        </Card.Header>
                        <Card.Section>
                            <DescriptionList
                                items={[
                                    {
                                        term: 'Education Summary:',
                                        description: educationSummary === "" ? "Details not available " : educationSummary
                                    },
                                    {
                                        term: 'Academic Qualifications:',
                                        description: `${qualification}` + "," + `${secondHighestqualification}` + "," + `${mbbsDegree}`
                                    },
                                    {
                                        term: 'Registration with Medical Council:',
                                        description: `${registration.join(", ")}`
                                    },
                                    {
                                        term: 'Language Skills:',
                                        description: `${languageName.join(", ")}`
                                    },
                                    {
                                        term: 'Years of Experience after Post-graduation:',
                                        description: `${yearexp} years`
                                    },
                                    {
                                        term: 'Work Summary:',
                                        description: workSummary === "" ? "Details not available" : workSummary
                                    },
                                    {
                                        term: 'Work Experience:',
                                        description: `${workExpName.join(", ")}`
                                    },
                                    {
                                        term: 'Professional Affiliations:',
                                        description: `${membershipName.join(", ")}`
                                    },
                                    {
                                        term: 'Awards Received:',
                                        description: `${awardName.join(", ")}`
                                    },
                                ]}
                            />
                        </Card.Section>
                    </div>
                    : (isSecondButtonActive === true) ? <div><Schedule Loader={setLoader} /></div>
                        : (isThirdButtonActive === true) ? <div><ContactDetails contactDetailsLoader={setContactDetailsLoader} /></div> : <div><Notes notesLoader={setNotesLoader} /></div>
                }

            </Card.Section>
        </Card >
    )

    return (
        <div>
            {loadingMarkup}
            <Page
                fullWidth
                title="Doctor Details"
                breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
            >
                {toSignin ? <Redirect to="/signin" /> : null}
                {editDoctorDetailsMarkup}
                {doctorDetails}
            </Page>
        </div>
    );
}