import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import {
    Page,
    Tabs
} from '@shopify/polaris';
import { Box } from '@primer/components';
import ActiveAllCaseList from './ActiveAllCaseList';
import InactiveAllCaseList from './InactiveAllCaseList';

export default function AllCaseList() {
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);

    const tabs = [
        {
            id: 'activeTask-list',
            content: 'Active Case List',
            body: (
                <ActiveAllCaseList />
            ),
        },
        {
            id: 'inactiveTas-list',
            content: 'Inactive Case List',
            body: (
                <InactiveAllCaseList />
            ),
        },
    ];

    useEffect(() => {
    }, []);

    return (
        <div>
            {/* {(activeTaskLoader=== true || inactiveTaskLoader === true) ? loadingMarkup : ""} */}
            <Page fullWidth title="Patient Case List">
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    );
}