import React, { useCallback, useEffect, useState } from 'react';
import { Page, Card, DataTable, ExceptionList, Button, Tabs } from '@shopify/polaris';
import { NoteMinor, PaymentsMajor } from '@shopify/polaris-icons';
import axios from 'axios';
import baseUrl from '../../../../../ApiConfig';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/store/Store';
import { InactiveCorporateList, InActiveCorporatePatientList } from '../../../../../redux/interfaces/Model';
import { getInactiveCorporateList, getInactiveCorporatePatinetList } from '../../../../../redux/effects/ApiCall';
import * as _ from 'lodash';
import AdminLoader from '../../../AdminLoader';
import InActivePatientListUnderPatientTeam from './InActivePatientListUnderPatientTeam';
import InActivePatientTeamPaymentDetails from './InActivePatientTeamPaymentDetails';
import { Box } from '@primer/components';

export default function InactivePatientDetails() {
    
    const { packageId } = useParams() as {
        packageId: string;
    };
    const { profileId } = useParams() as {
        profileId: string;
    };
    const history = useHistory();

    const dispatch = useDispatch();

    const loadingMarkup = <AdminLoader />;

    const [inActivePatientListUnderPatientTeamLoader, setInActivePatientListUnderPatientTeamLoader] = useState(false);
    const [inActivePatientTeamPaymentDetailsLoader, setInActivePatientTeamPaymentDetailsLoader] = useState(false);

    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);

    //call Inactive Patient List Store
    const inActiveCorporatePatientList = useSelector((state: AppState) => state.inactiveCorporatePatientValue);
    const inActiveCorporatePatientListValues: InActiveCorporatePatientList = _.filter(inActiveCorporatePatientList.inActiveCorporatePatientListValue, ['profileId', Number(profileId)])[0];

    // call Inactive Package List Store
    const inActiveCorporatePackageList = useSelector((state: AppState) => state.inactiveCorporateListValue);
    const inActiveCorporateListDetails: InactiveCorporateList = _.filter(inActiveCorporatePackageList.inactiveCorporateListValue, ['id', Number(packageId)])[0];
    useEffect(() => {
        if (inActiveCorporateListDetails === undefined && inActiveCorporateListDetails === undefined) {
            dispatch(getInactiveCorporateList());
            dispatch(getInactiveCorporatePatinetList(packageId));
        }
    }, [dispatch, inActiveCorporateListDetails, inActiveCorporatePatientListValues]);
    const tabs = [
        {
            id: 'active-packages',
            content: 'Patient List',
            body: (
                <InActivePatientListUnderPatientTeam inActivePatientListUnderPatientTeamLoader={setInActivePatientListUnderPatientTeamLoader} />
            ),
        },
        {
            id: 'inactive-packages',
            content: 'Payment List',
            body: (
                <InActivePatientTeamPaymentDetails inActivePatientTeamPaymentDetailsLoader={setInActivePatientTeamPaymentDetailsLoader} />
            ),
        },
    ];

    return (
        <div>
            {inActivePatientListUnderPatientTeamLoader === true || inActivePatientTeamPaymentDetailsLoader === true ? loadingMarkup : ""}
            <Page fullWidth
                breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
                title={`${(inActiveCorporatePatientListValues === undefined) ? "" : inActiveCorporatePatientListValues.firstname + " " + inActiveCorporatePatientListValues.lastname + "'s Details"}`}
                subtitle={`${(inActiveCorporateListDetails === undefined) ? "" : "For " + inActiveCorporateListDetails.name}`}
            >
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    )
}