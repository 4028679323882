import React, { useState, useCallback, useEffect } from 'react';
import {
    Layout,
    Banner,
    Heading,
    Page,
    Card,
    Subheading,
    Stack,
    FormLayout,
    Tabs
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { SelectPicker, } from 'rsuite';
import axios from 'axios';
import baseUrl from '../../../ApiConfig';
import Url from '../../../ApiConfig';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { CaseList } from '../../../redux/interfaces/Model';

import ActivePatientCaseNotes from '../Notes/PatientCaseNotes/ActivePatientCaseNotes';
import InActivePatientCaseNotes from '../Notes/PatientCaseNotes/InActivePatientCaseNotes';


export default function AppointmentSelectPatient(props: any) {

    const [toSignin, setToSignin] = useState(false);
    const { appointmentSelectPatientLoader } = props;

    const [caseStatusList, setCaseStatusList] = useState([]);
    const [selectedCaseStatus, setSelectedCaseStatus] = useState(null);
    const handlePatientNameChange = useCallback((value) => setSelectedCaseStatus(value), []);
    const [isLoading, setIsLoading] = useState(false);
    appointmentSelectPatientLoader(isLoading);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };

    const closeUpdateNoteStatusSuccess = () => {
        setUpdateNoteStatusSuccess('')
    }

    const [updateNoteStatusSuccess, setUpdateNoteStatusSuccess] = useState('');
    const updateNoteStatusSuccessDiv = updateNoteStatusSuccess
        ? <Banner
            onDismiss={closeUpdateNoteStatusSuccess}
            status="success">
            <p>{updateNoteStatusSuccess}</p>
        </Banner>
        : '';

    const closeUpdateNoteStatusError = () => {
        setUpdateNoteStatusError('')
    }

    const [updateNoteStatusError, setUpdateNoteStatusError] = useState('');
    const updateNoteStatusErrorDiv = updateNoteStatusError
        ? <Banner
            onDismiss={closeUpdateNoteStatusError}
            status="critical">
            <p>{updateNoteStatusError}</p>
        </Banner>
        : '';

    
    const getPatientCaseStatus = () => {
        appointmentSelectPatientLoader(true);
        axios.get(baseUrl.baseUrl + '/adminapp/api/adminActions/patientCaseStatus_list_dropdown', {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((res) => {
                appointmentSelectPatientLoader(false);
                let response = res.data.caseStatusList;
                setCaseStatusList(response);
            })
            .catch((error) => {
                appointmentSelectPatientLoader(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    const PatientListFromAPI = useSelector((state: AppState) => state.caseValue);
    const caseDetails: CaseList = _.filter(PatientListFromAPI.caseValue, ['caseId', Number(caseid)])[0];

    const casehistoryname = useSelector((state: AppState) => state.caseValue.patientname.patientName);

    // update caseStatus
    const updateCaseStatus = (selectedCaseStatus: any) => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/adminapp/api/adminActions/update_patientCaseStatus',
            { "statusId": selectedCaseStatus, "caseId": caseid }, { headers })
            .then(result => {
                setIsLoading(false);
                setUpdateNoteStatusSuccess("Case Status Updated Successfully");
                getcaseDetails();
            })
            .catch((error) => {
                setIsLoading(false);
                setUpdateNoteStatusSuccess("");
                setUpdateNoteStatusError(error.response.data.message);
            })
    }


    // GET Case details by ID
    const getcaseDetails = () => {
        appointmentSelectPatientLoader(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patient/patient_caseDetails_by_id',
            { "caseId": caseid }, { headers })
            .then(result => {
                appointmentSelectPatientLoader(false);
                let responseObj = result.data.patientCaseDetail.caseStatus;
                let responseObjasArray = [];
                responseObjasArray.push(responseObj);
                setSelectedCaseStatus(responseObjasArray[0].value);
            })
            .catch((error) => {
                appointmentSelectPatientLoader(false);
            })
    }

    useEffect(() => {
        // Do api call here.... 
        window.scrollTo(0, 0);
        getPatientCaseStatus();
        getcaseDetails();
    }, []);

    const symptomsAddInfo = (
        <Card>
            <Card.Section>
                <FormLayout>
                    <FormLayout.Group >
                        <Layout.Section>
                            <Heading>Patient Name:</Heading> <br />
                            <Subheading >{`${(caseDetails === undefined) ? `${(casehistoryname === undefined) ? "" : casehistoryname}` : `${(casehistoryname === undefined) ? "" : casehistoryname}`}`}</Subheading>
                        </Layout.Section>
                        <Layout.Section>
                            <Stack>
                                <Heading>Update Case Status:</Heading>
                                <SelectPicker
                                    block
                                    onChange={handlePatientNameChange}
                                    data={caseStatusList}
                                    value={selectedCaseStatus}
                                    onSelect={updateCaseStatus}
                                    placeholder="Select case status"
                                />
                            </Stack>
                            <Box p={1}>
                                <div>{updateNoteStatusSuccessDiv}</div>
                                <div>{updateNoteStatusErrorDiv}</div>
                            </Box>
                        </Layout.Section>
                    </FormLayout.Group>
                </FormLayout>
            </Card.Section>
        </Card>
    )

    const [activeNoteLoader, setActiveNoteLoader] = useState(false);
    const [inactiveNoteLoader, setInactiveNoteLoader] = useState(false);
    appointmentSelectPatientLoader(activeNoteLoader === true || inactiveNoteLoader === true ? true : false);

    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);
    const tabs = [
        {
            id: 'activeTask-list',
            content: 'Active Note List',
            body: (
                <ActivePatientCaseNotes activeNoteLoader={setActiveNoteLoader} />
            ),
        },
        {
            id: 'inactiveTas-list',
            content: 'Inactive Note List',
            body: (
                <InActivePatientCaseNotes inactiveNoteLoader={setInactiveNoteLoader} />
            ),
        },
    ];

    const notelist = (
        <Card title="Patient Notes  ">
            <Page fullWidth>
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <div>{tabs[selected].body}</div>
                </Tabs>
            </Page>
        </Card>
    )

    return (
        <Page fullWidth>
            {symptomsAddInfo}
            {notelist}
        </Page>
    );
}