import React, { useEffect, useState, useCallback } from 'react';
import {
    Page, DataTable, Button, Modal, FormLayout, TextField, TextStyle, 
    InlineError, Heading, Card, ExceptionList, Banner, SkeletonPage, TextContainer, 
    SkeletonBodyText, SkeletonDisplayText, Stack
} from '@shopify/polaris';
import { AppState } from '../../redux/store/Store';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionListValue, setVerifyValue } from '../../redux/effects/ApiCall';
import axios from 'axios';
import Url from '../../ApiConfig';
import { NoteMinor } from '@shopify/polaris-icons';
import { Box } from '@primer/components';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import AdminLoader from './AdminLoader';


export default function DoctorPatientInteraction() {
    const loadingMarkup = <AdminLoader />;
    const [isLoading, setIsLoading] = useState(false);
    const loadingModalMarkup = isLoading ? <AdminLoader /> : null;
    const dispatch = useDispatch();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    text: {
                        primary: "#FFFFFF",
                        secondary: "#8a877f"
                    }
                },
                typography: {
                    fontSize: 22,
                },
            }),
        [prefersDarkMode],
    );

    useEffect(() => {
        dispatch(getQuestionListValue());
    }, [dispatch]);

    const questionverify = (selectedResponse: any) => {
        const verifyBody = {
            "questionId": selectedResponse,
            "questionStatus": "verified"
        };
        dispatch(setVerifyValue(verifyBody));
    }
    const closeQuestionSuccess = () => {
        setAnsSuccess('');
    }

    const [answerModalActive, setAnswerModalActive] = useState(false);
    const [questionTextShow, setQuestionTextShow] = useState(false);
    const [selectedQuestionID, setSelectedQuestionID] = useState(false);
    const [selectedCaseID, setSelectedCaseID] = useState(false);

    const [ansSuccess, setAnsSuccess] = useState('');
    const ansSuccessDiv = ansSuccess
        ? <Banner
            onDismiss={closeQuestionSuccess}
            status="success">
            <p>{ansSuccess}</p>
        </Banner>
        : '';
    // popup open function..........
    function handleToggle(selectedResponse: any, questionText: any, selectedCaseID: any) {
        setAnswerModalActive((answerModalActive) => !answerModalActive);
        setQuestionTextShow(questionText);
        setSelectedQuestionID(selectedResponse);
        setSelectedCaseID(selectedCaseID);
        setAnsSuccess('');
        setAnswerDescription('');
    }

    const closeModal = () => {
        setAnswerModalActive((answerModalActive) => !answerModalActive);
    }
    const [answerDescription, setAnswerDescription] = useState('');
    const handleQuestionDescriptionChange = useCallback((newValue) => {
        setAnswerDescription(newValue);
    }, []);

    const [questionDescriptionError, setQuestionDescriptionError] = useState('');
    const questionDescriptionErrordiv = questionDescriptionError
        ? <TextStyle variation="negative">{questionDescriptionError}</TextStyle>
        : '';
    const AdminQuestionList = useSelector((state: AppState) => state.questionListValue);
    const questionListValues = AdminQuestionList.questionListValue;
    const questionListConfig: any = [];

    for (var i = 0; i < questionListValues.length; i++) {
        let selectedResponse = questionListValues[i].id;
        let selectedCaseID = questionListValues[i].caseId;
        let questionText = (questionListValues[i].questionText == null) ? "" : questionListValues[i].questionText;
        let questionStatus = (questionListValues[i].questionStatus == null) ? "" : questionListValues[i].questionStatus;
        questionListConfig.push([
            questionText,
            questionStatus,
            <Button primary onClick={() => questionverify(selectedResponse)}>Verify</Button>,
            <Button onClick={() => handleToggle(selectedResponse, questionText, selectedCaseID)}>Answer</Button>
        ]);
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };
    const questionBody = {
        "questionId": selectedQuestionID,
        "type": "admin",
        "caseId": selectedCaseID,
        "answerText": answerDescription,
    };
    const answerSubmit = () => {
        setIsLoading(true);
        if (answerDescription === '') {
            setQuestionDescriptionError("Please add Answer");
        }
        else {
            axios.post(Url.baseUrl + '/questionandanswerapp/api/question/answer_Question',
                questionBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setAnswerModalActive((answerModalActive) => !answerModalActive);
                        setAnsSuccess(result.data.message);
                        dispatch(getQuestionListValue());
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setIsLoading(false);
                })
        }
    }
    const rows: any[] = questionListConfig;
    // Question Table
    const questionTableBody = (
        <Card.Section>
            {questionListConfig && questionListConfig.length ? (
                <DataTable
                    columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text'
                    ]}
                    headings={[
                        'Question Text',
                        'Question Status',
                        'Verify',
                        'Answer'
                    ]}
                    rows={questionListConfig}
                />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Question is available!',
                        },
                    ]}
                />)}
        </Card.Section>
    );

    const answerModalBody = (
        <Modal
            large
            open={answerModalActive}
            onClose={closeModal}
            title={"Add Answer"}
            primaryAction={{
                content: "Submit",
                onAction: answerSubmit,
            }}
        >
            <Modal.Section><Heading>Question:&nbsp;&nbsp;{questionTextShow}</Heading></Modal.Section>
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <TextField
                                    label="Answer"
                                    type="text"
                                    value={answerDescription}
                                    onChange={handleQuestionDescriptionChange}
                                    multiline={4}
                                    placeholder="Please enter your answer here"
                                    id="questionDescriptionID"
                                />
                                <InlineError message={questionDescriptionErrordiv} fieldID="questionDescriptionID" />
                            </div>
                        </FormLayout.Group>
                        <div>{ansSuccessDiv}</div>
                        {/* <div>{questionErrorDiv}</div>  */}
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const loaderskeleton = (
        <Page fullWidth title="Question List">
            {/* <Card > */}
                <SkeletonPage primaryAction >
                    <TextContainer>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                        <FormLayout>
                            <FormLayout.Group condensed>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <Box pt={1}>
                                    <SkeletonBodyText lines={1} />
                                </Box>
                                <SkeletonDisplayText size="small" />
                                <SkeletonDisplayText size="small" />
                            </FormLayout.Group>
                        </FormLayout>

                    </TextContainer>
                </SkeletonPage>
            {/* </Card> */}
        </Page>
    )


    return (
        // <Page fullWidth title="Question List">
        //     {AdminQuestionList.loading ? loadingMarkup : ''}
        //     {questionTableBody}
        //     {answerModalBody}
        //     {loadingModalMarkup}
        // </Page>

        <div>
            {(AdminQuestionList.loading === true || isLoading === true) ? <Stack vertical>{loadingMarkup} {loaderskeleton}</Stack> :
                <Page fullWidth title="Question List">
                    {
                        AdminQuestionList.loading ? "" :
                            <ThemeProvider theme={theme}>
                                {questionTableBody}
                            </ThemeProvider>
                    }
                </Page>}
                {answerModalBody}
                {loadingModalMarkup}
        </div>
    );
}