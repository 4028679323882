import React, { useCallback, useEffect, useState } from 'react';
import {
    Card, FormLayout, Page, RadioButton, Stack, Subheading, TextField,
    Caption, Thumbnail, DataTable, Modal, TextContainer, Button, InlineError, Banner, Loading, TextStyle, ExceptionList
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Icon, Loader, Uploader } from 'rsuite';
import { createMuiTheme, IconButton } from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core';
import { NoteMinor, DeleteMinor } from '@shopify/polaris-icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../../ApiConfig';

export default function PersonalHistory(props: any) {

    const defaultMaterialTheme = createMuiTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: '#006FBB',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            text: {
                primary: "#FFFFF",
                secondary: "#006FBB"
            }
        },
        typography: {
            fontSize: 18,
        }
    });
    const [othersignificantillness, setOtherSignificantIllness] = useState('');
    const handleOtherSignificantIllness = useCallback((value) => {
        setOtherSignificantIllness(value)
    }, []);
    const { personaldatasend } = props;


    const addPersonalHistory = () => {
        console.log("addPersonalhistory Value In Main AddRecord Component=>>", othersignificantillness);
        personaldatasend(othersignificantillness);
    }

    const [value, setValue] = useState('yessmoke');
    const smokehandleChange = useCallback(
        (_checked, newValue) => setValue(newValue),
        [],
    );
    const [alcoholvalue, setAlcoholValue] = useState('yesalcohol');
    const alcoholhandleChange = useCallback(
        (_checked, newAlcoholValue) => setAlcoholValue(newAlcoholValue),
        [],
    );
    const [drugvalue, setDrugValue] = useState('yesdrug');
    const drughandleChange = useCallback(
        (_checked, newDrugValue) => setDrugValue(newDrugValue),
        [],
    );
    const [exercisevalue, setExerciseValue] = useState('yesexercise');
    const exercisehandleChange = useCallback(
        (_checked, newExerciseValue) => setExerciseValue(newExerciseValue),
        [],
    );

    /// for new card ..........
    const styles = {
        width: 80,
        height: 80,
        borderRadius: 40
    };
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const [patientDetailsId, setPatientDetailsId] = useState('');

    const [toSignin, setToSignin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <Loading /> : null;

    const [imageId, setImageId] = useState('');
    const [uploading] = React.useState(false);
    const [imageInfo, setImageInfo] = useState('');

    const [healthDescription, setHealthDescription] = useState('');
    const handleHealthDescriptionChange = useCallback((newValue) => {
        setHealthDescription(newValue);
    }, []);

    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
        new Date(),
    );
    const handleEndDateChange = (date: Date | null) => {
        setSelectedEndDate(date);
    };

    const closeHealthError = () => {
        setHealthError('')
    }
    const [healthError, setHealthError] = useState('');
    const errorDiv = healthError
        ? <Banner
            onDismiss={closeHealthError}
            status="critical">
            <p>{healthError}</p>
        </Banner>
        : '';

    const closeHealthSuccess = () => {
        setHealthSuccess('');
    }
    const [healthSuccess, setHealthSuccess] = useState('');
    const successDiv = healthSuccess
        ? <Banner
            onDismiss={closeHealthSuccess}
            status="success">
            <p>{healthSuccess}</p>
        </Banner>
        : '';

    const [healthDescriptionError, setHealthDescriptionError] = useState('');
    const healthDescriptionErrordiv = healthDescriptionError
        ? <TextStyle variation="negative">{healthDescriptionError}</TextStyle>
        : '';

    const uploadbody = {
        'typeName': 'patient-image'
    };

    const [modalActive, setModalActive] = useState(false);

    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
    }

    const [uploadmodalActive, setuploadmodalActive] = useState(false);
    const uploadhandleToggle = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
        setSelectedDate(new Date());
        setSelectedEndDate(null);
        setImageInfo('');
        setHealthDescriptionError('');
        setHealthDescription('');
        setHealthSuccess('');
        setHealthError('');
    }

    const closeUpload = () => {
        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
    }

    const [docViwerActive, setDocViwerActive] = useState(false);
    const handleDocViewerChange = useCallback(() => setDocViwerActive(!docViwerActive), [docViwerActive]);

    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const handleDeleteHealthModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

    // Delete popup open ........   
    function deleteHealthDetails(response: any) {
        setPatientDetailsId(response);
        setDeleteModalActive((deleteModalActive) => !deleteModalActive);
        setDeleteRiskSuccess('');
    }

    const closeDeleteRiskModalSucessError = () => {
        setDeleteRiskSuccess('');
    }

    const [deleteRiskSuccess, setDeleteRiskSuccess] = useState('');
    const successDeleteRiskModalDiv = deleteRiskSuccess
        ? <Banner
            onDismiss={closeDeleteRiskModalSucessError}
            status="success">
            <p>{deleteRiskSuccess}</p>
        </Banner>
        : '';

    //child to parent value send section.....
    const symptomBody = {
        "patientId": patientid,
        "caseId": caseid,
        "secondOpinionUiSection": "personalandSocialHistory",
        "description": healthDescription,
        "patientDocumentId": imageId,
        "startDate": selectedDate,
        "endDate": selectedEndDate
    };

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const [personalHistoryListValue, setPersonalHistoryListValue] = useState<any[]>([]);
    const truncate = (str: string,) => {
        return str.length > 0 ? str.substring(0, 20) + "..." : str;
    }

    const getPersonalSocialHistoryBody = {
        "caseId": caseid,
        "secondOpinionUiSection": "personalandSocialHistory"
    }

    const [questionListValue, setQuestionListValue] = useState<any[]>([]);
    // Get questionnair list
    const getQuestionnaitList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientQuestionnaireList',
            getPersonalSocialHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resultData = res.data.patientQuestionnaireList;

                console.log("SymptomList=>", resultData);
                console.log("question ", resultData[0].questionnaireType.displayValue);
                let ideasConfig = [];
                for (var i = 0; i < resultData.length; i++) {
                    let questionName = resultData[i].questionnaireType.displayValue;
                    let supportingDocuments = resultData[i].templateUrl;
                    ideasConfig.push([
                        questionName,
                        <Button onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}>View Question</Button>,
                    ]);
                }

                setQuestionListValue(ideasConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
    }

    // get document list
    const getHealthList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/get_patientDetailsList',
            getPersonalSocialHistoryBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.patientDetailList;
                console.log("Symptom List=======>", resData);
                let symptomConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let patientDetailsID = resData[i].id;
                    let description = (resData[i].description == null) ? "" : truncate(resData[i].description);
                    console.log("Award Name " + description);
                    let dateStart = (resData[i].startDate == null) ? "" : (new Date(resData[i].startDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let dateEnd = (resData[i].endDate == null) ? "" : (new Date(resData[i].endDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let supportingDocuments = (resData[i].patientDocument == null) ? '' : resData[i].patientDocument.document;
                    console.log("supportingDocuments==> ", supportingDocuments);
                    symptomConfig.push([
                        <Button icon={NoteMinor} size="large" onClick={() => {
                            setDocViwerActive(true);
                            setImageInfo(supportingDocuments);
                        }}></Button>,
                        description,
                        dateStart,
                        dateEnd,
                        <Button icon={DeleteMinor} size="slim" onClick={() => deleteHealthDetails(patientDetailsID)}></Button>
                    ]);
                    console.log("Symptoms List ====> ", symptomConfig);
                }
                setPersonalHistoryListValue(symptomConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    const healthSubmit = () => {
        console.log(symptomBody);
        if (healthDescription === '') {
            setHealthDescriptionError("Personal history & social history description should not be empty");
        }
        else {
            axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/add_patientDetails',
                symptomBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setHealthError('');
                        setHealthDescriptionError('');
                        setHealthSuccess(result.data.message);
                        getHealthList();
                        setuploadmodalActive((uploadmodalActive) => !uploadmodalActive);
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setHealthDescriptionError('');
                    setHealthSuccess('');
                    setHealthError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }
    // Delete body....
    const deleteChiefComplaintBody = {
        "caseId": caseid,
        "patientDetailId": patientDetailsId
    }
    // delete api call here ....
    const DeleteHealthDetailsSubmit = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/delete_patientDetails',
            deleteChiefComplaintBody, { headers })
            .then((res) => {
                setIsLoading(false);
                setDeleteRiskSuccess(res.data.message);
                getHealthList();
                handleDeleteHealthModalChange();
            })

            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }

    useEffect(() => {
        getQuestionnaitList();
        getHealthList();
    }, []);
    const questionBody = (
        <div>
            <Card>
                <Card.Header title="Questionnaire">
                </Card.Header>
                <Card.Section>
                    {questionListValue && questionListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                            ]}
                            headings={[
                                'Question Name',
                                'View'
                            ]}
                            rows={questionListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Question is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )


    const pagebody = (

        <Card sectioned primaryFooterAction={{ content: 'Submit', onAction: addPersonalHistory }}>
            <FormLayout>
                <FormLayout.Group condensed>
                    <div>
                        <Subheading>Do you drink caffinated beverages?</Subheading>
                    </div>
                    <div>
                        <Subheading>Cup/glass per day</Subheading>
                    </div>
                    <div>
                        <TextField
                            value={othersignificantillness}
                            label=""
                            placeholder="Glass/day"
                            id="othersignificantillness"
                            onChange={handleOtherSignificantIllness}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <Subheading>Do you smoke?</Subheading>
                    </div>
                    <div>
                        <Stack>
                            <RadioButton
                                label="Yes"
                                id="yessmoke"
                                checked={value === 'yessmoke'}
                                name="accounts"
                                onChange={smokehandleChange}
                            />
                            <RadioButton
                                label="No"
                                id="nosmoke"
                                name="accounts"
                                checked={value === 'nosmoke'}
                                onChange={smokehandleChange}
                            />
                        </Stack>
                    </div>
                    <div>
                        <TextField
                            value={othersignificantillness}
                            label=""
                            placeholder="For how long"
                            id="othersignificantillness"
                            onChange={handleOtherSignificantIllness}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <Subheading>Do you drink alcohol?</Subheading>
                    </div>
                    <div>
                        <Stack>
                            <RadioButton
                                label="Yes"
                                id="yesalcohol"
                                checked={alcoholvalue === 'yesalcohol'}
                                name="accounts"
                                onChange={alcoholhandleChange}
                            />
                            <RadioButton
                                label="No"
                                id="noalcohol"
                                name="accounts"
                                checked={alcoholvalue === 'noalcohol'}
                                onChange={alcoholhandleChange}
                            />
                        </Stack>
                    </div>
                    <div>
                        <TextField
                            value={othersignificantillness}
                            label=""
                            placeholder="Number/week"
                            id="othersignificantillness"
                            onChange={handleOtherSignificantIllness}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <Subheading>Do you use drug?</Subheading>
                    </div>
                    <div>
                        <Stack>
                            <RadioButton
                                label="Yes"
                                id="yesdrug"
                                checked={drugvalue === 'yesdrug'}
                                name="accounts"
                                onChange={drughandleChange}
                            />
                            <RadioButton
                                label="No"
                                id="nodrug"
                                name="accounts"
                                checked={drugvalue === 'nodrug'}
                                onChange={drughandleChange}
                            />
                        </Stack>
                    </div>
                    <div>
                        <TextField
                            value={othersignificantillness}
                            label=""
                            placeholder="Please list the drug you are taking"
                            id="othersignificantillness"
                            onChange={handleOtherSignificantIllness}
                        />
                    </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                    <div>
                        <Subheading>Do you exercise regularly?</Subheading>
                    </div>
                    <div>
                        <Stack>
                            <RadioButton
                                label="Yes"
                                id="yesexercise"
                                checked={exercisevalue === 'yesexercise'}
                                name="accounts"
                                onChange={exercisehandleChange}
                            />
                            <RadioButton
                                label="No"
                                id="noexercise"
                                name="accounts"
                                checked={exercisevalue === 'noexercise'}
                                onChange={exercisehandleChange}
                            />
                        </Stack>
                    </div>
                    <div>
                        <TextField
                            value={othersignificantillness}
                            label=""
                            placeholder="Please enter details of your exercise"
                            id="othersignificantillness"
                            onChange={handleOtherSignificantIllness}
                        />
                    </div>
                </FormLayout.Group>
            </FormLayout>
        </Card>
    );

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const problemListandTreatmentPlanAddInfo = (
        <div>
            <Card >
                <Card.Header title="Documents">
                    <Button primary={true} onClick={uploadhandleToggle}>Upload Data</Button>
                </Card.Header>
                <Card.Section>
                    {personalHistoryListValue && personalHistoryListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Document',
                                'Description',
                                'Start Date',
                                'End Date',
                                'Delete'
                            ]}
                            rows={personalHistoryListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Documents is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
        </div >
    )

    const docViewerComponent = (
        <Modal
            open={docViwerActive}
            onClose={handleDocViewerChange}
        >
            <Modal.Section>
                <TextContainer>
                    <p>
                        <iframe src={imageInfo} title='Small document' width='100%' height='700' frameBorder='0'>This is an embedded <a target='_blank' href='http://office.com' rel="noopener noreferrer">Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps' rel="noopener noreferrer">Office Online</a>.</iframe>
                    </p>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );

    const addSymptomDetails = (
        <Modal
            large
            open={modalActive}
            onClose={handleToggle}
            title={"Add Personal History & Social History Details"}
        >
            <Modal.Section>
                <Page>
                    <Box style={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: "25px",
                        height: "0"
                    }}>
                        <iframe style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            border: "0px"
                        }} src="https://virtualsathi-technicise-ehr.herokuapp.com/survey/12" title='Small document'></iframe>
                    </Box>
                </Page>
            </Modal.Section>
        </Modal>
    );

    const uploadSymptomDetails = (
        <Modal
            large
            open={uploadmodalActive}
            onClose={closeUpload}
            title={"Upload Data Of Personal & Social History Details"}
            primaryAction={{
                content: "Submit",
                onAction: healthSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <FormLayout.Group condensed>
                            <div>
                                <div>
                                    <TextField
                                        label="Personal History & Social History Description"
                                        value={healthDescription}
                                        onChange={handleHealthDescriptionChange}
                                        placeholder="Please describe personal & social history description."
                                        multiline={4}
                                        id="riskDescription"
                                    />
                                    <InlineError message={healthDescriptionErrordiv} fieldID="riskDescription" />
                                </div>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Upload Supporting Document</p>
                                <Box style={{ textAlign: "center", border: "1px #006FBB solid", borderRadius: "3px" }} p={1}>
                                    <Uploader
                                        action="https://aims2health-webserver.herokuapp.com/storageapp/api/upload-image/"
                                        draggable
                                        data={uploadbody}
                                        name="document"
                                        listType="text"
                                        onSuccess={(response: Object,) => {
                                            let res = JSON.stringify(response);
                                            setImageId(JSON.parse(res).image.id);
                                            setImageInfo(JSON.parse(res).image.document);
                                            console.log(imageId);
                                        }}
                                    >
                                        <button style={styles} type="button">
                                            {uploading && <Loader backdrop center />}
                                            {imageInfo ? (
                                                <img src={imageInfo} width="100%" height="100%" alt="" />
                                            ) : (
                                                <Thumbnail source={NoteMinor} size="large" alt="Small document" />

                                            )}
                                        </button>
                                    </Uploader>
                                </Box>
                                <Caption>
                                    <p style={{ color: 'gray' }}>Document size should be maximum 3MB.</p>
                                </Caption>
                            </div>
                        </FormLayout.Group>
                        <FormLayout.Group condensed>
                            <div>
                                <p style={{ marginBottom: "5px" }}>Start Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleDateChange(date)}
                                            size="small"
                                            focused
                                            id="startDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                            <div>
                                <p style={{ marginBottom: "5px" }}>End Date</p>
                                <ThemeProvider theme={defaultMaterialTheme}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            inputVariant="outlined"
                                            fullWidth
                                            format="dd/MM/yyyy"
                                            value={selectedEndDate}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={date => handleEndDateChange(date)}
                                            size="small"
                                            focused
                                            id="endDate"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton onClick={() => handleEndDateChange(null)}>
                                                        <Icon icon="close" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </ThemeProvider>
                            </div>
                        </FormLayout.Group>
                        <div>{successDiv}</div>
                        <div>{errorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );



    // Delete modal section ....
    const deleteProblemsPageBody = (
        <Modal
            open={deleteModalActive}
            onClose={handleDeleteHealthModalChange}
            title={" Delete Personal & Social History Details"}
            primaryAction={{
                content: "Yes",
                onAction: DeleteHealthDetailsSubmit,
            }}
            secondaryActions={[
                {
                    content: 'No',
                    onAction: handleDeleteHealthModalChange,
                },
            ]}
            footer={successDeleteRiskModalDiv}
        >
            <Modal.Section>
                <TextContainer>
                    <Subheading>
                        Are you sure, you want to delete this Personal History & Social History Details?
                    </Subheading>
                </TextContainer>
            </Modal.Section>
        </Modal>
    );
    return (
        <div>
            <Page title="Personal & Social History" fullWidth
            >
                <div>
                    {questionBody}
                    {pagebody}
                    <br />
                    {loadingMarkup}
                    {problemListandTreatmentPlanAddInfo}
                    {addSymptomDetails}
                    {docViewerComponent}
                    {deleteProblemsPageBody}
                    {uploadSymptomDetails}
                </div>
            </Page>
        </div>
    );
}
