import React, { useState, useCallback, useEffect } from 'react';
import {
    Page,
    Button, Banner,
    Heading, Toast, Card, DataTable, ExceptionList, Modal, FormLayout, InlineError, TextField, TextStyle, Stack
} from '@shopify/polaris';
import { NoteMinor, EditMinor, ViewMinor } from '@shopify/polaris-icons';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';
import baseUrl from '../../../ApiConfig';
import { DatePicker, SelectPicker } from 'rsuite';
import dayjs from 'dayjs';
import { isBefore } from 'date-fns';

export default function DoctorPatientInteraction(props: any) {
    const [toSignin, setToSignin] = useState(false);

    const [doctorId, setDoctorId] = useState("");

    const { caseid } = useParams() as {
        caseid: string;
    };
    const [selectedDocName, setSelectedDocName] = useState('');
    const handleDocNameChange = useCallback((value) => {
        setSelectedDocName(value);
        setDoctorId(value)
    }, []);

    const [doctorList, setDoctorList] = useState<any[]>([]);
    const [isCreateMeeting, setIsCreateMeeting] = useState(false);
    const [isScheduleMeeting, setIsScheduleMeeting] = useState(false);

    const [successMeetingCreateMessage, setSuccessMeetingCreateMessage] = useState('');
    const [successMeetingCreateToastActive, setSuccessMeetingCreateToastActive] = useState(false);
    const toggleSuccessMeetingCreateToastActive = useCallback(() => setSuccessMeetingCreateToastActive((successMeetingCreateToastActive) => !successMeetingCreateToastActive), []);
    const successMeetingCreateToastMarkup = successMeetingCreateToastActive ? (
        <Toast content={successMeetingCreateMessage} onDismiss={toggleSuccessMeetingCreateToastActive} />
    ) : null;
    const [errorMeetingCreateMessage, setErrorMeetingCreateMessage] = useState('');
    const [errorMeetingCreateToastActive, setErrorMeetingCreateToastActive] = useState(false);
    const toggleErrorMeetingCreateToastActive = useCallback(() => setErrorMeetingCreateToastActive((errorMeetingCreateToastActive) => !errorMeetingCreateToastActive), []);
    const errorMeetingCreateToastMarkup = errorMeetingCreateToastActive ? (
        <Toast content={errorMeetingCreateMessage} error onDismiss={toggleErrorMeetingCreateToastActive} />
    ) : null;
    const { appointmentDoctorPatientInteractionLoader } = props;
    const [isLoading, setIsLoading] = useState(false);
    appointmentDoctorPatientInteractionLoader(isLoading);

    const [startURL, setStartURL] = useState('');

    const [isEdit, setIsEdit] = useState(true);

    const [selectedDate, setSelectedDate] = React.useState<Date>(
        (new Date()),
    );

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
    };

    let newDate = dayjs(`${selectedDate}`);
    const [successToastMessage, setSuccessToastMessage] = useState("");
    const [successToastactive, setSuccessToastactive] = useState(false);
    const successToasttoggleActive = useCallback(() => setSuccessToastactive((successToastactive) => !successToastactive), []);
    const successToastMarkup = successToastactive ? (
        <Toast content={successToastMessage} onDismiss={successToasttoggleActive} />
    ) : null;

    const [errorToastMessage, setErrorToastMessage] = useState("");
    const [errorToastactive, setErrorToastactive] = useState(false);
    const errorToasttoggleActive = useCallback(() => setErrorToastactive((errorToastactive) => !errorToastactive), []);
    const errorToastMarkup = errorToastactive ? (
        <Toast content={errorToastMessage} error onDismiss={errorToasttoggleActive} />
    ) : null;

    const [patientDetailsId, setPatientDetailsId] = useState('');
    console.log(patientDetailsId);
    const [QuestionListValue, setQuestionListValue] = useState<any[]>([]);

    const [questionDescription, setQuestionDescription] = useState('');
    const handleQuestionDescriptionChange = useCallback((newValue) => {
        setQuestionDescription(newValue);
    }, []);

    const [questionModalActive, setQuestionModalActive] = useState(false);
    const handleToggle = () => {
        setQuestionModalActive((questionModalActive) => !questionModalActive);
        setQuestionDescription("");
        setQuestionDescriptionError("");
        setQuestionSuccess("");
        setQuestionnError("");
    }

    const closeQuestionSuccess = () => {
        setQuestionSuccess('');
    }

    const closeQuestionError = () => {
        setQuestionnError('');
    }

    const [questionDescriptionError, setQuestionDescriptionError] = useState('');
    const questionDescriptionErrordiv = questionDescriptionError
        ? <TextStyle variation="negative">{questionDescriptionError}</TextStyle>
        : '';

    const [questionSuccess, setQuestionSuccess] = useState('');
    const questionSuccessDiv = questionSuccess
        ? <Banner
            onDismiss={closeQuestionSuccess}
            status="success">
            <p>{questionSuccess}</p>
        </Banner>
        : '';

    const [QuestionError, setQuestionnError] = useState('');
    const questionErrorDiv = QuestionError
        ? <Banner
            onDismiss={closeQuestionError}
            status="critical">
            <p>{QuestionError}</p>
        </Banner>
        : '';

    const [question, setQuestion] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [caseNo, setCaseNo] = useState("");
    const [answerquestion, setAnswerquestion] = useState("");
    const handleAnswerQuestionChange = useCallback((newValue) => setAnswerquestion(newValue), []);

    const [answerModalActive, setAnswerModalActive] = useState(false);
    const handleAnswerModalActive = () => {
        setAnswerModalActive((answerModalActive) => !answerModalActive);
        setAnswerquestion('');
        setAsnwerSubmitError('');
        setAnswerSubmitSuccess('');
    }
    function answerById(questionID: any, caseId: any, questionText: any) {
        handleAnswerModalActive();
        setQuestionId(questionID);
        setQuestion(questionText);
        setCaseNo(caseId);
    }

    const closeAnswerSubmitError = () => {
        setAsnwerSubmitError('')
    }

    const [asnwerSubmitError, setAsnwerSubmitError] = useState('');
    const errorAnswerSubmitDiv = asnwerSubmitError
        ? <Banner
            onDismiss={closeAnswerSubmitError}
            status="critical">
            <p>{asnwerSubmitError}</p>
        </Banner>
        : '';

    const [answerSubmitSuccess, setAnswerSubmitSuccess] = useState('');
    const closeAnswerSubmitSuccess = () => {
        setAnswerSubmitSuccess('');
    }
    const successAnswerSubmitDiv = answerSubmitSuccess
        ? <Banner
            onDismiss={closeAnswerSubmitSuccess}
            status="success">
            <p>{answerSubmitSuccess}</p>
        </Banner>
        : '';

    const [answer, setAnswer] = useState("");

    const [viewAnswerModalActive, setViewAnswerModalActive] = useState(false);
    const handleViewAnswerModalActive = () => {
        setViewAnswerModalActive((viewAnswerModalActive) => !viewAnswerModalActive);
        setAnswerquestion('');
        setAsnwerSubmitError('');
        setAnswerSubmitSuccess('');
    }
    const truncate = (str: string,) => {
        return str.length > 10 ? str.substring(0, 20) + "..." : str;
    }
    function viewAnswerById(questionID: any, questionText: any, fullAnswer: any) {
        handleViewAnswerModalActive();
        setQuestionId(questionID);
        setQuestion(questionText);
        setAnswer(fullAnswer);
    }
    const createMeetingBody = {
        doctorId: doctorId,
        caseId: String(caseid),
        starttime: newDate
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Create Meeting API
    const createMeeting = () => {
        console.log("CreateMeeting===", createMeetingBody);
        setIsCreateMeeting(true);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/createInstantMeeting',
            createMeetingBody, { headers })
            .then(result => {
                setIsLoading(false);
                toggleSuccessMeetingCreateToastActive();
                setSuccessMeetingCreateMessage(result.data.message);
                setStartURL(result.data.startUrl);
                getMeetingList();
                setIsEdit(false);
                setIsCreateMeeting(false);
            })
            .catch((error) => {
                setErrorMeetingCreateMessage(error.response.data.message);
                toggleErrorMeetingCreateToastActive();
                setIsCreateMeeting(false);
                setIsLoading(false);
            })
    }

    // schedule Meeting
    const scheduleMeetingBody = {
        doctorId: doctorId,
        caseId: String(caseid),
        starttime: newDate
    }
    // Create Meeting API
    const scheduleMeeting = () => {
        console.log("CreateMeeting===", scheduleMeetingBody);
        setIsScheduleMeeting(true);
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/createScheduleMeeting',
            scheduleMeetingBody, { headers })
            .then(result => {
                setIsLoading(false);
                toggleSuccessMeetingCreateToastActive();
                setSuccessMeetingCreateMessage(result.data.message);
                setStartURL(result.data.startUrl);
                getMeetingList();
                setIsEdit(false);
                setIsScheduleMeeting(false);
            })
            .catch((error) => {
                setErrorMeetingCreateMessage(error.response.data.message);
                toggleErrorMeetingCreateToastActive();
                setIsScheduleMeeting(false);
                setIsLoading(false);
            })
    }

    const startMeeting = (meetingURL: any) => {
        window.open(meetingURL);
    }

    const getQuestionBody = {
        "caseId": caseid,
        "doctorId": doctorId
    }

    const deactivateMeeting = (meetingId: any) => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/deactivate_meeting',
            { "teleHealthAppointmentId": meetingId }, { headers })
            .then((res) => {
                setIsLoading(false);
                setSuccessToastMessage(res.data.message);
                successToasttoggleActive();
                getMeetingList();
            })
            .catch((error) => {
                setIsLoading(false);
                setErrorToastMessage(error.response.data.message);
                errorToasttoggleActive();
                getMeetingList();
            })
    }

    const joinMeeting = (joinMeetingUrl: any) => {
        window.open(joinMeetingUrl);
    }

    const getAskedQuestionList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/questionandanswerapp/api/question/list_Question_for_doctor',
            getQuestionBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.questionList;
                let questionLIstValue = [];
                for (var i = 0; i < resData.length; i++) {
                    let questionID = (resData[i].id == null) ? "" : resData[i].id;
                    let caseID = resData[i].caseId;
                    let questionText = (resData[i].questionText === null || resData[i].questionText === null) ? "" : resData[i].questionText;
                    let tableQuestionText = (resData[i].questionText === null || resData[i].questionText === null) ? "" : truncate(resData[i].questionText);
                    let questionAskedBy = (resData[i].askedBy === null || resData[i].askedBy === "") ? "" : resData[i].askedBy;
                    let answer = (resData[i].hasOwnProperty('answerText')) ? (resData[i].answerText === null || resData[i].answerText === "") ? "" : truncate(resData[i].answerText) : "";
                    let fullAnswer = (resData[i].hasOwnProperty('answerText')) ? (resData[i].answerText === null || resData[i].answerText === "") ? "" : resData[i].answerText : "";
                    let answerBy = (resData[i].answeredBy === null || resData[i].answeredBy === "") ? "" : resData[i].answeredBy;
                    let questionStatus = (resData[i].questionStatus === null || resData[i].questionStatus === "") ? "" : resData[i].questionStatus;
                    setPatientDetailsId(questionID);
                    questionLIstValue.push([
                        tableQuestionText,
                        questionAskedBy,
                        answer,
                        answerBy,
                        questionStatus,
                        <Button icon={EditMinor} size="slim" onClick={() => answerById(questionID, caseID, questionText)}>Answer</Button>,
                        <Button icon={ViewMinor} size="slim" onClick={() => viewAnswerById(questionID, questionText, fullAnswer)}>View Answer</Button>
                    ]);
                }
                setQuestionListValue(questionLIstValue);
            })
            .catch((error) => {
                setIsLoading(false);
                // console.log("error record====" + error);
            })
    }


    const [meetingListValue, setMeetingListValue] = useState<any[]>([]);
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };

    // Meeting List Body
    const meetingListBody = {
        "caseId": caseid
    };
    // Meeting List GET API
    const getMeetingList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/telehealthapp/api/telehealth/getMeetingList', meetingListBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let meetingData = res.data.meetingList;
                console.log("AppoinmentLIST==>", meetingData);
                let meetingValueList = [];
                for (var i = 0; i < meetingData.length; i++) {
                    let displayName = (meetingData[i].hasOwnProperty('displayName') ? (meetingData[i].displayName == "" || meetingData[i].displayName == null) ? "No Details Available" : meetingData[i].displayName : "");
                    let meetingId = (meetingData[i].hasOwnProperty('id') ? meetingData[i].id == null ? "" : meetingData[i].id : "");
                    let meetingURL = (meetingData[i].hasOwnProperty('startMeetingUrl') ? (meetingData[i].startMeetingUrl == "" || meetingData[i].startMeetingUrl == null) ? "No Details Available" : meetingData[i].startMeetingUrl : "");
                    let joinMeetingUrl = (meetingData[i].hasOwnProperty('joinMeetingUrl') ? (meetingData[i].joinMeetingUrl == "" || meetingData[i].joinMeetingUrl == null) ? "No Details Available" : meetingData[i].joinMeetingUrl : "");
                    let appointmentDate = (meetingData[i].hasOwnProperty('appointmentDate') ? (meetingData[i].appointmentDate == null) ? "" : (new Date(meetingData[i].appointmentDate)).toLocaleDateString('en-US', DATE_OPTIONS) + " at " + (new Date(meetingData[i].appointmentDate)).getHours() + ":" + (new Date(meetingData[i].appointmentDate)).getMinutes() : "");
                    let createdDate = (meetingData[i].dateCreated == null) ? "" : (new Date(meetingData[i].dateCreated)).toLocaleDateString('en-US', DATE_OPTIONS);
                    meetingValueList.push([
                        <Button outline onClick={() => startMeeting(meetingURL)}>Start Meeting</Button>,
                        meetingId,
                        displayName,
                        appointmentDate,
                        createdDate,
                        <Button outline onClick={() => joinMeeting(joinMeetingUrl)}>Join Meeting</Button>,
                        <Button outline onClick={() => deactivateMeeting(meetingId)}>Deactivate</Button>
                    ]);
                }
                setMeetingListValue(meetingValueList);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    const questionBody = {
        "doctorId": doctorId,
        "caseId": caseid,
        "questionText": questionDescription,
        "questionType": "DoctorToPatient"
    };

    // Question Submit API
    const questionSubmit = () => {
        console.log(questionBody);
        if (questionDescription === '') {
            setQuestionDescriptionError("Please add question");
        }
        else {
            axios.post(baseUrl.baseUrl + '/questionandanswerapp/api/question/ask_Question',
                questionBody, { headers })
                .then(result => {
                    setIsLoading(false);
                    if (result) {
                        setQuestionDescriptionError("");
                        setQuestionSuccess(result.data.message);
                        getAskedQuestionList();
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                    setQuestionDescriptionError("");
                    setQuestionnError(error.response.data.message);
                    setIsLoading(false);
                })
        }
    }

    const answerSubmitBody = {
        "questionId": String(questionId),
        "caseId": String(caseNo),
        "type": "doctor",
        "answerText": answerquestion
    };
    // Submit Answer by Question API
    const answerSubmit = () => {
        console.log(answerSubmitBody);
        axios.post(baseUrl.baseUrl + '/questionandanswerapp/api/question/answer_Question',
            answerSubmitBody, { headers })
            .then(result => {
                setIsLoading(false);
                if (result) {
                    setAsnwerSubmitError("");
                    setAnswerSubmitSuccess(result.data.message);
                    getAskedQuestionList();
                }
            })
            .catch((error) => {
                setAnswerSubmitSuccess("");
                setAsnwerSubmitError(error.response.data.message);
                setIsLoading(false);
            })
    };

    // Get Selected Doctor API
    const getSelectedDoc = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/patientMedicalData/doctorList_for_patientCase',
            { "caseId": caseid }, { headers }).then((res) => {
                setIsLoading(false);
                let values = res.data.patientCaseDoctorlist;
                let docList: Array<{ label: string, value: string, }> = [];
                values.forEach((element: { firstname: any; lastname: any; id: any; speciality: any }) => {
                    docList.push({
                        label: element.firstname + " " + element.lastname + " ( " + element.speciality + " )",
                        value: element.id
                    });
                });
                setDoctorList(docList);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            });
    };

    useEffect(() => {
        setIsEdit(true);
        getSelectedDoc();
        setStartURL('');
        getAskedQuestionList();
        getMeetingList();
    }, []);

    // Telehealth Banner
    const [title] = useState('Doctor Patient Interaction');
    const [bannerbody] = useState('Please click on "Create Meeting" button; then click on "Start Meeting" button for telehealth.');

    const bannermarkup = bannerbody
        ? <Banner>
            <Heading>{title}</Heading>
            <p>{bannerbody}</p>
        </Banner>
        : '';
    const doctorListMarkup = (
        <div>
            <Stack distribution="fillEvenly">
                <label>Select Doctor to Create Zoom Meeting: </label>
                <Card>
                    <SelectPicker
                        block
                        onChange={handleDocNameChange}
                        data={doctorList}
                        value={selectedDocName}
                    />
                </Card>
            </Stack>
            <br />
        </div>

    )

    const appointmentTableBody = (
        <div>
            <Card >
                <Card.Header title="Existing Meeting Details">
                    <DatePicker
                        format="DD-MM-YYYY HH:mm"
                        locale={{
                            sunday: 'Su',
                            monday: 'Mo',
                            tuesday: 'Tu',
                            wednesday: 'We',
                            thursday: 'Th',
                            friday: 'Fr',
                            saturday: 'Sa',
                            ok: 'OK',
                            today: 'Today',
                            yesterday: 'Yesterday',
                            hours: 'Hours',
                            minutes: 'Minutes',
                            seconds: 'Seconds'
                        }}
                        value={selectedDate}
                        onChange={handleDateChange}
                        ranges={[]}
                        menuStyle={{ zIndex: 200, color: "rgb(80, 184, 60) !important" }}
                        hideHours={hour => hour < 0 || hour > 23}
                        hideMinutes={minute => minute % 5 !== 0}
                        disabledDate={date => isBefore(date!, new Date(Date.now() - 864e5))}
                    />
                    <Button primary={false} disabled={isScheduleMeeting} onClick={scheduleMeeting}>Schedule Meeting</Button>
                    <Button primary disabled={isCreateMeeting} onClick={createMeeting}>Create Meeting</Button>
                    <Button primary={false} onClick={getMeetingList}>Reload Meeting List</Button>
                </Card.Header>
                <Card.Section>
                    {meetingListValue && meetingListValue.length ? (
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Start Meeting',
                                'Meeting Id',
                                'Appointment Name',
                                'Appointment Date & Time',
                                'Create Date',
                                'Join Meeting',
                                'Deactivate Meeting'
                            ]}
                            rows={meetingListValue}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Meeting Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />
        </div >
    )

    // Question Table
    const questionTableBody = (
        <Card >
            <Card.Header title="Question & Answers">
                <Button primary={false} onClick={getAskedQuestionList}>Reload</Button>
                <Button primary={true} onClick={handleToggle}>Ask Question</Button>
            </Card.Header>
            <Card.Section>
                {QuestionListValue && QuestionListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'Description',
                            'Asked By',
                            'Answer',
                            'Answered By',
                            'Status',
                            'Submit Answer',
                            'View Full Answer'

                        ]}
                        rows={QuestionListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Question & Answers is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    // Question Modal 
    const questionModalBody = (
        <Modal
            large
            open={questionModalActive}
            onClose={handleToggle}
            title={"Ask Question"}
            primaryAction={{
                content: "Submit",
                onAction: questionSubmit,
            }}
        >
            <Modal.Section>
                <Page>
                    <FormLayout>
                        <div>
                            <TextField
                                label="Question"
                                type="text"
                                value={questionDescription}
                                onChange={handleQuestionDescriptionChange}
                                multiline={4}
                                placeholder="Please enter your question"
                                id="questionDescriptionID"
                            />
                            <InlineError message={questionDescriptionErrordiv} fieldID="questionDescriptionID" />
                        </div>
                        <br />
                        <div>{questionSuccessDiv}</div>
                        <div>{questionErrorDiv}</div>
                    </FormLayout>
                </Page>
            </Modal.Section>
        </Modal>
    );

    // Answer Question modal
    const answerQuestionComponent = (
        <div>
            <Modal
                large
                open={answerModalActive}
                onClose={handleAnswerModalActive}
                title="Add Answer"
                primaryAction={{
                    content: 'Submit',
                    onAction: answerSubmit,
                }}
            >
                <Modal.Section>
                    <TextStyle variation="strong">Question: {question}</TextStyle>
                </Modal.Section>
                <Modal.Section>
                    <TextField
                        label="Answer"
                        value={answerquestion}
                        onChange={handleAnswerQuestionChange}
                        multiline={4}
                    />
                    <br />
                    <div>{errorAnswerSubmitDiv}</div>
                    <div>{successAnswerSubmitDiv}</div>
                </Modal.Section>
            </Modal>
        </div>
    )
    const viewAnswerComponent = (
        <div>
            <Modal
                large
                open={viewAnswerModalActive}
                onClose={handleViewAnswerModalActive}
                title="View Answer"
                primaryAction={{
                    content: 'Close',
                    onAction: handleViewAnswerModalActive,
                }}
            >
                <Modal.Section>
                    <TextStyle variation="strong">Question: {question}</TextStyle>
                </Modal.Section>
                <Modal.Section>
                    <TextField
                        label="Answer"
                        value={answer}
                        onChange={handleAnswerQuestionChange}
                        multiline={4}
                        readOnly
                    />
                    <br />
                    <div>{errorAnswerSubmitDiv}</div>
                    <div>{successAnswerSubmitDiv}</div>
                </Modal.Section>
            </Modal>
        </div>
    )
    return (
        <Page fullWidth >
            {toSignin ? <Redirect to="/signin" /> : null}
            {bannermarkup}
            <br />
            {doctorListMarkup}
            {successMeetingCreateToastMarkup}
            {errorMeetingCreateToastMarkup}
            {appointmentTableBody}
            {questionTableBody}
            {questionModalBody}
            {answerQuestionComponent}
            {successToastMarkup}
            {errorToastMarkup}
            {viewAnswerComponent}
        </Page>
    );
}