import React, { useState, useEffect } from 'react';
import {
    Button,
    Page,
    Card,
    DataTable,
    ExceptionList,
} from '@shopify/polaris';
import axios from 'axios';
import baseUrl from '../../ApiConfig';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { NoteMinor, RefreshMinor, MobileBackArrowMajor, ViewMinor } from '@shopify/polaris-icons';
import AdminLoader from './AdminLoader';

export default function DoctorAppointments(props: any) {
    const [toSignin, setToSignin] = useState(false);
    const history = useHistory();
    const { doctorId } = useParams() as {
        doctorId: string;
    };
    const [isLoading, setIsLoading] = useState(false);
    const loadingMarkup = isLoading ? <AdminLoader /> : null;

    function gotoDetails(patientId: number,caseId:Number) {
        history.push(`/filepreparation/${patientId}/${caseId}`);
    }

    const [appoinmentRequestListValue, setAppoinmentRequestListValue] = useState<any[]>([]);

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    const appointmentBody = {
        "targetDoctorId": doctorId
    };

    // Selection Type GET API
    const getAppoinmentRequestList = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/appointmentapp/api/appointment/get_appointment_list_for_doctor_by_admin',
            appointmentBody, { headers })
            .then((res) => {
                setIsLoading(false);
                let bookData = res.data.appointmentRequestList;
                let appoinmentValueList = [];
                for (var i = 0; i < bookData.length; i++) {
                    let patientId = (bookData[i].patientId == null) ? "" : bookData[i].patientId;
                    let caseId = (bookData[i].caseId == null) ? "" : bookData[i].caseId;
                    let description = (bookData[i].name == "" || bookData[i].name == null) ? "No Details Available" : bookData[i].name;
                    let createdDate = (bookData[i].createDate == null) ? "" : (new Date(bookData[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let endDate = (bookData[i].end == null) ? "" : (new Date(bookData[i].end)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let modifiedDate = (bookData[i].modifiedDate == null) ? "" : (new Date(bookData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let appoinmentStatus = (bookData[i].hasOwnProperty('status')) ? (bookData[i].status === null || bookData[i].status === "") ? "" : bookData[i].status : "";
                    appoinmentValueList.push([
                        <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(patientId,caseId)}>View</Button>,
                        description,
                        createdDate,
                        endDate,
                        modifiedDate,
                        appoinmentStatus
                    ]);
                }
                setAppoinmentRequestListValue(appoinmentValueList);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            })
    }

    useEffect(() => {
        // Do api call here.... 
        getAppoinmentRequestList();
    }, []);

    function gotoDoctorList() {
        history.push(`/doctors`);
    }

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const tableMarkup = (
        <Card >
            <Card.Section>
                <Card.Header>
                    <Button icon={RefreshMinor} primary={false} onClick={getAppoinmentRequestList}>Reload Appointment List</Button>
                </Card.Header>

                {appoinmentRequestListValue && appoinmentRequestListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text'
                        ]}
                        headings={[
                            'View Details',
                            'Description',
                            'Create Date',
                            'End Date',
                            'Modified Date',
                            'Status',
                        ]}
                        rows={appoinmentRequestListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Appointment Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    );

    return (
        <div>
            {loadingMarkup}
            <Page title="Doctor Appointment List" fullWidth
                breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
            >
                {toSignin ? <Redirect to="/signin" /> : null}

                {tableMarkup}
            </Page>
        </div>

    );
}