import React, { useState, useCallback, useEffect } from 'react';
import { Page, Tabs } from '@shopify/polaris';
import { Box } from '@primer/components';
import InactivePatientList from './CorporatePatientList/InactivePatientList';
import InactiveAppointmentList from './CorporateAppointmentList/InactivePaymentList';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { InactiveCorporateList } from '../../../redux/interfaces/Model';
import { getInactiveCorporateList } from '../../../redux/effects/ApiCall';
import * as _ from 'lodash';

export default function CorporatePackageInactivedetails() {
    const dispatch = useDispatch();

    const { id } = useParams() as {
        id: string;
    };
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedInactiveCorporatepackagesTab", String(selectedTabIndex));
    }, []);

    // call Store
    const inActiveCorporatePackageList = useSelector((state: AppState) => state.inactiveCorporateListValue);
    const inActiveCorporateListDetails: InactiveCorporateList = _.filter(inActiveCorporatePackageList.inactiveCorporateListValue, ['id', Number(id)])[0];

    useEffect(() => {
        window.scrollTo(0, 0);
        if (inActiveCorporateListDetails === undefined) {
            dispatch(getInactiveCorporateList());
        }
    }, [dispatch, inActiveCorporateListDetails]);


    const tabs = [
        {
            id: 'inactive-patient-list',
            content: 'Patient Team List in Corporate Package',
            body: (
                <InactivePatientList packageId={id} />
            ),
        },
        {
            id: 'inactive-payment-list',
            content: 'Payment List in Corporate Package',
            body: (
                <InactiveAppointmentList packageId={id} />
            ),
        },
    ];

    return (
        <Page fullWidth
            breadcrumbs={[{ content: 'Corporate Packages', url: '/corporatepackages' }]}
            title={`${(inActiveCorporateListDetails === undefined) ? "" : inActiveCorporateListDetails.name + "'s Details"}`}
        >
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Box py={3}>{tabs[selected].body}</Box>
            </Tabs>
        </Page>
    )
}