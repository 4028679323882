import React, { useEffect, useState } from 'react';
import { Page, Loading, Button, Card, DataTable, ExceptionList } from '@shopify/polaris';
import axios from 'axios';
import Url from '../../ApiConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorOnboardingIncompleteList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { NoteMinor, MobileCancelMajor, MobileAcceptMajor } from '@shopify/polaris-icons';
import { Redirect } from 'react-router-dom';

export default function DoctorsOnboardingRequest(props: any) {
    const { docOndoardingIncompleteLoading } = props;
    const [isLoading, setIsLoading] = useState(false);
    docOndoardingIncompleteLoading(isLoading);
    const [toSignin, setToSignin] = useState(false);
    const dispatch = useDispatch();
    // approve reject api call........   
    const doctorOnBoardingApproveOrReject = (doctorId: any, approveOrreject: any) => {
        setIsLoading(true);
        console.log("doctorId=", doctorId);
        let token = localStorage.getItem("token");
        axios.post(Url.baseUrl + '/adminapp/api/onboardRequestApproval/', { "isApproved": approveOrreject, "onboardReqId": doctorId }, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `token ${token}`
            }

        })
            .then((res) => {
                let values = res.data.status;
                setIsLoading(false);
                console.log(values);
                dispatch(getDoctorOnboardingIncompleteList());

            })

            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            })
    }

    useEffect(() => {
        dispatch(getDoctorOnboardingIncompleteList());
    }, [dispatch]);


    // Api Call from Store.... 
    const docotorList = useSelector((state: AppState) => state.doctorIncompleteValue);
    let docList = docotorList.doctorIncompleteValue;
    let docListConfig: any = [];
    for (var i = 0; i < docList.length; i++) {
        let doctorId: number = docList[i].id;
        let doctorName = (docList[i].fullName === null) ? '' : docList[i].fullName;
        let doctorQualification = (docList[i].qualification === null) ? '' : docList[i].qualification;
        let doctorSpecialty = (docList[i].specialty === null) ? '' : docList[i].specialty;
        let doctorExperience = (docList[i].yearsOfExperience === null) ? '' : docList[i].yearsOfExperience;

        docListConfig.push([
            doctorName,
            doctorQualification,
            doctorSpecialty,
            doctorExperience,
            <Button icon={MobileAcceptMajor} onClick={() => doctorOnBoardingApproveOrReject(doctorId, "True")}>Approve</Button>,
            <Button icon={MobileCancelMajor} destructive onClick={() => doctorOnBoardingApproveOrReject(doctorId, "False")}>Reject</Button>,
        ]);
    }
    const docListValue: any[] = docListConfig;
    const DoctorListTable = (
        <Card>
            <Card.Section>
                {docListValue && docListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'Doctor Name',
                            'Qualification',
                            'Speciality',
                            'Year of Experience',
                            'Approve',
                            'Reject'
                        ]}
                        rows={docListValue}
                    />
                ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Doctor list is available!',
                                },
                            ]}
                        />)}
            </Card.Section>
        </Card>
    );
    return (
        <div>
            {(docotorList.loading === true) ? docOndoardingIncompleteLoading(true) :
                <Page title="Doctor's On-Boarding Incomplete" fullWidth>
                    {toSignin ? <Redirect to="/signin" /> : null}
                    {DoctorListTable}
                </Page>}
        </div>
    )

}