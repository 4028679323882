import React, { useCallback, useEffect, useState } from 'react';
import {
    Card,
    Button,
    Page,
    DataTable,
    ExceptionList,
    Toast
} from '@shopify/polaris';
import { ExchangeMajor, NoteMinor, ViewMajor } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/store/Store';
import { getActiveCorporateList } from '../../../redux/effects/ApiCall';
import axios from 'axios';
import Url from '../../../ApiConfig';
export default function CorporatePackageActive(props: any) {
    const history = useHistory();
    const dispatch = useDispatch();

    const { corporateActivePackageLoader } = props;

    const [noteStatusSuccess, setNoteStatusSuccess] = useState('');
    const [noteStatusSuccessActive, setNoteStatusSuccessActive] = useState(false);
    const togglenoteStatusSuccessActive = useCallback(() => setNoteStatusSuccessActive((noteStatusSuccessActive) => !noteStatusSuccessActive), []);
    const noteStatusSuccessMarkup = noteStatusSuccessActive ? (
        <Toast content={noteStatusSuccess} onDismiss={togglenoteStatusSuccessActive} />
    ) : null;

    const [noteStatusError, setNoteStatusError] = useState('');
    const [noteStatusErrorActive, setNoteStatusErrorActive] = useState(false);
    const togglenoteStatusErrorActive = useCallback(() => setNoteStatusErrorActive((noteStatusErrorActive) => !noteStatusErrorActive), []);
    const noteStatusErrorMarkup = noteStatusErrorActive ? (
        <Toast content={noteStatusError} onDismiss={togglenoteStatusErrorActive} />
    ) : null;

    const gotoDetails = (id: any) => {
        history.push(`/activecorporatepackagesdetails/${id}`)
    }
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    function changePackageStatus(packageId: any) {
        corporateActivePackageLoader(true);
        axios.get(Url.baseUrl + `/paymentapp/api/corporatePackageForAdmin/${Number(packageId)}/toggle_active_corporatePackage`,
            { headers })
            .then((res) => {
                corporateActivePackageLoader(false);
                setNoteStatusError("");
                setNoteStatusSuccess(res.data.message);
                dispatch(getActiveCorporateList());
                togglenoteStatusSuccessActive();
            })
            .catch((error) => {
                corporateActivePackageLoader(false);
                setNoteStatusSuccess("");
                setNoteStatusError(error.response.data.message);
                dispatch(getActiveCorporateList());
                togglenoteStatusErrorActive();
            })
    }
    useEffect(() => {
        dispatch(getActiveCorporateList());
    }, [dispatch]);

    // call Store
    const activeCorporatePackageList = useSelector((state: AppState) => state.activeCorporateListValue);
    const activeCorporateListValues = activeCorporatePackageList.activeCorporateListValue;

    let activeCorporateListConfig: any = [];
    for (var i = 0; i < activeCorporateListValues.length; i++) {
        let id = (activeCorporateListValues[i].id === null) ? "" : activeCorporateListValues[i].id;
        let name = (activeCorporateListValues[i].name === null || activeCorporateListValues[i].name === "") ? "" : activeCorporateListValues[i].name;
        let discountFactor = (activeCorporateListValues[i].discountFactor === null || activeCorporateListValues[i].discountFactor === "") ? "" : activeCorporateListValues[i].discountFactor;
        activeCorporateListConfig.push([
            <Button icon={ViewMajor} size="slim" onClick={() => gotoDetails(id)}></Button>,
            name,
            discountFactor,
            <Button icon={ExchangeMajor} size="slim"
                onClick={() => changePackageStatus(id)}
            ></Button>,
        ]);
    }
    const corporateActivePackageListValue: any[] = activeCorporateListConfig;

    const actualPageMarkup = (
        <Card>
            <Card.Header title="Active Corporate Packages Details">
            </Card.Header>
            <Card.Section>
                {corporateActivePackageListValue && corporateActivePackageListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View Details',
                            'Package Name',
                            'Discount Factor',
                            'Change Status'
                        ]}
                        rows={corporateActivePackageListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Corporate Packages Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )

    return (
        <div>
            {activeCorporatePackageList.loading === true ? corporateActivePackageLoader(true) : corporateActivePackageLoader(false)}
            <Page>
                {actualPageMarkup}
                {noteStatusSuccessMarkup}
                {noteStatusErrorMarkup}
            </Page>
        </div>
    );
}