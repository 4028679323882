import React, { useState, useCallback } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Login from './components/pages/Login';
import AdminDetails from './components/pages/AdminDetails';
import Dashboard from './components/pages/Dashboard';
import { PrivateRoute } from './routes/PrivateRoute';
import { Frame, TopBar, Navigation, Loading } from '@shopify/polaris';
import {
  ArrowLeftMinor, HomeMajor, CustomersMajor, LogOutMinor,
  CustomerPlusMajor, DesktopMajor, ViewMinor, ProfileMajor, SearchMajor,
  PaymentsMajor, TransferWithinShopifyMajor, NoteMajor, PhoneMajor, BankMajor
} from '@shopify/polaris-icons';
import DoctorList from './components/pages/DoctorList';
import PatientList from './components/pages/PatientList';
import PatientCaseList from './components/pages/PatientCaseList';
import { PublicRoute } from './routes/PublicRoute';
import NotFound from './components/pages/NotFound';
import DoctorsOnboarding from './components/pages/DoctorsOnboarding';
import AppointmenList from './components/pages/AppointmentList';
import axios from 'axios';
import Url from './ApiConfig';
import PatientQueries from './components/pages/PatientQueries';
import DoctorQueries from './components/pages/DoctorQueries';
import Payment from './components/pages/Payment';
import ImportData from './components/pages/ImportData';
import PatientQueriesDetails from './components/pages/PatientQueriesDetails';
import DoctorQueriesDetails from './components/pages/DoctorQueriesDetails';
import Task from './components/pages/Task/Task';
import TaskDetails from './components/pages/Task/TaskDetails';
import SelfTaskDetails from './components/pages/SelfTaskDetails';
import DoctorPatientInteraction from './components/pages/DoctorPatientInteraction';
import FilePreparation from './components/pages/Patient/FilePreparation';
import AddHealthRecord from './components/pages/Patient/FilePreparation/AddHealthRecord';
import DemographicDetails from './components/pages/Patient/FilePreparation/DemographicDetails';
import ExecutiveList from './components/pages/ExecutiveList';
import DoctorAppointments from './components/pages/DoctorAppointments';
import AllCaseList from './components/pages/CaseList/AllCaseList';
import ExecutiveTaskDetails from './components/pages/ExecutiveTaskDetails';
import DoctorDetails from './components/pages/DoctorDetails';
import PatientTeam from './components/pages/PatientTeam';
import TeleHealth from './components/pages/TeleHealth/TeleHealth';
import PatientTeamDetails from './components/pages/PatientTeamDetails';
import CorporatePackage from './components/pages/CorporatePackage/CorporatePackage';
import ActiveCorporatePackageDetails from './components/pages/CorporatePackage/ActiveCorporatePackageDetails';
import CorporatePackageInactivedetails from './components/pages/CorporatePackage/CorporatePackageInactivedetails';
import ActivePatientListDetails from './components/pages/CorporatePackage/CorporatePatientList/ActivepatientTeamDetails/ActivePatientListDetails';
import InactivePatientDetails from './components/pages/CorporatePackage/CorporatePatientList/InActivePatientTeamDetails/InactivePatientDetails';
import AdminLoader from './components/pages/AdminLoader';
import PatientNotes from './components/pages/Notes/PatientNote/PatientNotes';
import NotesDetails from './components/pages/Notes/PatientCaseNotesForDoctor/NotesDetails';
import Preview from './components/pages/Patient/FilePreparation/Preview';

export default function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );
  const toggleUserMenuActive = useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const Token = localStorage.getItem("token");
  const body = JSON.stringify({
    token: Token,
  });
  const headers = {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Authorization': `Token ${Token}`
  }
  const userMenuActions = [
    {
      items: [{ content: 'User Profile', url: "/admindetails", }],

    },
  ];

  let usernameCheck: string;

  const userFirstname = function () {
    return localStorage.getItem("firstname");
  }

  let Firstname: string = userFirstname()!

  const userLastname = function () {
    return localStorage.getItem("lastname");
  }

  let Lastname: string = userLastname()!

  if (Firstname === '') {
    usernameCheck = "Unknown"
  }
  else {
    usernameCheck = Firstname + " " + Lastname
  }


  const userEmail = function () {
    return localStorage.getItem("email");
  }
  let useremail: string = userEmail()!

  const handleLogout = () => {
    setIsLoading(true);
    localStorage.removeItem("firstname");
    localStorage.removeItem("lastname");
    localStorage.removeItem("token");
    axios.post(Url.baseUrl + '/profileapp/logout/',
      body, { headers })
      .then(res => {
        setIsLoading(false);
        localStorage.removeItem("token");
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("===LOGIN API ERROR===");
        console.log(error);

      })
  }

  const userMenuMarkup = Token ? (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={usernameCheck}
      detail={useremail}
      initials=""
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  ) : null;

  const navigationMarkup = Token ? (
    <Navigation location="/">
      <div style={{ display: 'flex', height: 'calc(100vh - 10rem)', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{ marginBottom: 'auto', width: '100%', }}>
          <Navigation.Section
            items={[
              {
                label: 'Back to Home',
                icon: ArrowLeftMinor,
                url: "/dashboard",
              },
            ]}
          />
          <Navigation.Section
            separator
            title="Aims2Health"
            items={[
              {
                label: 'Dashboard',
                icon: HomeMajor,
                url: "/dashboard",
              },
              {
                label: 'Corporate Packages',
                icon: BankMajor,
                url: "/corporatepackages",
              },
              {
                label: 'Doctor List',
                icon: CustomerPlusMajor,
                url: "/doctors",
              },
              {
                label: 'Patient Team List',
                icon: CustomerPlusMajor,
                url: "/patientteam",
              },
              {
                label: 'Patient List',
                icon: CustomersMajor,
                url: "/patients",
              },
              {
                label: 'Patient Case List',
                icon: CustomersMajor,
                // onClick: toggleIsLoading,
                url: "/allcaselist",
              },
              {
                label: 'Doctor On-Boarding Requests',
                icon: ViewMinor,
                url: "/doctoronboardingrequests",
              },
              {
                label: 'Executive List',
                icon: CustomerPlusMajor,
                url: "/executive",
              },
              {
                label: 'Appointment List',
                icon: DesktopMajor,
                url: "/appointmentlist",
              },
              {
                label: 'Patient Queries',
                icon: SearchMajor,
                url: "/patient-queries",
              },
              {
                label: 'Doctor Queries',
                icon: SearchMajor,
                url: "/doctor-queries",
              },
              {
                label: 'Payment',
                icon: PaymentsMajor,
                url: "/payment",
              },
              {
                label: 'Import Data',
                icon: TransferWithinShopifyMajor,
                url: "/importdata",
              },
              {
                label: 'Task',
                icon: NoteMajor,
                url: "/task",
              },
              {
                label: 'Question & Answer',
                icon: NoteMajor,
                url: "/question-answer",
              },
              {
                label: 'Telehealth',
                icon: PhoneMajor,
                url: "/telehealth",
              }
            ]}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Navigation.Section
            items={[
              {
                label: 'User Profile',
                icon: ProfileMajor,
                url: "/admindetails",
              },
              {
                label: 'Sign out',
                icon: LogOutMinor,
                onClick: handleLogout,
              },
            ]}
            separator
          />
        </div>
      </div>
    </Navigation>
  ) : null;

  const topBarMarkup = Token ? (
    (
      <TopBar
        showNavigationToggle
        userMenu={userMenuMarkup}
        onNavigationToggle={toggleMobileNavigationActive}
      />
    )
  ) : null;
  const loadingMarkup = isLoading ? <AdminLoader /> : null;
  return (
    <div>
      <Frame topBar={topBarMarkup}
        navigation={navigationMarkup}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {/* <div><FirebasePushNotification /></div> */}
        {loadingMarkup}
        <Router>
          <Switch>
            <PublicRoute exact path="/" component={Login} />
            <PublicRoute exact path="/signin" component={Login} />
            <PrivateRoute path="/admindetails" component={AdminDetails} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/doctors" component={DoctorList} />
            <PrivateRoute path="/patients" component={PatientList} />
            <PrivateRoute path="/allcaselist" component={AllCaseList} />
            <PrivateRoute path="/patientcaselist/:patientid?" component={PatientCaseList} />
            <PrivateRoute path="/addhealthrecord/:patientid?" component={AddHealthRecord} />
            <PrivateRoute path="/demographicdetails/:patientid?" component={DemographicDetails} />
            <PrivateRoute path="/filepreparation/:patientid?/:caseid?/" component={FilePreparation} />
            <PrivateRoute path="/patient-queries" component={PatientQueries} />
            <PrivateRoute path="/doctor-queries" component={DoctorQueries} />
            <PrivateRoute path="/payment" component={Payment} />
            <PrivateRoute path="/importdata" component={ImportData} />
            <PrivateRoute path="/doctoronboardingrequests" component={DoctorsOnboarding} />
            <PrivateRoute path="/appointmentlist/" component={AppointmenList} />
            <PrivateRoute path="/patient-queries-details/:id?" component={PatientQueriesDetails} />
            <PrivateRoute path="/doctor-queries-details/:id?" component={DoctorQueriesDetails} />
            <PrivateRoute path="/task" component={Task} />
            <PrivateRoute path="/question-answer" component={DoctorPatientInteraction} />
            <PrivateRoute path="/taskdetails/:currentPage?/:rowIndex?" component={TaskDetails} />
            <PrivateRoute path="/selftaskdetails/:rowIndex?" component={SelfTaskDetails} />
            <PrivateRoute path="/executive" component={ExecutiveList} />
            <PrivateRoute path="/doctorapoointment/:doctorId" component={DoctorAppointments} />
            <PrivateRoute path="/executivetaskdetails/:executiveId?" component={ExecutiveTaskDetails} />
            <PrivateRoute path="/doctordetails/:id?" component={DoctorDetails} />
            <PrivateRoute path="/patientteam" component={PatientTeam} />
            <PrivateRoute path="/telehealth" component={TeleHealth} />
            <PrivateRoute path="/patientteamdetails/:patientTeamCurrentPage?/:patientDetailsID?" component={PatientTeamDetails} />
            <PrivateRoute path="/corporatepackages" component={CorporatePackage} />
            <PrivateRoute path="/activecorporatepackagesdetails/:id?" component={ActiveCorporatePackageDetails} />
            <PrivateRoute path="/inactivecorporatepackagedetails/:id?" component={CorporatePackageInactivedetails} />
            <PrivateRoute path="/activepatientpaymentdetails/:packageId?/:profileId?" component={ActivePatientListDetails} />
            <PrivateRoute path="/inactivepatientpaymentdetails/:packageId?/:profileId?" component={InactivePatientDetails} />
            <PrivateRoute path="/patientnotes/:profileId?" component={PatientNotes} />
            <PrivateRoute path="/notesdetails/:patientid?/:caseid?/:doctorId?" component={NotesDetails} />
            <PrivateRoute path="/preview/:patientid?/:caseid?" component={Preview} />

            <Route component={NotFound} />
          </Switch>
        </Router>
      </Frame>
    </div>
  )
}

