import React, { useEffect, useState, useCallback } from 'react';
import { Page, Tabs } from '@shopify/polaris';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/store/Store';
import { ActiveCorporateList, ActiveCorporatePatientList } from '../../../../../redux/interfaces/Model';
import * as _ from 'lodash';
import { getActiveCorporateList, getActiveCorporatePatinetList } from '../../../../../redux/effects/ApiCall';
import AdminLoader from '../../../AdminLoader';
import ActivePatientListUnderPatientTeam from './ActivePatientListUnderPatientTeam';
import ActivePatientTeamPaymentDetails from './ActivePatientTeamPaymentDetails';
import { Box } from '@primer/components';

export default function ActivePatientListDetails() {
    const { packageId } = useParams() as {
        packageId: string;
    };
    const { profileId } = useParams() as {
        profileId: string;
    };
    const history = useHistory();

    const dispatch = useDispatch();

    const loadingMarkup = <AdminLoader />;

    const [activePatientListUnderPatientTeamLoader, setActivePatientListUnderPatientTeamLoader] = useState(false);
    const [activePatientTeamPaymentDetailsLoader, setActivePatientTeamPaymentDetailsLoader] = useState(false);

    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
    }, []);

    // call Active Package List Store
    const activeCorporatePackageList = useSelector((state: AppState) => state.activeCorporateListValue);
    const activeCorporateListDetails: ActiveCorporateList = _.filter(activeCorporatePackageList.activeCorporateListValue, ['id', Number(packageId)])[0];

    // call Active Corporate Patient List Store
    const activeCorporatePatientList = useSelector((state: AppState) => state.activeCorporatePatientValue);
    const activeCorporatePatientListValues: ActiveCorporatePatientList = _.filter(activeCorporatePatientList.activeCorporatePatientListValue, ['profileId', Number(profileId)])[0];
    useEffect(() => {
        if (activeCorporateListDetails === undefined && activeCorporatePatientListValues === undefined) {
            dispatch(getActiveCorporateList());
            dispatch(getActiveCorporatePatinetList(packageId));
        }
    }, [dispatch, activeCorporateListDetails, activeCorporatePatientListValues]);
    const tabs = [
        {
            id: 'active-packages',
            content: 'Patient List',
            body: (
                <ActivePatientListUnderPatientTeam activePatientListUnderPatientTeamLoader={setActivePatientListUnderPatientTeamLoader} />
            ),
        },
        {
            id: 'inactive-packages',
            content: 'Payment List',
            body: (
                <ActivePatientTeamPaymentDetails activePatientTeamPaymentDetailsLoader={setActivePatientTeamPaymentDetailsLoader} />
            ),
        },
    ];

    return (
        <div>
            {activePatientListUnderPatientTeamLoader === true || activePatientTeamPaymentDetailsLoader === true ? loadingMarkup : ""}
            <Page fullWidth
                breadcrumbs={[{ content: 'Previous Section', onAction: () => { history.goBack() } }]}
                title={`${(activeCorporatePatientListValues === undefined) ? "" : activeCorporatePatientListValues.firstname + " " + activeCorporatePatientListValues.lastname + "'s Details"}`}
                subtitle={`${(activeCorporateListDetails === undefined) ? "" : "For " + activeCorporateListDetails.name}`}
            >
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>
    )
}