import React, { useState, useCallback, useEffect } from 'react';
import { Box } from '@primer/components';
import {
    Tabs,
    Page,
} from '@shopify/polaris';
import ActiveTaskList from './ActiveTaskList';
import InactiveTaskDetails from './InactiveTaskList';
import AdminLoader from '../AdminLoader';

export default function Task() {

    const loadingMarkup = <AdminLoader />;
    const [activeTaskLoader, setActiveTaskLoader] = useState(false);
    const [inactiveTaskLoader, setInactiveTaskLoader] = useState(false);
    const [selected, setSelected] = useState(0);
    const handleTabChange = useCallback((selectedTabIndex) => {
        setSelected(selectedTabIndex);
        localStorage.setItem("selctedOnboardingtab", String(selectedTabIndex));
    }, []);


    useEffect(() => {
        setSelected(Number(localStorage.getItem("selctedOnboardingtab")));

    });

    const tabs = [
        {
            id: 'activeTask-list',
            content: 'Active Task List',
            body: (
                <ActiveTaskList activeTaskLoader={setActiveTaskLoader} />
            ),
        },
        {
            id: 'inactiveTas-list',
            content: 'Completed Task List',
            body: (
                <InactiveTaskDetails inactiveTaskLoader={setInactiveTaskLoader}/>
            ),
        },
    ];

    return (
        <div>
            {(activeTaskLoader=== true || inactiveTaskLoader === true) ? loadingMarkup : ""}
            <Page fullWidth title="Task">
                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                    <Box py={3}>{tabs[selected].body}</Box>
                </Tabs>
            </Page>
        </div>

    )

}