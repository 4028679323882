import React, { useState, useEffect } from 'react';
import { Page, Card, Button, ExceptionList, DescriptionList, Stack, DataTable, Layout, } from '@shopify/polaris';

import { Redirect, useHistory, useParams } from 'react-router-dom';
import { NoteMinor, ViewMinor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientTeamList } from '../../redux/effects/ApiCall';
import { AppState } from '../../redux/store/Store';
import { AdminPatientTeamList } from '../../redux/interfaces/Model';
import * as _ from 'lodash';
import AdminLoader from './AdminLoader';
import axios from 'axios';
import baseUrl from '../../ApiConfig';
export default function PatientTeamDetails() {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [toSignin] = useState(false)
    const { patientDetailsID } = useParams() as {
        patientDetailsID: string,

    };
    // const { patientDetailsID } = useParams() as {
    //     patientDetailsID: string,

    // };
    let patientid = patientDetailsID
    const { patientTeamCurrentPage } = useParams() as {
        patientTeamCurrentPage: string,

    };
    const history = useHistory();
    const loadingMarkup = <AdminLoader />;

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    };

    // Call Store
    const patientTeamList = useSelector((state: AppState) => state.allPatientTeamListValue);
    const patientTeamValues: AdminPatientTeamList = _.filter(patientTeamList.allPatientTeamListValue, ['profileId', Number(patientDetailsID)])[0];

    let patientTeamEmail = patientTeamValues === undefined ? "" : patientTeamValues.email;
    let patientTeamPhone = patientTeamValues === undefined ? "" : String(patientTeamValues.phone);

    const [patientTeamEmailPhone, setpatientTeamEmailPhone] = useState("");

    const truncate = (str: string,) => {
        return str.length > 20 ? str.substring(0, 25) + "..." : str;
    }
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const [selectedDoctorListValue, setSelectedDoctorListValue] = useState<any[]>([]);

    function gotoAddPatient() {
        localStorage.setItem("PatientID", String(patientDetailsID));
        localStorage.setItem("PatientTeamID", String(patientTeamEmailPhone));
        history.push(`/demographicdetails`);
    }

    function gotoDetails(patientId: number) {
        history.push(`/patientcaselist/${patientId}`);
    }

    // Get Selected Doctor API
    const getPatientListByPatientTeam = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/adminapp/api/adminActions/patient_list_by_patientTeam_id',
            { "patientTeamProfileId": patientDetailsID }, { headers }).then((res) => {
                setIsLoading(false);
                let patientListValue = res.data.patientList;
                let patientListConfig = [];
                for (var i = 0; i < patientListValue.length; i++)
                {
                    let taskSeqNo: number = i;
                    let patientId: number = patientListValue[i].id;
                    let firstname = (patientListValue[i].firstname === "") || (patientListValue[i].firstname === null) ? ' ' : patientListValue[i].firstname;
                    let lastname = (patientListValue[i].lastname === "") || (patientListValue[i].lastname === null) ? ' ' : patientListValue[i].lastname;
                    let dob = (patientListValue[i].dob === "") || (patientListValue[i].dob === null) ? ' ' : (new Date(patientListValue[i].dob)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let patientRelationshipWithOwner = (patientListValue[i].patientRelationshipWithOwner?.displayValue);
                    let gender = (patientListValue[i].gender?.label);
                    let bloodGroup = (patientListValue[i].bloodGroup?.label);
                    let description = (patientListValue[i].description === "") || (patientListValue[i].description === null) ? ' ' : truncate(patientListValue[i].description);
            
                    patientListConfig.push([
                        <Button icon={ViewMinor} size="slim" onClick={() => gotoDetails(patientId)}>View</Button>,
                        patientId,
                        firstname,
                        lastname,
                        dob,
                        patientRelationshipWithOwner,
                        gender,
                        bloodGroup,
                        description
                    ]);
                }
                setSelectedDoctorListValue(patientListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
                // Error 😨
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        //setToSignin(true);
                    }
                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request and triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                console.log(toSignin);
            });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getPatientListByPatientTeam();
        if (patientTeamValues === undefined) {
            dispatch(getPatientTeamList(Number(patientTeamCurrentPage)));
        }
        if (patientTeamEmail === "") {
            setpatientTeamEmailPhone(String(patientTeamPhone));
        }
        else if (patientTeamPhone === "") {
            setpatientTeamEmailPhone(patientTeamEmail);
        }
        else {
            setpatientTeamEmailPhone(patientTeamEmail);
        }
    }, [dispatch, patientTeamValues]);

    const patientTable = (
        <div>
            <Card>
                <Card.Header title={(patientTeamValues === undefined) ? "" : patientTeamValues.firstname + " " + patientTeamValues.lastname}>
                    <Button primary={true} onClick={gotoAddPatient}>Add Patient</Button>
                </Card.Header>
                <Card.Section>
                    {patientTeamValues ? (
                        <DescriptionList
                            items={[
                                {
                                    term: 'Email',
                                    description: `${(patientTeamValues === undefined) ? "" : patientTeamValues.email}`
                                },
                                {
                                    term: 'Phone Number',
                                    description: `${(patientTeamValues === undefined || patientTeamValues === null) ? "" : patientTeamValues.phone}`
                                },
                            ]}
                        />
                    ) : (
                        <ExceptionList
                            items={[
                                {
                                    icon: NoteMinor,
                                    description: 'No Patient Details is available!',
                                },
                            ]}
                        />)}
                </Card.Section>
            </Card>
            <br />

        </div>
    )

    const patientListTable = selectedDoctorListValue ? (
        <Card>
            <Card.Header title="Patient List">

            </Card.Header>
            <Card.Section>
                {selectedDoctorListValue && selectedDoctorListValue.length ? (
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={[
                            'View',
                            'Patient Id',
                            'First Name',
                            'Last Name',
                            'DOB',
                            'Relationship',
                            'Gender',
                            'BloodGroup',
                            'Description'
                        ]}
                        rows={selectedDoctorListValue}
                    />
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Patient Details Is Available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    ) : (
        <Layout.Section>
            {/* {emptyPageMarkup} */}
        </Layout.Section>
    );

    return (
        <div>
            {patientTeamValues === undefined ? loadingMarkup : ""}
            {toSignin ? <Redirect to="/signin" /> : null}
            <Page title="Patient Team Details" fullWidth breadcrumbs={[{ content: 'Tasklist', url: '/patientteam' }]}>
                {patientTable}
                {patientListTable}
            </Page>
        </div>
    );
}