import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider, ThemeProvider } from '@shopify/polaris';
import { Provider } from 'react-redux';

import App from './App';

import '@shopify/polaris/dist/styles.css';
import theme from './theme';

import 'rsuite/lib/styles/themes/dark/index.less';
import './style.css';

import store from './redux/store/Store';
import * as serviceWorker from './serviceWorker';

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
  <AppProvider i18n={{
    Polaris: {
      Avatar: {
        label: 'Avatar',
        labelWithInitials: 'Avatar with initials {initials}',
      },

      TextField: {
        characterCount: '{count} characters',
      },
      TopBar: {
        toggleMenuLabel: 'Toggle menu',
      },
      Modal: {
        iFrameTitle: 'body markup',
      },
      Frame: {
        skipToContent: 'Skip to content',
        Navigation: {
          closeMobileNavigationLabel: 'Close navigation',
        },
      },
    },
  }}
    features={{ newDesignLanguage: true }}
    theme={theme}
  >
    <ThemeProvider theme={{
      colorScheme: 'dark',
      logo: {
        width: 150,
        topBarSource:
          '/aims2health-with-caption.png',
        contextualSaveBarSource: '',
        url: '/',
        accessibilityLabel: 'aims2health',
      },
    }}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </AppProvider >,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
