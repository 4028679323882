import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    Button,
    Modal,
    TextField,
    Banner,
    TextStyle,
    InlineError,
    Toast,
    Scrollable,
    DataTable,
    ExceptionList,
    Page
} from '@shopify/polaris';
import axios from 'axios';
import Url from '../../../../ApiConfig';
import { Box } from '@primer/components';
import { EditMinor, NoteMinor } from '@shopify/polaris-icons';
import { useParams } from 'react-router-dom';

export default function ActivePatientCaseNotes(props: any) {
    const { activeNoteLoader } = props;

    const [isLoading, setIsLoading] = useState(false);
    activeNoteLoader(isLoading);

    const [notes, setNotes] = useState('');
    const handleNotesChange = useCallback((newValue) => {
        setNotes(newValue);
    }, []);

    const [notesError, setNotesError] = useState('');
    const notesErrorDiv = notesError
        ? <TextStyle variation="negative">{notesError}</TextStyle>
        : '';

    const closeNotesSubmitSuccess = () => {
        setNotesSubmitSuccess('')
    }

    const [notesSubmitSuccess, setNotesSubmitSuccess] = useState('');
    const notesSubmitSuccessDiv = notesSubmitSuccess
        ? <Banner
            onDismiss={closeNotesSubmitSuccess}
            status="success">
            <p>{notesSubmitSuccess}</p>
        </Banner>
        : '';

    const closeNotesSubmitError = () => {
        setNotesSubmitError('')
    }

    const [notesSubmitError, setNotesSubmitError] = useState('');
    const notesSubmitErrorDiv = notesSubmitError
        ? <Banner
            onDismiss={closeNotesSubmitError}
            status="critical">
            <p>{notesSubmitError}</p>
        </Banner>
        : '';

    const [notesModalActive, setNotesModalActive] = useState(false);
    const handleNotesModalToggle = () => {
        setNotesModalActive((notesModalActive) => !notesModalActive);
        setNotes("");
        setNotesError("");
        setNotesSubmitSuccess("");
        setNotesSubmitError("");
    }

    const { profileId } = useParams() as {
        profileId: string;
    };
    const { patientid } = useParams() as {
        patientid: string;
    };
    const { caseid } = useParams() as {
        caseid: string;
    };
    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    // Add notes with patient & case Id details
    const addNotes = () => {
        if (notes === "") {
            setNotesError("Please add notes");
        }

        else {
            setIsLoading(true);
            axios.post(Url.baseUrl + '/erpapp/api/adminNote/add_adminNote_for_patient',
                { "patientId": patientid, "caseId":caseid, "note": notes }, { headers })
                .then(result => {
                    setIsLoading(false);
                    setNotesSubmitError("");
                    setNotesSubmitSuccess(result.data.message);
                    handleNotesModalToggle();
                    activeNoteList();
                })
                .catch((error) => {
                    setIsLoading(false);
                    setNotesSubmitSuccess("");
                    setNotesSubmitError(error.response.data.message);
                    activeNoteList();
                })
        }
    }

    const [noteStatusSuccess, setNoteStatusSuccess] = useState('');
    const [noteStatusSuccessActive, setNoteStatusSuccessActive] = useState(false);
    const togglenoteStatusSuccessActive = useCallback(() => setNoteStatusSuccessActive((noteStatusSuccessActive) => !noteStatusSuccessActive), []);
    const noteStatusSuccessMarkup = noteStatusSuccessActive ? (
        <Toast content={noteStatusSuccess} onDismiss={togglenoteStatusSuccessActive} />
    ) : null;

    const [noteStatusError, setNoteStatusError] = useState('');
    const [noteStatusErrorActive, setNoteStatusErrorActive] = useState(false);
    const togglenoteStatusErrorActive = useCallback(() => setNoteStatusErrorActive((noteStatusErrorActive) => !noteStatusErrorActive), []);
    const noteStatusErrorMarkup = noteStatusErrorActive ? (
        <Toast content={noteStatusError} onDismiss={togglenoteStatusErrorActive} />
    ) : null;


    // Notes Status change API
    function toggleNoteStatus(response: any) {
        setIsLoading(true);
        axios.get(Url.baseUrl + `/erpapp/api/adminNote/${Number(response)}/toggle_active`,
            { headers })
            .then((res) => {
                setIsLoading(false);
                setNoteStatusError("");
                setNoteStatusSuccess(res.data.message);
                activeNoteList();
                togglenoteStatusSuccessActive();
            })
            .catch((error) => {
                setIsLoading(false);
                setNoteStatusSuccess("");
                setNoteStatusError(error.response.data.message);
                activeNoteList();
                togglenoteStatusErrorActive();
            })
    }

    const [noteListValue, setNoteListValue] = useState<any[]>([]);
    // Get InActive Notes List
    const activeNoteList = () => {
        setIsLoading(true);
        axios.post(Url.baseUrl + '/erpapp/api/adminNote/get_admin_notes_by_patient', { "patientId": patientid,"caseId":caseid, "isActive": true }, { headers })
            .then((res) => {
                setIsLoading(false);
                let resData = res.data.noteList;
                let noteListConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let selectedResponse = resData[i].id;
                    let note = (resData[i].note === "") ? "" : resData[i].note;
                    let createdDate = (resData[i].createdDate === null) ? null : (new Date(resData[i].createdDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let createBy = (resData[i].createdBy === null) ? "" : resData[i].createdBy.user.first_name + " " + resData[i].createdBy.user.last_name;
                    let modifiedDate = (resData[i].modifiedDate === null) ? null : (new Date(resData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let modifyBy = (resData[i].modifiedBy === null) ? "" : resData[i].modifiedBy.user.first_name + " " + resData[i].modifiedBy.user.last_name;
                    noteListConfig.push([
                        note,
                        createdDate,
                        createBy,
                        <Button icon={EditMinor} size="slim" onClick={() => toggleNoteStatus(selectedResponse)}></Button>,
                        modifiedDate,
                        modifyBy
                    ]);
                }
                setNoteListValue(noteListConfig);
            })
            .catch((error) => {
                setIsLoading(false);
            })
    }

    useEffect(() => {
        activeNoteList();
    }, []);

    // Active Notes List
    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const getInActiveNoteList = (
        <Card>
            <Card.Header title="Notes List">
                <Button primary={false} onClick={activeNoteList}>Reload</Button>
                <Button primary={true} onClick={handleNotesModalToggle}>Add Note</Button>
            </Card.Header>
            <Card.Section>
                {noteListValue && noteListValue.length ? (
                    <Scrollable shadow focusable>
                        <DataTable
                            columnContentTypes={[
                                'text',
                                'text',
                                'text',
                                'text',
                                'text',
                                'text'
                            ]}
                            headings={[
                                'Note',
                                'Create Date',
                                'Created By',
                                'Change Status',
                                'Modified Date',
                                'Modified By'
                            ]}
                            rows={noteListValue}
                        />
                    </Scrollable>
                ) : (
                    <ExceptionList
                        items={[
                            {
                                icon: NoteMinor,
                                description: 'No Active Notes Details is available!',
                            },
                        ]}
                    />)}
            </Card.Section>
        </Card>
    )
    // active Patient Notes Modal
    const drNotesModalMarkup = (
        <div>
            <Modal
                large
                open={notesModalActive}
                onClose={handleNotesModalToggle}
                title="Add Note"
                primaryAction={{
                    content: 'Submit',
                    onAction: addNotes,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: handleNotesModalToggle,
                    },
                ]}
            >
                <Modal.Section>
                    <div>
                        <TextField
                            value={notes}
                            label="Doctor Notes"
                            placeholder="Please provide notes"
                            id="notesID"
                            onChange={handleNotesChange}
                        />
                        <InlineError message={notesErrorDiv} fieldID="contactNumberID" />
                    </div>
                    <Box p={1}>{notesSubmitSuccessDiv}</Box>
                    <Box p={1}>{notesSubmitErrorDiv}</Box>
                </Modal.Section>
            </Modal>
        </div>
    )

    return (
        <Page fullWidth>
            {getInActiveNoteList}
            {drNotesModalMarkup}
            {noteStatusSuccessMarkup}
            {noteStatusErrorMarkup}
        </Page>
    );
}