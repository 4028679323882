import React, { useState, useCallback, useEffect } from 'react';
import {
    Card,
    FormLayout,
    TextField,
    InlineError,
    Banner,
    TextStyle,
    Button,
    Loading,
    DataTable,
    Scrollable,
    Modal,
    ExceptionList,
} from '@shopify/polaris';
import { Box } from '@primer/components';
import { Typography } from "@material-ui/core";
import baseUrl from '../../../../ApiConfig';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { NoteMinor} from '@shopify/polaris-icons';


export default function ImportedConfig() {
    const [importEmail, setImportEmail] = useState('');
    const [importWhatsapp, setImportWhatsapp] = useState('');
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editConfigId, seteditConfigId] = useState('');

    const handleEmailChange = useCallback((valueA) => {
        setImportEmail(valueA)
    }, []);

    const handleWhatsappChange = useCallback((valueB) => {
        setImportWhatsapp(valueB)
    }, []);

    const [phonenumber, setPhonenumber] = useState('');
    const handleChange = useCallback((newValue) => {
        setPhonenumber(newValue)
    }, []);

    const loadingMarkup = isLoading ? <Loading /> : null;

    const closeerror = () => {
        setError('')
    }

    const [masterConfigList, setMasterConfigList] = useState<any[]>([]);

    const { patientid } = useParams() as {
        patientid: string;
    };

    const [error, setError] = useState('');
    const errorDiv = error
        ? <Banner
            onDismiss={closeerror}
            status="critical">
            <p>{error}</p>
        </Banner>
        : '';

    const [educationsuccess, setEducationssuccess] = useState('');

    const closeSuccess = () => {
        setEducationssuccess('');
    }

    const successDiv = educationsuccess
        ? <Banner
            onDismiss={closeSuccess}
            status="success">
            <p>{educationsuccess}</p>
        </Banner>
        : '';

    const [instituteerror] = useState('');
    const instituteErrordiv = instituteerror
        ? <TextStyle variation="negative">{instituteerror}</TextStyle>
        : '';

    // objemail: any, objcreatedate: any, objmodifieddate: any, objphno: any, objwhatsappno:any
    function sayHello(id: any, objemail: any, objcreatedate: any, objmodifieddate: any, objphno: any, objwhatsappno: any) {
        console.log("CONFIGID==>", id);
        setModalActive((modalActive) => !modalActive);
        seteditConfigId(id);
        setOpen((open) => !open);
        setImportEmail(objemail);
        setImportWhatsapp(objwhatsappno);
        setPhonenumber(objphno);
        setEducationssuccess("");
        setError("");
    }


    const [modalActive, setModalActive] = useState(false);
    const handleToggle = () => {
        setModalActive((modalActive) => !modalActive);
        seteditConfigId('');
        setImportEmail('');
        setImportWhatsapp('');
        setPhonenumber('');
        setEducationssuccess("");
        setError("");
    }

    const userToken = localStorage.getItem("token");
    const headers = {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Token ${userToken}`
    }

    const addconfigbody = {
        "patientId": patientid,
        "email": importEmail,
        "phoneNumber": phonenumber,
        "whatsappNo": importWhatsapp
    }

    const getconfig = {
        "patientId": patientid
    }

    const addSymptomSubmit = () => {
        let url, newAwardBody;
        if (editConfigId) {
            url = baseUrl.baseUrl + '/patientapp/api/dataimport/edit_patient_import_config';
            newAwardBody = Object.assign(addconfigbody, { patientConfigId: editConfigId })
        }
        else {
            url = baseUrl.baseUrl + '/patientapp/api/dataimport/add_patient_import_config';

        }
        console.log(addconfigbody);
        axios.post(url, JSON.stringify(addconfigbody), { headers }).then(result => {
            setIsLoading(false);
            if (result) {
                setError('');
                setEducationssuccess(result.data.message);
                getMasterConfig();
            }
        }).catch((error) => {
            setIsLoading(false);
            setEducationssuccess("");
            setError(error.response.data.message);
            console.log(open);
        });

    }

    const getMasterConfig = () => {
        setIsLoading(true);
        axios.post(baseUrl.baseUrl + '/patientapp/api/dataimport/get_patient_import_config_list',
            getconfig, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Token ${userToken} `
            }
        })
            .then((result) => {
                setIsLoading(false);
                let resData = result.data.patientDataImportConfigList;
                let masterConfig = [];
                for (var i = 0; i < resData.length; i++) {
                    let ConfigId = resData[i].id;
                    // seteditConfigId(ConfigId);
                    let objemail = resData[i].email;
                    let objcreatedate = (new Date(resData[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let objmodifieddate = (new Date(resData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS);
                    let objphno = resData[i].phoneNumber;
                    let objwhatsappno = resData[i].whatsappNo;
                    masterConfig.push([<TextStyle variation="strong" key="phone-number">{resData[i].email}</TextStyle>,
                    (new Date(resData[i].createDate)).toLocaleDateString('en-US', DATE_OPTIONS),
                    (new Date(resData[i].modifiedDate)).toLocaleDateString('en-US', DATE_OPTIONS),
                    resData[i].phoneNumber,
                    resData[i].whatsappNo,
                    <Button onClick={() => sayHello(ConfigId, objemail, objcreatedate, objmodifieddate, objphno, objwhatsappno)}>Edit</Button>]);
                    // <Button onClick={() => sayHello(ConfigId)}>Edit</Button>]);
                }
                setMasterConfigList(masterConfig);
                console.log("api res===", resData);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.response.data.message);
            })
    }

    useEffect(() => {
        // get call here.... 
        getMasterConfig();
    }, []);

    const DATE_OPTIONS = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    const masterImportConfigList =   (
        <Scrollable shadow style={{ height: '300px' }}>
            <Card.Section>
              {masterConfigList && masterConfigList.length ? (
            <DataTable
                columnContentTypes={[
                    'text',
                    'numeric',
                    'numeric',
                    'numeric',
                    'numeric',
                ]}
                headings={[
                    'Email',
                    'Create date',
                    'Modified date',
                    'Phone Number',
                    'Whatsapp Number',
                    'Edit'
                ]}
                rows={masterConfigList}
            />
            ) : (
                <ExceptionList
                    items={[
                        {
                            icon: NoteMinor,
                            description: 'No Documents is available!',
                        },
                    ]}
                />)}
                </Card.Section>
        </Scrollable>

    );

    const symptomsAddInfo = (
        <div>
            {loadingMarkup}
            <Card>
                <Card.Header title="Import Configs">
                    <Button primary={true} onClick={handleToggle}>Add config</Button>
                </Card.Header>
                {masterImportConfigList}
            </Card>
            <br />
            <br />
            <Modal
                large
                open={modalActive}
                onClose={handleToggle}
                title={editConfigId ? "Update Import Config" : "Add Import Config"}
                primaryAction={{
                    content: 'Submit',
                    onAction: addSymptomSubmit,
                }}
            >
                <Modal.Section>
                    {/* {editMasterConfigId ? (
                            <Card title="Update Import config"> */}
                    {/* <Form onSubmit={addSymptomSubmit}> */}
                    <Box p={3}>
                        <FormLayout>
                            <FormLayout.Group condensed>
                                <div>
                                    <TextField
                                        value={importEmail}
                                        onChange={handleEmailChange}
                                        label=" Email to be used to import patient data"
                                        type="email"
                                        placeholder="Enter Your Email Address"
                                        id="Emailname"
                                    /><br />
                                    <InlineError message={instituteErrordiv} fieldID="Emailname" />

                                    <TextField
                                        value={importWhatsapp}
                                        onChange={handleWhatsappChange}
                                        label=" Whatsapp number to be used to import patient data"
                                        type="number"
                                        placeholder="Enter Whatsapp Number"
                                    />

                                </div>
                                <div>
                                    <TextField
                                        label="Phone Number"
                                        type="number"
                                        value={phonenumber}
                                        onChange={handleChange}
                                        placeholder="Please enter phone number"
                                    />
                                </div>
                            </FormLayout.Group>
                            <div>{successDiv}</div>
                            <div>{errorDiv}</div>
                        </FormLayout>
                    </Box>
                </Modal.Section>
            </Modal>
        </div >
    )
    return (
        <Typography>
            {symptomsAddInfo}
        </Typography>
    );
}